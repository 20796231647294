import { LAYOUT_SETTINGS, ROAD_GATE_WIDTH } from '.'
export const DEFAULT_Y_SHAPE = {
  sides: {
    right: {
      valueIn: {
        container: {
          x: LAYOUT_SETTINGS.startPosition.x + LAYOUT_SETTINGS.roadGateSize,
          y: LAYOUT_SETTINGS.startPosition.y + 20,
        },
        angle: -90,
      },
      valueOut: {
        container: {
          x: LAYOUT_SETTINGS.startPosition.x + LAYOUT_SETTINGS.roadGateSize,
          y: LAYOUT_SETTINGS.startPosition.y + LAYOUT_SETTINGS.roadGateSize - 30,
        },
        angle: -90,
      },
      label: {
        container: {
          x: LAYOUT_SETTINGS.startPosition.x + +LAYOUT_SETTINGS.roadGateSize + 10,
          y: LAYOUT_SETTINGS.startPosition.y + LAYOUT_SETTINGS.roadGateSize / 2,
        },
        angle: -90,
      },
      position: {
        angle: -30,
        x: LAYOUT_SETTINGS.startPosition.x + LAYOUT_SETTINGS.roadGateSize - 30,
        y: LAYOUT_SETTINGS.startPosition.y - 10,
      },
      roadGateWay: {
        BackwardDirection: [
          [-11, 95],
          [4, LAYOUT_SETTINGS.roadGateSize - 35],
          [-73, LAYOUT_SETTINGS.roadGateSize - 22],
        ],
        ForwardDirection: [
          [-20, 70],
          [-LAYOUT_SETTINGS.roadGateSize / 3, 55],
          [-42, 76],
        ],
        LeftDirection: [
          [-16, 83],
          [-93, LAYOUT_SETTINGS.roadGateSize],
          [-56, 94],
        ],
      },
    },
    bottom: {
      valueIn: {
        container: {
          x: LAYOUT_SETTINGS.startPosition.x,
          y:
            LAYOUT_SETTINGS.startPosition.y +
            LAYOUT_SETTINGS.roadGateSize +
            LAYOUT_SETTINGS.roadGateGup * 2 +
            ROAD_GATE_WIDTH,
        },
      },
      valueOut: {
        container: {
          x: LAYOUT_SETTINGS.startPosition.x + LAYOUT_SETTINGS.roadGateSize - 55,
          y:
            LAYOUT_SETTINGS.startPosition.y +
            LAYOUT_SETTINGS.roadGateSize +
            LAYOUT_SETTINGS.roadGateGup * 2 +
            ROAD_GATE_WIDTH,
        },
      },
      label: {
        container: {
          x: (LAYOUT_SETTINGS.startPosition.x + LAYOUT_SETTINGS.roadGateSize) / 1.5,
          y:
            LAYOUT_SETTINGS.startPosition.y +
            LAYOUT_SETTINGS.roadGateSize +
            LAYOUT_SETTINGS.roadGateGup * 6 +
            ROAD_GATE_WIDTH,
        },
      },
      position: {
        x: LAYOUT_SETTINGS.startPosition.x + ROAD_GATE_WIDTH,
        y:
          LAYOUT_SETTINGS.startPosition.y +
          LAYOUT_SETTINGS.roadGateSize +
          LAYOUT_SETTINGS.roadGateGup,
      },
      roadGateWay: {
        BackwardDirection: [
          [LAYOUT_SETTINGS.roadGateSize - 60, -6],
          [60, -1],
          [75, -75],
        ],
        RightDirection: [
          [LAYOUT_SETTINGS.roadGateSize - 25, -2],
          [LAYOUT_SETTINGS.roadGateSize - 8, -19],
          [LAYOUT_SETTINGS.roadGateSize - 35, -20],
        ],
        LeftDirection: [
          [LAYOUT_SETTINGS.roadGateSize - 45, -5],
          [60, -(LAYOUT_SETTINGS.roadGateSize - 60)],
          [LAYOUT_SETTINGS.roadGateSize - 45, -60],
        ],
      },
    },
    left: {
      valueIn: {
        container: {
          x: LAYOUT_SETTINGS.startPosition.x - 35,
          y: LAYOUT_SETTINGS.startPosition.y + 20,
        },
        angle: -90,
      },
      valueOut: {
        container: {
          x: LAYOUT_SETTINGS.startPosition.x - 40,
          y: LAYOUT_SETTINGS.startPosition.y + LAYOUT_SETTINGS.roadGateSize - 30,
        },
        angle: -90,
      },
      label: {
        container: {
          x: LAYOUT_SETTINGS.startPosition.x - 85,
          y: LAYOUT_SETTINGS.startPosition.y + LAYOUT_SETTINGS.roadGateSize / 2,
        },
        angle: -90,
      },
      position: {
        angle: 30,
        x: LAYOUT_SETTINGS.startPosition.x + 37,
        y: LAYOUT_SETTINGS.startPosition.y - 8,
      },
      roadGateWay: {
        BackwardDirection: [
          [-1, LAYOUT_SETTINGS.roadGateSize - 40],
          [12, LAYOUT_SETTINGS.roadGateSize - 60],
          [71, LAYOUT_SETTINGS.roadGateSize - 35],
        ],
        RightDirection: [
          [-17, LAYOUT_SETTINGS.roadGateSize - 16],
          [11, LAYOUT_SETTINGS.roadGateSize + LAYOUT_SETTINGS.roadGateGup],
          [12.6, LAYOUT_SETTINGS.roadGateSize - 9],
        ],
        ForwardDirection: [
          [-15, LAYOUT_SETTINGS.roadGateSize - 24],
          [LAYOUT_SETTINGS.roadGateSize / 1.6, LAYOUT_SETTINGS.roadGateSize - 24],
          [50, LAYOUT_SETTINGS.roadGateSize - 24],
        ],
      },
    },
  },
}
