import { Konva, ShapeModel, Board, ShapeDrawer, getPointsDistance } from 'pikaso'

import { ShapeUtils } from '../'
import { EditorObjectsType } from '../../../../ts/enum/editor'

const findAngle = (sx: number, sy: number, ex: number, ey: number) => Math.atan2(ey - sy, ex - sx)

const drawArrowhead = (
  ctx: any,
  x: number,
  y: number,
  angle: number,
  sizeX: number,
  sizeY: number,
) => {
  const hx = sizeX / 2
  const hy = sizeY / 2
  ctx.translate(x, y)
  ctx.rotate(angle)
  ctx.translate(-hx, -hy)

  ctx.moveTo(0, 0)
  ctx.lineTo(0, 1 * sizeY)
  ctx.lineTo(1 * sizeX, 1 * hy)
  ctx.closePath()
}

export class ArrowModel extends ShapeModel<Konva.Group, Konva.GroupConfig> {
  constructor(board: Board, node: Konva.Group) {
    super(board, node)
    this.config = {
      ...this.config,
      transformer: {
        enabledAnchors: [],
      },
    }
  }

  public get type() {
    return EditorObjectsType.RoadGateWay as string
  }
}

export class ArrowDrawer extends ShapeDrawer<
  // @ts-ignore
  Konva.Group,
  Konva.GroupConfig
> {
  public node: Konva.Group | null = null

  constructor(board: Board) {
    super(board, EditorObjectsType.RoadGateWay)
  }

  public insert(config: Konva.ShapeConfig): ArrowModel {
    return super.insert(config)
  }

  public draw(config: Konva.ShapeConfig = {}) {
    super.draw(config)
  }

  protected createShape(config: Partial<Konva.ShapeConfig | Record<string, any>>): ArrowModel {
    const configHandlers = {
      start: { x: 60, y: 30, ...config?.attrs?.start },
      control: { x: 240, y: 110, ...config?.attrs?.control },
      end: { x: 80, y: 160, ...config?.attrs?.end },
    }

    const handlers: Record<keyof typeof configHandlers, any> = {
      control: {},
      end: {},
      start: {},
    }

    this.node = new Konva.Group({
      ...config,
      draggable: true,
      name: EditorObjectsType.RoadGateWay,
    })

    Object.entries(configHandlers).forEach((entriesProps) => {
      const [key, coords] = entriesProps as [keyof typeof configHandlers, Record<string, any>]

      handlers[key] = ShapeUtils.createHandler({
        x: coords.x,
        y: coords.y,
        config: {
          fill: '#fff',
          stroke: '#000',
          radius: 4,
        },
        onDragMove: (e) => {
          const { target } = e

          this.node?.setAttr(key, {
            x: target.x(),
            y: target.y(),
          })
        },
      })

      this.node?.setAttr(key, coords)
    })

    const curve = new Konva.Shape({
      stroke: '#41E38C',
      strokeWidth: 3,
      sceneFunc: (ctx, shape) => {
        ctx.beginPath()
        ctx.moveTo(handlers.start.x(), handlers.start.y())
        ctx.quadraticCurveTo(
          handlers.control.x(),
          handlers.control.y(),
          handlers.end.x(),
          handlers.end.y(),
        )
        drawArrowhead(
          ctx,
          handlers.end.x(),
          handlers.end.y(),
          findAngle(handlers.control.x(), handlers.control.y(), handlers.end.x(), handlers.end.y()),
          8,
          8,
        )
        ctx.fillStrokeShape(shape)
      },
    })

    curve.on('mouseover', () => curve.stroke('white'))
    curve.on('mouseout', () => curve.stroke('#41E38C'))

    this.node.setAttr('isTransform', false)
    this.node.add(curve, handlers.control, handlers.start, handlers.end)
    const nodes = [handlers.control, handlers.start, handlers.end]
    nodes.forEach((n) => n.opacity(0))

    curve.on('dblclick', () => {
      if (!this.node) return
      const isTransform = this.node?.getAttr('isTransform')
      const transformValue = !isTransform
      this.node.setAttr('isTransform', transformValue)
      nodes.forEach((n) => n.opacity(transformValue ? 1 : 0))
    })

    return new ArrowModel(this.board, this.node)
  }

  protected onStartDrawing() {
    super.onStartDrawing()
    if (!this.isDrawing) return
    this.createShape({
      x: this.startPoint.x,
      y: this.startPoint.y,
      scaleX: 0,
      scaleY: 0,
      ...this.config,
    })
  }

  protected onDrawing(e: Konva.KonvaEventObject<MouseEvent>) {
    super.onDrawing(e)
    if (!this.node) return
    const points = this.board.stage.getPointerPosition()!
    const distance = getPointsDistance(points, this.getShapePosition())
    this.node.setAttrs({
      x: points.x - this.node.width(),
      scaleX: distance / 10,
      scaleY: distance / 10,
    })
  }
}
