import Pikaso from 'pikaso'
import { Shape } from '../../shapes'
import { EditorEnums } from '../../../../ts'
import { shapesTheme } from '../../shapes-config'

export const roadSegmentDeserializer = (
  editor: Pikaso<Shape>,
  shapesConfig: Record<string, any>[],
) => {
  if (!shapesConfig.length) return
  const groupId = EditorEnums.EditorObjectsType.RoadSegment
  const groupName = groupId
  const [groupConfig] = shapesConfig.filter((sc) => sc.attrs.name === groupName)
  const textConfig = shapesConfig.filter((sc) => sc.className === 'Text')
  const nodes: any[] = []

  const group = editor.board.groups.create(groupName, groupConfig)
  group.container.node.setAttrs({ id: groupId, groupName })
  group.container.config = {
    ...group.container.config,
    transformer: {
      ...group.container.config.transformer,
      enabledAnchors: [],
    },
  }

  let hasSomeSelect = false
  textConfig.forEach((s) => {
    const config = { ...s.attrs, width: undefined, height: undefined }
    const text = editor.shapes.text.insert(config)
    text.node.setAttr('groupId', groupId)
    text.node.setAttr('groupName', groupName)
    text.node.on('dblclick', () => {
      const g = editor.board.groups.find(groupName)
      editor.selection.deselectAll()
      editor.selection.add(text)
      editor.board.groups.ungroup(groupName)
      editor.board.groups.delete(groupName)
      editor.board.groups.destroy(groupName)
      g && editor.board.removeShape(g.container)
      hasSomeSelect = true
    })
    text.config = {
      ...text.config,
      transformer: {
        ...text.config.transformer,
        enabledAnchors: [],
      },
    }
    text.group = groupName
    nodes.push(text)
  })

  editor.on('selection:change', (e) => {
    const isClearSomeShape = !e.shapes?.length
    if (isClearSomeShape && hasSomeSelect) {
      hasSomeSelect = false
      const g = editor.board.groups.attach(nodes, groupName)
      g.container.node.setAttrs({ id: groupId, groupName })
      g.container.config = {
        ...g.container.config,
        transformer: {
          ...g.container.config.transformer,
          enabledAnchors: [],
        },
      }
      return
    }
  })
}

export const roadSegmentGenerator = (editor: Pikaso<Shape>, presetData: Record<string, any>) => {
  const {
    leftStreetName = 'left street name',
    rightStreetName = 'right street name',
    distance = 'default distance',
  } = presetData || {}

  const groupId = EditorEnums.EditorObjectsType.RoadSegment
  const groupName = groupId
  const g = editor.board.groups.find(groupId)
  if (g) return

  const stageCenterX = editor.board.stage.width() / 2
  const stageCenterY = editor.board.stage.height() / 2
  const padding = 20

  const leftStreet = editor.shapes.text.insert({
    text: leftStreetName,
    rotation: 90,
    ...shapesTheme.HalfRoadSegment.street,
  })

  const rightStreet = editor.shapes.text.insert({
    x: editor.board.stage.width() - padding,
    text: rightStreetName,
    rotation: 90,
    ...shapesTheme.HalfRoadSegment.street,
  })

  const distanceValue = editor.shapes.text.insert({
    text: distance,
    ...shapesTheme.HalfRoadSegment.distance,
  })

  distanceValue.update({
    x: stageCenterX - distanceValue.width() / 2,
    y: stageCenterY - distanceValue.height() / 2,
  })
  leftStreet.update({ y: stageCenterY - leftStreet.width() / 2, x: leftStreet.height() + padding })
  rightStreet.update({ y: stageCenterY - rightStreet.width() / 2 })

  const nodes = [leftStreet, rightStreet, distanceValue]
  let hasSomeSelect = false

  nodes.forEach((s) => {
    s.node.setAttr('groupId', groupId)
    s.node.setAttr('groupName', groupName)
    s.config = {
      ...s.config,
      transformer: {
        ...s.config.transformer,
        enabledAnchors: [],
      },
    }
    s.node.on('dblclick', () => {
      const g = editor.board.groups.find(groupName)
      editor.selection.deselectAll()
      editor.selection.add(s)
      editor.board.groups.ungroup(groupName)
      editor.board.groups.delete(groupName)
      editor.board.groups.destroy(groupName)
      g && editor.board.removeShape(g.container)
      hasSomeSelect = true
    })
  })

  const group = editor.board.groups.attach(
    [leftStreet, rightStreet, distanceValue],
    EditorEnums.EditorObjectsType.RoadSegment,
  )

  group.container.node.setAttrs({ id: groupId, groupName })
  group.container.config = {
    ...group.container.config,
    transformer: {
      ...group.container.config.transformer,
      enabledAnchors: [],
    },
  }

  editor.on('selection:change', (e) => {
    const isClearSomeShape = !e.shapes?.length
    if (isClearSomeShape && hasSomeSelect) {
      hasSomeSelect = false
      const g = editor.board.groups.attach([...nodes], groupName)
      g.container.node.setAttrs({ id: groupId, groupName })
      g.container.config = {
        ...g.container.config,
        transformer: {
          ...g.container.config.transformer,
          enabledAnchors: [],
        },
      }
      return
    }
  })
}
