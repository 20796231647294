import { EditorEnums } from '../../ts'

export const shapesTheme = {
  [EditorEnums.EditorObjectsType.RoadGate]: {
    line: {
      stroke: '#3C72FF', // --primary-color
      strokeWidth: 3,
    },
    value: {
      fill: '#959da8',
      fontSize: 14,
    },
  },
  [EditorEnums.EditorObjectsType.HalfRoadSegment]: {
    street: {
      fill: '#fff',
      fontSize: 24,
    },
    distance: {
      fill: '#fff',
      fontSize: 32,
    },
  },
}
