import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Board, Konva } from 'pikaso'

/** @jsx jsx */
import { jsx } from '@emotion/react'
import { ProgressSpinner } from 'primereact/progressspinner'

// @ts-ignore
import { Tooltip } from 'primereact/tooltip'

import { Messages } from 'primereact/messages'

import { usePikaso } from '../../packages'
import { EditorModels, EditorEnums } from '../../ts'
import { useGetApiRepository } from '../../providers'
import { ActionsToolBar, ToolBar } from '../../components'
import { PikasoService } from '../../service'

import { editorLoading, editorStyles, editorWrapper, messagesStyle } from './style'
import { CameraDrawer, Shape, ArrowDrawer, CurveDrawer } from './shapes'
import { ShapesPresetsType } from './presets'
import { ActionToolBar } from '../../ts/enum/editor'
import { HalfRoadSegmentDrawer } from './shapes/half-road-segment'

type EditorOpenApi = {
  createObject: (o: EditorModels.BaseShapeSetting, meta: Record<string, any>) => void
  validateShapes: (shapes: string[]) => void
  createPreset(o: ShapesPresetsType, presetData: Record<string, any>): void
  updateObject: (o: EditorModels.BaseShapeSetting) => void
  hasObject: (id: string) => boolean
  deleteObject: (id: string, silent?: boolean) => void
  drawObject: (o: EditorModels.BaseShapeSetting) => void
  toggleVisibility: (ids: Record<string, boolean>) => void
  saveView: () => void
}

const PIKASO_DEFAULT_SETTING = (isEditMode: boolean) => {
  return {
    snapToGrid: {
      strokeWidth: 2,
      stroke: '#fff',
    },
    selection: {
      transformer: {
        anchorSize: !isEditMode ? 0 : 5,
        borderStrokeWidth: !isEditMode ? 0 : 1,
      },
      keyboard: {
        enabled: false,
      },
    },
  }
}

const PIKASO_CUSTOM_SHAPES = (board: Board) => ({
  camera: new CameraDrawer(board),
  roadGateWay: new ArrowDrawer(board),
  curve: new CurveDrawer(board),
  halfRoadSegment: new HalfRoadSegmentDrawer(board),
})

const Editor = (
  props: React.PropsWithChildren<EditorModels.EditorProps>,
  ref: React.ForwardedRef<EditorOpenApi>,
) => {
  const { entity, objectSettings, mode } = props
  const message = useRef<Messages>(null!)
  const pikasoService = useRef<PikasoService>(null!)
  const [loading, setLoading] = useState(true)
  const api = useGetApiRepository()
  const isEditMode = mode === EditorEnums.EditorModes.editor
  const { editor, ref: editorRef } = usePikaso<Shape>(
    PIKASO_DEFAULT_SETTING(isEditMode),
    PIKASO_CUSTOM_SHAPES,
  )

  useEffect(() => {
    if (!pikasoService.current) return
    pikasoService.current.configurableShapes(objectSettings)
  }, [objectSettings])

  useEffect(() => {
    if (!editor || !entity) return

    const init = async () => {
      try {
        setLoading(true)
        const { entity: svgEntity } = await api.getEntity<{
          pikasoScheme?: string
        }>({
          id: entity.id,
          type: 'SvgView',
        })

        const json = decodeURIComponent(svgEntity?.pikasoScheme || '{}')
        const pikasoScheme = JSON.parse(json)

        pikasoService.current = new PikasoService(
          editor,
          mode,
          pikasoScheme,
          objectSettings,
          api,
          message.current,
          entity,
        )

        const tooltipLayer = new Konva.Layer({ id: 'tooltipLayer' })
        if (!isEditMode && props.window?.height)
          editorRef.current.style.height = `${props.window?.height}px`

        editor.board.stage.add(tooltipLayer)
        editor.snapGrid.disable()
        setLoading(false)
      } catch (e) {
        console.error(e)
      }
    }
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, editor, entity?.id])

  useImperativeHandle(ref, () => ({
    createObject: (o) => {
      pikasoService.current.createObject(o)
      pikasoService.current.saveBoard()
    },
    createPreset: (o, presetData) => pikasoService.current.createPreset(o, presetData),
    updateObject: (o) => {
      pikasoService.current.updateObject(o)
      pikasoService.current.saveBoard()
    },
    drawObject: (o) => pikasoService.current.drawObject(o),
    hasObject: (id) => pikasoService.current.hasObject(id),
    toggleVisibility: (id) => pikasoService.current.toggleVisibility(id),
    deleteObject: (id, silent) => {
      pikasoService.current.deleteObject(id, silent)
      pikasoService.current.saveBoard()
    },
    saveView: () => pikasoService.current.saveBoard(),
    validateShapes: (ids) => pikasoService.current.validateShapes(ids),
  }))

  const handleToolBarActions = (
    actionType: EditorEnums.EditorActions,
    id: EditorEnums.EditorObjectsType,
  ) => {
    if (actionType === EditorEnums.EditorActions.addObject) {
      pikasoService.current.createObject({ id: String(Date.now()), type: id })
    }

    if (actionType === EditorEnums.EditorActions.drawElement) {
      pikasoService.current.drawObject({ id: String(Date.now()), type: id })
    }

    if (
      [
        EditorEnums.EditorActions.addExistObject,
        EditorEnums.EditorActions.addPresetObjects,
        EditorEnums.EditorActions.createElement,
      ].includes(actionType)
    ) {
      pikasoService.current.eventEmitter.dispatchEvent(actionType, { type: id })
    }
  }

  const handleActionsClick = useCallback(
    async (selectAction: string) => {
      if (selectAction === ActionToolBar.delete) {
        if (!editor?.board.selection.list.length) return
        const ids = editor?.board.selection.list.map((s) => s.node.id()).filter((s) => Boolean(s))
        ids.length && pikasoService.current.batchDelete(ids)
      }

      if (selectAction === ActionToolBar.save) pikasoService.current.saveBoard()
    },
    [editor?.board.selection.list],
  )

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (document.activeElement?.tagName !== 'BODY') return
      const key = e.code
      const isNeedKey = ['Backspace', 'Delete'].includes(key)
      if (!isNeedKey) return
      handleActionsClick(ActionToolBar.delete)
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [editor, handleActionsClick])

  return (
    <div css={editorWrapper}>
      {loading && <ProgressSpinner css={editorLoading} />}
      <div
        css={editorStyles}
        className="react-canvas-editor"
        ref={editorRef}
        style={{ transform: `scale(${props.window?.zoom || 1})` }}
      />
      <Messages css={messagesStyle} ref={message} />

      {!loading && isEditMode && (
        <>
          <Tooltip target="[data-tooltip='true']" />
          <ToolBar onSelect={handleToolBarActions} />
          <ActionsToolBar onSelect={handleActionsClick} />
        </>
      )}
    </div>
  )
}

const EditorRef = React.memo(React.forwardRef<EditorOpenApi, EditorModels.EditorProps>(Editor))

export { EditorRef as Editor }
