import { css } from '@emotion/react'

export const toolBarStyles = css`
  position: absolute;
  padding: 10px;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
`

export const toolBarMenuStyles = css`
  border-radius: 10px;
  background: var(--bg-gradient-dark);
  padding: 10px;
  margin: 0;
  list-style: none;

  li {
    &:hover {
      & > div {
        li {
          opacity: 1;
        }
        opacity: 1;
        pointer-events: all;
      }
    }
  }
`

export const toolBarInnerMenuStyles = css`
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 13px;
    padding: 12px 0;
    cursor: pointer;
    color: #959da8;
    margin: 0;

    i {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      font-size: 17px;
      margin-left: auto;

      &:hover {
        color: var(--text-color);
      }
    }

    span {
      i {
        margin-right: 10px;
      }

      display: flex;
      align-items: center;
      margin-right: 10px;
      white-space: nowrap;

      &:hover {
        color: var(--text-color);
      }
    }
  }
`

export const toolBarItemStyles = css`
  position: relative;
  font-size: 20px;
  margin-bottom: 12px;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }
`
export const toolBarInnerSpaceStyle = css`
  padding-left: 20px;
  position: absolute;
  top: 0;
  left: 20px;
  opacity: 0;
  pointer-events: none;
`
