import { TUnionRepo } from '@netvision/lib-api-repo'
import { isEqual } from 'lodash'

type entityData = Record<string, any>

export const createEditEntity = async (
  api: TUnionRepo,
  newEntityData: entityData,
  originalEntityData: entityData | undefined,
) => {
  if (newEntityData?.id) delete newEntityData?.id
  const isOnlyNew = !originalEntityData
  const crudMethod = isOnlyNew ? api.createEntity : api.updateEntity

  let options: entityData & { type: string; id: string } = {
    id: isOnlyNew ? '' : originalEntityData.id,
    type: isOnlyNew ? newEntityData.type : originalEntityData.type,
  }

  const appendEntityProperties: entityData & { type: string; id: string } = { ...options }
  if (isOnlyNew) {
    options = {
      ...options,
      ...newEntityData,
    }
  } else {
    Object.keys(newEntityData).forEach((key) => {
      if (isEqual(newEntityData[key], originalEntityData[key])) return
      if (originalEntityData[key] === undefined) {
        appendEntityProperties[key] = newEntityData[key]
      } else {
        options[key] = newEntityData[key]
      }
    })
  }

  if (Object.keys(appendEntityProperties).length > 2) {
    // @ts-ignore
    await api.appendEntity(appendEntityProperties)
  }

  if (isOnlyNew || Object.keys(options).length > 2) {
    await crudMethod({ ...options })
  }
}
