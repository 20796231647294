import { EditorEnums } from '../../ts'

export type MenuItem = {
  id: EditorEnums.EditorObjectsType | string
  actionType?: EditorEnums.EditorActions
  tooltip?: string
  label?: string
  icons?: {
    before?: {
      id: EditorEnums.EditorObjectsType | string
      actionType?: string
      icon: string
    }
    after?: {
      id: EditorEnums.EditorObjectsType | string
      actionType?: string
      icon: string
    }
  }
  subItems?: MenuItem[]
}

export type MenuScheme = MenuItem[]
EditorEnums.EditorActions
export const menuScheme: MenuScheme = [
  {
    id: EditorEnums.EditorObjectsType.Line,
    actionType: EditorEnums.EditorActions.addObject,
    tooltip: 'Отрезок',
    icons: {
      before: {
        id: 'line-icon',
        icon: 'mdi-vector-line',
      },
    },
  },
  {
    id: EditorEnums.EditorObjectsType.QuadraticBezier,
    actionType: EditorEnums.EditorActions.addObject,
    tooltip: 'Кривая',
    icons: {
      before: {
        id: 'vector-bezier',
        icon: 'mdi-vector-bezier',
      },
    },
  },
  {
    id: EditorEnums.EditorObjectsType.Text,
    actionType: EditorEnums.EditorActions.addObject,
    tooltip: 'Текст',
    icons: {
      before: {
        id: 'color-text',
        icon: 'mdi-format-color-text',
      },
    },
  },
  {
    id: EditorEnums.EditorObjectsType.Image,
    actionType: EditorEnums.EditorActions.addObject,
    tooltip: 'Изображение',
    icons: {
      before: {
        id: 'image',
        icon: 'mdi-image',
      },
    },
  },
  {
    id: 'svg-objects',
    icons: {
      before: {
        icon: 'mdi-home-assistant',
        id: 'svg-objects-icon',
      },
    },
    subItems: [
      {
        id: EditorEnums.EditorObjectsType.Camera,
        actionType: EditorEnums.EditorActions.addExistObject,
        label: 'Добавить камеру',
        icons: {
          before: {
            id: 'icon-camera-before',
            icon: 'mdi-cctv',
          },
        },
      },
      {
        id: EditorEnums.EditorObjectsType.HalfRoadSegment,
        actionType: EditorEnums.EditorActions.createElement,
        label: 'Добавить Рубеж',
        icons: {
          before: {
            id: 'icon-road-segment',
            icon: 'mdi-road',
          },
        },
      },
      {
        id: EditorEnums.EditorObjectsType.Bolid,
        actionType: EditorEnums.EditorActions.addExistObject,
        label: 'Добавить bolid',
        icons: {
          before: {
            id: 'icon-camera-before',
            icon: 'mdi-smoke-detector',
          },
          after: {
            id: EditorEnums.EditorObjectsType.Bolid,
            actionType: EditorEnums.EditorActions.addExistObject,
            icon: 'mdi-home-import-outline',
          },
        },
      },
      {
        id: EditorEnums.EditorObjectsType.RoadGate,
        actionType: EditorEnums.EditorActions.createElement,
        label: 'Створ',
        icons: {
          before: {
            id: 'icon-arrow-before',
            icon: 'mdi-boom-gate',
          },
          after: {
            icon: 'mdi-home-import-outline',
            id: EditorEnums.EditorObjectsType.RoadGate,
            actionType: EditorEnums.EditorActions.addExistObject,
          },
        },
      },
      {
        id: EditorEnums.EditorObjectsType.RoadGate,
        actionType: EditorEnums.EditorActions.addPresetObjects,
        label: 'Группа створов',
        icons: {
          before: {
            id: 'arrow-expand-left',
            icon: 'mdi-arrow-decision-outline',
          },
        },
      },
      {
        id: EditorEnums.EditorObjectsType.RoadGateWay,
        actionType: EditorEnums.EditorActions.drawElement,
        label: 'Направление створа',
        icons: {
          before: {
            id: 'icon-arrow-left-top',
            icon: 'mdi-arrow-left',
          },
        },
      },
    ],
  },
]
