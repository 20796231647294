import React from 'react'

/** @jsx jsx */
import { jsx } from '@emotion/react'
import { toolBarStyles, toolBarItemStyles } from './styles'
import { ActionToolBar } from '../../ts/enum/editor'

const svgAction = [
  {
    id: ActionToolBar.delete,
    tooltip: 'Удалить',
    icon: 'mdi-delete',
  },
  {
    id: ActionToolBar.save,
    tooltip: 'Сохранить',
    icon: 'mdi-content-save',
  },
]

interface Props {
  onSelect: (selectTool: string) => void
}

export const ActionsToolBar = (props: Props) => {
  return (
    <div css={toolBarStyles}>
      {svgAction.map((item) => (
        <div
          onClick={() => props.onSelect(item.id)}
          css={toolBarItemStyles}
          key={item.id}
          data-tooltip
          data-pr-tooltip={item.tooltip}>
          <i className={`mdi ${item.icon}`} />
        </div>
      ))}
    </div>
  )
}
