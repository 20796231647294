import React from 'react'

/** @jsx jsx */
import { jsx } from '@emotion/react'

import {
  toolBarStyles,
  toolBarItemStyles,
  toolBarMenuStyles,
  toolBarInnerMenuStyles,
  toolBarInnerSpaceStyle,
} from './styles'

import { EditorEnums } from '../../ts'
import { MenuItem, menuScheme } from './model'
import { useWidgetProps } from '../../Root'

interface Props {
  onSelect: (actionType: EditorEnums.EditorActions, id: EditorEnums.EditorObjectsType) => void
}

export const ToolBar = (props: Props) => {
  const { props: widgetProps } = useWidgetProps()
  const { toolbar = { objects: [] } } = widgetProps || {}
  const generateItem = (item: MenuItem) => {
    const onClick = item.actionType
      ? (e: React.MouseEvent<HTMLElement>) => {
          e.stopPropagation()
          props.onSelect(
            item.actionType as EditorEnums.EditorActions,
            item.id as EditorEnums.EditorObjectsType,
          )
        }
      : undefined

    const { after, before } = item.icons || {}

    const onBeforeIconClick = before?.actionType
      ? () =>
          props.onSelect(
            before.actionType as EditorEnums.EditorActions,
            before.id as EditorEnums.EditorObjectsType,
          )
      : undefined

    const onAfterIconClick = after?.actionType
      ? (e: React.MouseEvent<HTMLElement>) => {
          e.stopPropagation()
          props.onSelect(
            after.actionType as EditorEnums.EditorActions,
            after.id as EditorEnums.EditorObjectsType,
          )
        }
      : undefined

    return (
      <li
        className="menu-item-style"
        onClick={onClick}
        css={toolBarItemStyles}
        key={item.id + item.actionType}
        data-tooltip={Boolean(item.tooltip)}
        data-pr-tooltip={item.tooltip}>
        <span>
          {before && <i onClick={onBeforeIconClick} className={`mdi ${before.icon}`} />}
          {item.label}
        </span>
        {after && <i onClick={onAfterIconClick} className={`mdi ${after.icon}`} />}
        <div css={toolBarInnerSpaceStyle}>
          {item?.subItems && (
            <ul css={[toolBarMenuStyles, toolBarInnerMenuStyles]}>
              {item?.subItems
                .filter((subMenu) => {
                  const hasFilter = toolbar.objects.length > 0
                  if (!hasFilter) return true
                  return toolbar.objects.includes(subMenu.id as EditorEnums.EditorObjectsType)
                })
                .map(generateItem)}
            </ul>
          )}
        </div>
      </li>
    )
  }
  return (
    <div css={toolBarStyles}>
      <ul css={toolBarMenuStyles}>{menuScheme.map(generateItem)}</ul>
    </div>
  )
}
