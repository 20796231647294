import Pikaso, { LabelModel, LineModel, ShapeModel } from 'pikaso'

import { Shape, roadGateBuilder } from '../modules/editor/shapes'
import {
  DEFAULT_CONFIG_ROAD_GATES_PRESETS,
  generateRoadGate,
  roadSegmentGenerator,
  ShapesPresetsType,
} from '../modules/editor/presets'

import { EditorModels, EditorEnums } from '../ts'
import { EventEmitterService } from './event-emitter'
import { EditorObjects } from '../ts/models/editor'
import { EditorActions, EditorObjectsType } from '../ts/enum/editor'
import { GroupModel } from 'pikaso/esm/shape/models/GroupModel'
import { getLineCenter, roadGateDeserializer } from '../modules/editor/shapes/road-gate'
import { Messages } from 'primereact/messages'
import { TUnionRepo } from '@netvision/lib-api-repo'
import { createEditEntity } from '../utils'
import { roadSegmentDeserializer } from '../modules/editor/presets/road-segment'

const isRoadGateShape = (s: ShapeModel) =>
  s.node.name().includes(EditorEnums.EditorObjectsType.RoadGate) &&
  s.node.name() !== EditorEnums.EditorObjectsType.RoadGateWay
const isRoadGateWay = (s: ShapeModel) => s.type == EditorEnums.EditorObjectsType.RoadGateWay

function debounce(callee: (args: any) => void, timeoutMs: number) {
  const self: Record<string, any> = {}
  return function perform(...args: any) {
    let previousCall = self.lastCall
    self.lastCall = Date.now()
    if (previousCall && self.lastCall - previousCall <= timeoutMs) {
      clearTimeout(self.lastCallTimer)
    }
    self.lastCallTimer = setTimeout(() => callee([...args]), timeoutMs)
  }
}

export class PikasoService {
  isEditMode: boolean
  editor: Pikaso<Shape>
  eventEmitter: EventEmitterService
  messages: Messages
  api: TUnionRepo
  entity: Record<string, any>

  constructor(
    pikasoInstance: Pikaso<Shape>,
    mode: EditorEnums.EditorModes,
    pikasoScheme: Record<string, any>,
    objectSettings: EditorObjects[],
    api: TUnionRepo,
    messages: Messages,
    entity: Record<string, any>,
  ) {
    this.editor = pikasoInstance
    this.messages = messages
    this.eventEmitter = new EventEmitterService(this.dispatchNode)
    this.isEditMode = mode === EditorEnums.EditorModes.editor
    this.api = api
    this.entity = entity
    this.handleEditorShapeCreate = this.handleEditorShapeCreate.bind(this)
    this.initListeners()

    this.loadBoard(pikasoScheme).then(() => {
      this.configurableShapes(objectSettings)
      this.resizeInit()
      !this.isEditMode && this.initViewMode()
      this.eventEmitter.dispatchEvent(EditorActions.load, {})
    })
  }

  resizeInit() {
    this.editor.board.stage.height(600)
    this.editor.board.stage.width(1300)
    this.editor.board.stage.scale({ x: 1.3, y: 1.3 })
    this.editor.board.stage.position({ x: 0, y: -150 })

    if (this.isEditMode) {
      const node = this.editor.board.stage.container()
      const pikaso = node.querySelector('.pikaso') as HTMLDivElement | null
      if (!pikaso) return
      pikaso.style.border = '1px solid var(--primary-color)'
    }
  }

  handleEditorShapeCreate(e: Record<string, any>) {
    e.shapes?.forEach((shape: ShapeModel) => {
      const isRoadGateType = isRoadGateShape(shape)

      if (
        [
          EditorEnums.EditorObjectsType.Camera,
          EditorEnums.EditorObjectsType.HalfRoadSegment,
        ].includes(shape.type as EditorEnums.EditorObjectsType) ||
        isRoadGateWay(shape)
      ) {
        this.eventEmitter.dispatchEvent(EditorEnums.EditorActions.addObject, {
          id: shape.node.id(),
          type: shape.type,
        })
      }

      if (isRoadGateType) {
        const id = shape.name.slice(EditorEnums.EditorObjectsType.RoadGate.length + 1)
        this.eventEmitter.dispatchEvent(EditorEnums.EditorActions.addObject, {
          id: id,
          type: EditorEnums.EditorObjectsType.RoadGate,
        })
      }

      shape.node.on('click', () =>
        this.eventEmitter.dispatchEvent(EditorEnums.EditorActions.clickObject, {
          id: shape.node.id(),
          type: isRoadGateType ? EditorEnums.EditorObjectsType.RoadGate : shape.type,
        }),
      )
    })
  }

  private initListeners() {
    this.editor.on('shape:create', this.handleEditorShapeCreate)
    const handleSaveFunction = debounce(() => this.saveBoard(true), 1000)
    ;(['selection:dragmove', 'selection:transformend'] as const).forEach((eventName) =>
      this.editor.on(eventName, handleSaveFunction),
    )
  }

  private initViewMode() {
    this.editor.board.shapes.forEach((s) => {
      s.node.draggable(false)
      if (s.type === 'image') s.node.listening(false)
      if (isRoadGateShape(s)) {
        const g = this.editor.board.groups.find(s.node.name())
        if (!g) return

        s.node.on('mouseover', () => {
          const [, ...labels] = g.children
          // @ts-ignore
          labels.forEach((l) => l?.updateText({ fill: 'white' }))
        })

        s.node.on('mouseleave', () => {
          const [, ...labels] = g.children
          labels.forEach((l) => {
            if (l.node.getAttr('isActive')) return
            // @ts-ignore
            l?.updateText({ fill: '#959da8' })
          })
        })
      }

      if (s.type === 'label') {
        s.node.removeEventListener('dblclick')
      }
    })

    const scaleBy = 1.01
    const stage = this.editor.board.stage
    stage.on('wheel', (e) => {
      // stop default scrolling
      e.evt.preventDefault()

      const oldScale = stage.scaleX()
      const pointer = stage.getPointerPosition()!

      var mousePointTo = {
        x: (pointer.x - stage.x()) / oldScale,
        y: (pointer.y - stage.y()) / oldScale,
      }

      // how to scale? Zoom in? Or zoom out?
      let direction = e.evt.deltaY > 0 ? 1 : -1

      // when we zoom on trackpad, e.evt.ctrlKey is true
      // in that case lets revert direction
      if (e.evt.ctrlKey) direction = -direction

      const newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy

      stage.scale({ x: newScale, y: newScale })

      const newPos = {
        x: pointer.x - mousePointTo.x * newScale,
        y: pointer.y - mousePointTo.y * newScale,
      }
      stage.position(newPos)
    })
  }

  private get dispatchNode() {
    const node = document
      .querySelector('.react-canvas-editor')
      ?.closest('.single-spa-parcel-container')?.parentElement
    if (!node) return null
    return node as HTMLElement
  }

  public createObject(
    o: EditorModels.BaseShapeSetting & { title?: string; icon?: Record<string, any> } & Record<
        string,
        any
      >,
  ) {
    if (o.type === EditorEnums.EditorObjectsType.Camera) {
      return this.editor.shapes.camera.insert({
        attrs: {
          id: String(o.id),
          title: o?.title,
          icon: o?.icon?.iconClass,
        },
      })
    }

    if (o.type === EditorEnums.EditorObjectsType.RoadGate) {
      return roadGateBuilder(this.editor, o)
    }

    if (o.type === EditorEnums.EditorObjectsType.Image) {
      let input: HTMLInputElement | null = document.createElement('input')
      input.type = 'file'
      input.accept = 'image/*'
      input.click()

      input.onchange = (e) => {
        const files = (e.target as HTMLInputElement).files

        if (!files?.length) {
          console.warn('File does not exist')
          return
        }

        if (!files[0].type.includes('image')) {
          console.warn('Selected file is not image')
          return
        }

        const image = this.editor.shapes.image.insert(files[0])
        image.then((img) => {
          const imgCenterX = img.width() / 2
          const imgCenterY = img.height() / 2
          const centerStageX = this.editor.board.stage.width() / 2
          const centerStageY = this.editor.board.stage.height() / 2

          img.update({
            x: centerStageX - imgCenterX,
            y: centerStageY - imgCenterY,
          })
          img.node.zIndex(1)
          img.node.id(Math.random().toString(16).slice(2))
        })
        input = null
      }
    }

    if (o.type === EditorEnums.EditorObjectsType.Text) {
      const text = this.editor.shapes.label.insert({
        container: {
          x: 50,
          y: 50,
        },
        text: {
          text: o.title || 'TextPlaceHolder',
          fill: '#fff',
          fontSize: 14,
        },
      })
      text.node.id(Math.random().toString(16).slice(2))
      return text
    }

    if (o.type === EditorEnums.EditorObjectsType.Line) {
      const line = this.editor.shapes.line.insert({
        stroke: '#3c72ff',
        strokeWidth: 3,
        points: [100, 100, 300, 100],
      })
      line.node.id(Math.random().toString(16).slice(2))
      return line
    }

    if (o.type === EditorEnums.EditorObjectsType.QuadraticBezier) {
      const curve = this.editor.shapes.curve.insert({})
      curve.node.id(Math.random().toString(16).slice(2))
      return curve
    }

    if (o.type === EditorEnums.EditorObjectsType.HalfRoadSegment) {
      const roadLane = this.editor.shapes.halfRoadSegment.insert({
        title: o.title,
        leftLanes: o?.leftLanes,
        rightLanes: o?.rightLanes,
        id: o.id || Math.random().toString(16).slice(2),
      })
      return roadLane
    }

    return null
  }

  public createPreset(preset: ShapesPresetsType, presetData: Record<string, any>) {
    this.editor.off('shape:create', this.handleEditorShapeCreate)
    if (preset.type === EditorEnums.EditorObjectsType.RoadGate) {
      generateRoadGate(this.editor, presetData, DEFAULT_CONFIG_ROAD_GATES_PRESETS[preset.id])
      this.saveBoard()
    }

    if (preset.type === EditorEnums.EditorObjectsType.RoadSegment) {
      roadSegmentGenerator(this.editor, presetData)
      this.saveBoard()
    }

    this.editor.on('shape:create', this.handleEditorShapeCreate)
  }

  public hasObject(id: string) {
    const shapes = this.editor.board.shapes.find((s) => s.node.id() === id)
    return Boolean(shapes)
  }

  private deleteGroup(shape: GroupModel | ShapeModel, silent?: boolean) {
    const isRoadGate = shape.name.includes(EditorEnums.EditorObjectsType.RoadGate)
    const dependencyShapes: ShapeModel[] = []
    const groupId = shape.node.id()
    let groupType: EditorEnums.EditorObjectsType | null = null

    if (isRoadGate) {
      const shapes = this.editor.board.shapes.filter((s) => {
        return (
          s.node.name() === EditorEnums.EditorObjectsType.RoadGateWay &&
          s.node.id().includes(shape.node.id())
        )
      })
      dependencyShapes.push(...shapes)
      groupType = EditorEnums.EditorObjectsType.RoadGate
    }

    const group = this.editor.board.groups.find(shape.name)
    if (!group) return
    group.children.forEach(this.destroyShape)
    dependencyShapes.forEach(this.destroyShape)
    this.editor.board.groups.destroy(shape.name)
    this.editor.board.groups.ungroup(shape.name)

    if (!groupType) return
    !silent &&
      this.eventEmitter.dispatchEvent(EditorEnums.EditorActions.deleteObject, {
        type: groupType,
        id: groupId,
      })
  }

  private destroyShape(shape: ShapeModel) {
    shape.delete()
    shape.destroy()
  }

  public deleteObject(id: string, silent?: boolean) {
    const shape = this.editor.board.shapes.find((s) => s.node.id() === id)
    if (!shape) return
    if (shape.type === 'group') return this.deleteGroup(shape, silent)

    this.destroyShape(shape)
    if (silent) return
    this.eventEmitter.dispatchEvent(EditorEnums.EditorActions.deleteObject, {
      type: shape.type,
      id,
    })
  }

  public batchDelete(ids: string[]) {
    ids.forEach((id) => this.deleteObject(id, true))
    this.eventEmitter.dispatchEvent(EditorEnums.EditorActions.batchDelete, {
      id: ids,
    })
    this.editor?.selection.deselectAll()
    this.saveBoard()
  }

  public validateShapes(ids: string[]) {
    const brokenShapesId = this.editor.board.shapes
      .filter((shape) => {
        const hasInTree = ids.includes(shape.node.id())
        const isShape = (shape: ShapeModel) => {
          return (
            isRoadGateShape(shape) ||
            [
              EditorEnums.EditorObjectsType.Camera,
              EditorEnums.EditorObjectsType.HalfRoadSegment,
            ].includes(shape.name as EditorEnums.EditorObjectsType)
          )
        }
        return isShape(shape) && !hasInTree
      })
      .map((shape) => shape.node.id())
    if (!brokenShapesId.length) return
    brokenShapesId.forEach((id) => this.deleteObject(id, true))
    this.saveBoard(true)
    this.messages?.show({
      closable: false,
      severity: 'warn',
      life: 4000,
      summary:
        'Некоторые объекты были удалены с рабочего листа, скорее всего их удалили вне редактора.',
    })
  }

  public configurableShapes(shapesConfig: EditorObjects[]) {
    shapesConfig?.forEach((shapeConfig) => {
      const findShape = this.editor.board.shapes.find((shapeModel) => {
        return shapeModel.node.attrs?.id === shapeConfig.id
      })
      if (!findShape) return
      this.toggleVisible(findShape, !shapeConfig.visible)

      if (shapeConfig.type === EditorEnums.EditorObjectsType.RoadGate) {
        const g = this.editor.board.groups.find(findShape.name)
        if (!g?.children) return

        const label = g.children.find((shape) => shape.node.attrs.name === 'label') as LabelModel
        const valueOut = g.children.find(
          (shape) => shape.node.attrs.name === 'valueOut',
        ) as LabelModel
        const valueIn = g.children.find(
          (shape) => shape.node.attrs.name === 'valueIn',
        ) as LabelModel
        if (!label || !valueIn || !valueOut) return
        ;[label, valueIn, valueOut].forEach((s) => {
          s.node.setAttr('isActive', shapeConfig.isActive)
          s.updateText({
            fill: shapeConfig.isActive ? 'white' : '#959da8',
          })
        })
        shapeConfig?.label && label.updateText({ text: shapeConfig.label })
        shapeConfig?.label && label.updateText({ text: shapeConfig.label })
        shapeConfig?.value?.in && valueIn.updateText({ text: shapeConfig.value.in })
        shapeConfig?.value?.out && valueOut.updateText({ text: shapeConfig.value.out })
        return
      }

      if (shapeConfig.type === EditorEnums.EditorObjectsType.Text) {
        // @ts-ignore
        shapeConfig?.label && findShape?.updateText({ text: shapeConfig.label })
      }

      if (shapeConfig.type === EditorEnums.EditorObjectsType.Camera) {
        shapeConfig.label && findShape.node.setAttr('title', shapeConfig.label)
      }
    })
  }

  private toggleVisible(shape: ShapeModel, isVisible: boolean) {
    const methodName = isVisible ? 'hide' : 'show'
    shape[methodName] && shape.node[methodName]()
  }

  public toggleVisibility(ids: Record<string, boolean>) {
    Object.entries(ids).forEach(([id, isVisible]) => {
      const shape = this.editor.board.shapes.find(
        (shape) => shape.node.id() === id || shape.name === id,
      )
      if (!shape) return
      this.toggleVisible(shape, isVisible)
    })
  }

  private async loadBoard(pikasoScheme: Record<string, any>) {
    if (!pikasoScheme) return
    const customShapes: Record<string, any>[] = []

    const presetShapes: Record<string, any> = {
      [EditorObjectsType.RoadGate]: {},
      [EditorObjectsType.RoadSegment]: [],
    }

    pikasoScheme.shapes = pikasoScheme?.shapes?.filter((shape: Record<string, any>) => {
      if (
        [
          EditorObjectsType.RoadGateWay,
          EditorObjectsType.Camera,
          EditorObjectsType.HalfRoadSegment,
          EditorObjectsType.QuadraticBezier,
        ].includes(shape.attrs.name)
      ) {
        customShapes.push(shape)
        return
      }

      if (shape.attrs?.groupName?.includes(EditorObjectsType.RoadGate)) {
        const roadGate = presetShapes[EditorObjectsType.RoadGate]
        if (!roadGate[shape.attrs?.groupId]) roadGate[shape.attrs?.groupId] = [shape]
        else roadGate[shape.attrs?.groupId].push(shape)
        return
      }

      // EditorObjectsType.RoadSegment только 1 всегда
      if (shape.attrs?.groupName?.includes(EditorObjectsType.RoadSegment)) {
        const roadSegment = presetShapes[EditorObjectsType.RoadSegment]
        roadSegment.push(shape)
        return
      }

      return true
    })
    pikasoScheme?.shapes && (await this.editor.import.json(pikasoScheme as any))
    roadGateDeserializer(this.editor, presetShapes[EditorObjectsType.RoadGate])
    roadSegmentDeserializer(this.editor, presetShapes[EditorObjectsType.RoadSegment])

    customShapes.forEach((shape: any) => {
      if (shape.attrs.name === EditorObjectsType.RoadGateWay) {
        this.editor.shapes.roadGateWay.insert({ ...shape })
      }

      if (shape.attrs.name === EditorObjectsType.Camera) {
        this.editor.shapes.camera.insert({ ...shape })
      }

      if (shape.attrs.name === EditorObjectsType.HalfRoadSegment) {
        this.editor.shapes.halfRoadSegment.insert({ ...shape.attrs })
      }

      if (shape.attrs.name === EditorObjectsType.QuadraticBezier) {
        this.editor.shapes.curve.insert({ ...shape.attrs })
      }
    })
  }

  public async saveBoard(silent?: boolean) {
    const hideThen: any[] = []
    this.editor?.board.shapes
      .filter((shape) => !shape.isVisible)
      .forEach((s) => {
        // если будет моргать
        // добавить сначала прозрачность, а затем показать
        // s.node.opacity(0)
        s.show()
        hideThen.push(s)
      })
    const json = this.editor?.export.toJson()
    hideThen.forEach((s) => s.hide())
    if (!json) return
    try {
      await createEditEntity(
        this.api,
        {
          id: this.entity.id,
          type: 'SvgView',
          pikasoScheme: encodeURIComponent(JSON.stringify(json)),
        },
        {
          id: this.entity.id,
          type: 'SvgView',
        },
      )

      !silent &&
        this.messages.show({
          closable: false,
          severity: 'success',
          life: 1000,
          summary: 'Успешно сохранено',
        })
    } catch (e) {
      this.messages.show({
        closable: false,
        life: 1000,
        severity: 'error',
        summary: 'Не удалось сохранить',
      })
    }
  }

  public drawObject(
    objects: EditorModels.BaseShapeSetting & { title?: string; icon?: Record<string, any> },
  ) {
    if (objects.type === EditorEnums.EditorObjectsType.RoadGateWay) {
      const hasSomeRoadGate = this.editor.board.shapes.find((s) =>
        s.name.includes(EditorEnums.EditorObjectsType.RoadGate),
      )
      if (!hasSomeRoadGate) {
        this.messages?.show({
          closable: false,
          severity: 'error',
          life: 1500,
          summary: 'Нет ни одного созданного створа',
        })
        return
      }
      this.drawRoadGateWay()
    }
  }

  private drawRoadGateWay() {
    const roadGatesId: string[] = []
    this.messages?.show({
      closable: false,
      severity: 'info',
      life: 30000,
      summary: 'Для создания поворота выберите створ въезда',
    })
    const listenerClick = (e: CustomEvent<{ type: string; id: string }>) => {
      const isRoadGate = e.detail.type === EditorEnums.EditorObjectsType.RoadGate
      if (!isRoadGate) return
      roadGatesId.push(e.detail.id)
      this.messages?.clear()

      if (roadGatesId.length === 2) {
        this.dispatchNode?.removeEventListener(
          EditorEnums.EditorActions.clickObject,
          listenerClick as any,
        )
        const [idOne, idTwo] = roadGatesId

        if (this.hasObject(idOne + idTwo)) {
          this.messages?.clear()
          this.messages?.show({
            closable: false,
            life: 3000,
            severity: 'warn',
            summary: 'Выбранное направление уже создано',
          })
          return
        }

        const firstRoadGate = this.editor.board.shapes.find(
          (shape) => shape.node.attrs?.groupId === idOne && shape.type === 'line',
        ) as LineModel | undefined
        const secondRoadGate = this.editor.board.shapes.find(
          (shape) => shape.node.attrs?.groupId === idTwo && shape.type === 'line',
        ) as LineModel | undefined

        const BASE_WIDTH = 130
        const f = firstRoadGate?.node.getClientRect()
        const s = secondRoadGate?.node.getClientRect()
        if (!f || !s) return
        const isHorizontalFirst = f.width > f.height
        const isHorizontalSecond = s.width > s.height

        const firstCenter = getLineCenter([
          f.x,
          f.y,
          isHorizontalFirst ? BASE_WIDTH + f.x : f.x,
          isHorizontalFirst ? f.y : f.y + BASE_WIDTH,
        ])

        const secondCenter = getLineCenter([
          s.x,
          s.y,
          isHorizontalSecond ? BASE_WIDTH + s.x : s.x,
          isHorizontalSecond ? s.y : s.y + BASE_WIDTH,
        ])

        const control = getLineCenter([
          1,
          1,
          secondCenter.x - firstCenter.x + 1,
          secondCenter.y - firstCenter.y + 1,
        ])

        const isSameRoadGate = idOne === idTwo

        this.editor.shapes.roadGateWay.insert({
          id: idOne + idTwo,
          x: firstCenter.x,
          y: firstCenter.y,
          attrs: {
            start: {
              x: 1,
              y: 1,
            },
            control: isSameRoadGate ? { x: control.x, y: control.y + 75 } : control,
            end: {
              x: secondCenter.x - firstCenter.x + (isSameRoadGate ? 30 : 1),
              y: secondCenter.y - firstCenter.y + 1,
            },
          },
        })
        this.saveBoard()
        return
      }

      this.messages?.show({
        closable: false,
        life: 30000,
        severity: 'info',
        summary: 'Для завершения поворота выберите створ выезда',
      })
    }

    this.dispatchNode?.addEventListener(EditorEnums.EditorActions.clickObject, listenerClick as any)
  }

  public updateObject(o: EditorModels.BaseShapeSetting & { label?: string }) {
    const findShape = this.editor.board.shapes.find((s) => s.node.attrs?.id === o.id)
    if (!findShape) return

    if (o.type === EditorEnums.EditorObjectsType.RoadGate) {
      const g = this.editor.board.groups.find(findShape.name)
      if (!g?.children) return
      const label = g.children.find((shape) => shape.node.attrs.name === 'label') as
        | LabelModel
        | undefined
      if (!label) return
      o?.label && label.updateText({ text: o.label })
      return
    }

    if (o.type === EditorEnums.EditorObjectsType.Camera) {
      o.label && findShape.node.setAttr('title', o.label)
    }
  }
}
