import Pikaso from 'pikaso'
import { EditorEnums } from '../../../../ts'
import { roadGateBuilder, Shape } from '../../shapes'

export const ROAD_GATE_WIDTH = 3 // TODO import from road-gate shape
export const LAYOUT_SETTINGS = {
  roadGateSize: 150,
  roadGateGup: 5,
  startPosition: {
    x: 200,
    y: 200,
  },
}

const generateRoadGateWayConfig = (DEFAULT_SIDES_SETTINGS: Record<string, any>) => {
  const config: Record<string, any> = {}
  Object.entries(DEFAULT_SIDES_SETTINGS.sides).forEach((sisesConfig) => {
    const [sideName, settings] = sisesConfig as [string, Record<string, any>]
    config[sideName] = {}
    Object.entries(settings.roadGateWay).forEach((roadGateWayData) => {
      const [roadGateWayKey, roadGateWayConfig] = roadGateWayData as [string, Record<string, any>[]]
      const [start, end, control] = roadGateWayConfig
      config[sideName][roadGateWayKey] = {
        x: settings.position.x,
        y: settings.position.y,
        attrs: {
          start: { x: start[0], y: start[1] },
          end: { x: end[0], y: end[1] },
          control: { x: control[0], y: control[1] },
        },
      }
    })
  })
  return config
}

export const generateRoadGate = (
  editor: Pikaso<Shape>,
  presetData: Record<string, any>,
  type: Record<string, any>,
) => {
  if (!type) return
  const config = generateRoadGateWayConfig(type)
  const roadGates = presetData[EditorEnums.EditorObjectsType.RoadGate]
  const roadGateWay = presetData.RoadGateWay
  if (!roadGateWay) return
  if (!Array.isArray(roadGates) || roadGates.length !== Object.entries(type.sides).length)
    return console.warn('roadGates is not Array or bad array length')

  roadGates.forEach((roadGate) => {
    const { id: roadGateId, title: roadGateTitle, position } = roadGate || {}
    if (!roadGateId || !roadGateTitle)
      return console.warn('error in generateRoadGate Preset bad id or title')
    const sideName = position
    const sideConfig = type.sides[sideName as 'top' | 'bottom' | 'left' | 'right']
    if (!sideConfig) return
    const roadGateWayPreset = roadGateWay[sideName]
    const isHorizontalOrientation = sideName === 'top' || sideName === 'bottom'
    const endPointXDelta = isHorizontalOrientation ? LAYOUT_SETTINGS.roadGateSize : 0
    const endPointYDelta = !isHorizontalOrientation ? LAYOUT_SETTINGS.roadGateSize : 0

    const points = [
      sideConfig.position.x,
      sideConfig.position.y,
      sideConfig.position.x + endPointXDelta,
      sideConfig.position.y + endPointYDelta,
    ]

    roadGateBuilder(editor, {
      id: roadGateId,
      title: roadGateTitle,
      groupAngle: sideConfig.position?.angle,
      points,
      isHorizontalOrientation,
      valueIn: sideConfig.valueIn,
      valueOut: sideConfig.valueOut,
      label: sideConfig.label,
    })

    Object.entries(config[sideName]).forEach(([directionKey, curveConfig]) => {
      const relation = roadGateWayPreset[directionKey]
      if (!relation) return
      const { source, target } = relation
      editor.shapes.roadGateWay.insert({
        ...(curveConfig as Record<string, any>),
        id: `${source.id}${target.id}`,
      })
    })
  })
}
