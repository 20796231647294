import Pikaso, { Settings } from 'pikaso'
import { BaseShapes, RegisterShapesFn } from 'pikaso/esm/types'
import React, { useState, useEffect, useMemo, useRef } from 'react'

export function usePikaso<T extends BaseShapes>(
  settings?: Partial<Settings>,
  registerShapeFunction?: RegisterShapesFn<T>,
) {
  const editorContainer = useRef<HTMLDivElement>(null!)
  const [instance, setInstance] = useState<Pikaso<T> | null>(null)

  useEffect(() => {
    try {
      const pikasoInstance = new Pikaso(
        {
          container: editorContainer.current,
          ...settings,
        },
        registerShapeFunction,
      )

      setInstance(pikasoInstance)
    } catch (e) {
      throw new Error('can not create pikaso instance')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    editor: instance,
    ref: editorContainer,
  }
}

function throttle(func: () => void, wait: number) {
  let timeoutId: any = null
  let lastArguments: any = null
  let lastThis: any = null

  return (...args: any) => {
    // @ts-ignore
    const self = this

    if (timeoutId) {
      lastArguments = args
      lastThis = self
      return
    }

    func.apply(self, args)
    timeoutId = setTimeout(() => {
      if (lastArguments) {
        func.apply(lastThis, lastArguments)
        timeoutId = null
        lastArguments = null
        lastThis = null
      }
    }, wait)
  }
}
