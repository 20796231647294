import React, { createContext, useContext, useState, useEffect } from 'react'
import { ApiRepositoryProvider } from './providers'

import { IWidgetProps } from './IWidgetProps'
import { Editor } from './modules'
import { EditorModels } from './ts'

import isFunction from 'lodash-es/isFunction'

const WidgetPropsContext = createContext<IWidgetProps>(null!)
export const useWidgetProps = () => useContext(WidgetPropsContext)

const DEFAULT_API_REPO = { name: 'lib-api' }

const Root = (widgetProps: IWidgetProps) => {
  const { subscribe } = widgetProps.props || {}
  const [props, setProps] = useState<EditorModels.EditorProps>(
    widgetProps.props || ({} as EditorModels.EditorProps),
  )

  useEffect(() => {
    if (!isFunction(subscribe)) return
    return subscribe((newProps) => {
      setProps((prev) => ({ ...prev, ...newProps }))
    })
  }, [subscribe])

  return (
    <WidgetPropsContext.Provider value={widgetProps}>
      <ApiRepositoryProvider value={widgetProps.props?.libApiRepo || DEFAULT_API_REPO}>
        <Editor ref={props.openApiRef} {...props} />
      </ApiRepositoryProvider>
    </WidgetPropsContext.Provider>
  )
}

export default Root
