import React, { createContext, ReactNode, useContext, useMemo } from 'react'
import { repositoryGetter, TLibProp, TUnionRepo } from '@netvision/lib-api-repo'

export type TApiRepositoryProviderProps = {
  value: TLibProp
  children: ReactNode
}

export const useGetApiRepository = () => useContext<TUnionRepo>(ApiRepositoryContext)

export const ApiRepositoryContext = createContext<any>(null!)
export const ApiRepositoryProvider = ({ children, value }: TApiRepositoryProviderProps) => {
  const api = useMemo(() => repositoryGetter(value), [value])
  return <ApiRepositoryContext.Provider value={api}>{children}</ApiRepositoryContext.Provider>
}
