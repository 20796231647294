import { Konva, ShapeModel, Board, ShapeDrawer, getPointsDistance } from 'pikaso'

import { ShapeUtils } from '../'
import { EditorObjectsType } from '../../../../ts/enum/editor'
import { iconsMap } from './icon-map'

const findAngle = (sx: number, sy: number, ex: number, ey: number) => Math.atan2(ey - sy, ex - sx)

export class CameraModel extends ShapeModel<Konva.Group, Konva.GroupConfig> {
  constructor(board: Board, node: Konva.Group) {
    super(board, node)
    this.config = {
      ...this.config,
      transformer: {
        enabledAnchors: [],
      },
    }
  }

  public get type() {
    return EditorObjectsType.Camera as string
  }
}

export class CameraDrawer extends ShapeDrawer<
  // @ts-ignore
  Konva.Group,
  Konva.GroupConfig
> {
  public node: Konva.Group | null = null

  constructor(board: Board) {
    super(board, EditorObjectsType.Camera)
  }

  public insert(config: Konva.GroupConfig): CameraModel {
    return super.insert(config)
  }

  public draw(config: Konva.GroupConfig) {
    super.draw(config)
  }

  protected createShape(
    config: Konva.GroupConfig & { title?: string; icon?: string },
  ): CameraModel {
    const configHandlers = {
      startAngle: { x: 110, y: 50, ...config?.attrs?.startAngle },
      endAngle: { x: 50, y: 110, ...config?.attrs?.endAngle },
      center: { x: 50, y: 50, ...config?.attrs?.center },
    }

    const transformData = {
      rotation: config?.attrs?.rotation,
      scaleX: config?.attrs?.scaleX,
      scaleY: config?.attrs?.scaleY,
      skewX: config?.attrs?.skewX,
    }

    const handlers: Record<keyof typeof configHandlers, any> = {
      center: new Konva.Group({
        x: configHandlers.center.x,
        y: configHandlers.center.y,
      }),
      endAngle: {},
      startAngle: {},
    }

    const cameraCenter = new Konva.Circle({
      radius: 11,
      stroke: 'rgb(149, 157, 168)', // --text-color-secondary
      fill: 'rgb(149, 157, 168)',
      strokeWidth: 1,
      draggable: false,
    })

    const iconKey = (config?.attrs?.icon || 'mdi-camera').slice(4)
    const iconSymbol = iconsMap[iconKey]
    const cameraCenterIcon = new Konva.Text({
      x: -7,
      y: -6,
      text: iconSymbol,
      fontSize: 14,
      fontFamily: '"Material Design Icons"',
      fill: '#fff',
    })

    handlers.center.add(cameraCenter, cameraCenterIcon)

    this.node = new Konva.Group({
      x: config?.attrs?.x || 100,
      y: config?.attrs?.y || 100,
      id: config?.attrs?.id,
      name: EditorObjectsType.Camera,
      ...transformData,
    })

    const cameraTooltipText = new Konva.Text({
      text: '',
      fontSize: 14,
      opacity: 1,
      verticalAlign: 'middle',
      fill: '#fff',
      padding: 5,
    })

    const cameraTooltipTag = new Konva.Tag({
      fill: 'rgba(60, 114, 255, 1)',
      cornerRadius: 8,
    })
    const cameraTooltipLabel = new Konva.Label({ visible: false })

    cameraTooltipLabel.add(cameraTooltipTag, cameraTooltipText)

    Object.entries(configHandlers).forEach((entriesProps) => {
      const [key, coords] = entriesProps as [keyof typeof configHandlers, Record<string, any>]

      if (key === 'center') {
        this.node?.setAttr(key, configHandlers.center)
        return
      }

      handlers[key] = ShapeUtils.createHandler({
        x: coords.x,
        y: coords.y,
        config: {
          fill: '#fff',
          stroke: '#000',
          radius: 4,
        },
        onDragMove: (e) => {
          const { target } = e
          this.node?.setAttr(key, {
            x: target.x(),
            y: target.y(),
          })
        },
      })

      this.node?.setAttr(key, coords)
    })

    const cameraLight = new Konva.Shape({
      fill: 'rgba(60, 114, 255, 0.81)', // --primary-color
      width: 100,
      height: 100,
      sceneFunc: (ctx, shape) => {
        const startRadian = findAngle(
          handlers.center.x(),
          handlers.center.y(),
          handlers.startAngle.x(),
          handlers.startAngle.y(),
        )
        const endRadian = findAngle(
          handlers.center.x(),
          handlers.center.y(),
          handlers.endAngle.x(),
          handlers.endAngle.y(),
        )
        ctx.moveTo(0, 0)
        ctx.beginPath()
        ctx.arc(handlers.center.x(), handlers.center.y(), 55, startRadian, endRadian)
        ctx.lineTo(handlers.center.x(), handlers.center.y())
        ctx.fillStrokeShape(shape)
      },
    })

    this.node.setAttr('icon', config?.attrs?.icon)
    this.node.setAttr('isTransform', false)
    this.node.setAttr('title', config?.attrs.title || String(config.attrs.id))
    const nodes = [handlers.endAngle, handlers.startAngle, cameraLight]
    nodes?.forEach((n) => n?.opacity(0))
    this.node.add(cameraLight, ...Object.values(handlers))

    handlers.center.on('mousemove', (e: Record<string, any>) => {
      const title = e.target.parent.parent.attrs.title
      const mousePos = this.board.stage.getPointerPosition()
      if (!mousePos) return
      cameraLight.opacity(1)
      cameraTooltipLabel.position({
        x: mousePos.x + 5,
        y: mousePos.y + 5,
      })
      // this.node?.getAttr('title') TODO так не робит, тут this.node всегда последний
      // Нужно разобраться почему +  скорее всего течет
      cameraTooltipText.text(title)
      cameraTooltipLabel.show()
    })

    handlers.center.on('mouseout', function (e: Record<string, any>) {
      const node = e.target.parent.parent
      cameraTooltipLabel.hide()
      if (node.getAttr('isTransform')) return
      cameraLight.opacity(0)
    })

    const [, tooltipLayer] = this.board.stage.getLayers()
    tooltipLayer.add(cameraTooltipLabel)

    handlers.center.on('dblclick', (e: Record<string, any>) => {
      const node = e.target.parent.parent
      const isTransform = node.getAttr('isTransform')
      const transformValue = !isTransform
      node.setAttr('isTransform', transformValue)

      cameraCenter.setAttrs({
        stroke: transformValue ? '#3C72FF' : 'rgb(149, 157, 168)',
        fill: transformValue ? '#fff' : 'rgb(149, 157, 168)',
      })

      cameraCenterIcon.setAttr('fill', transformValue ? '#545C64' : '#fff')

      nodes.forEach((n) => n.opacity(transformValue ? 1 : 0))
    })

    return new CameraModel(this.board, this.node)
  }

  protected onDrawing(e: Konva.KonvaEventObject<MouseEvent>) {
    super.onDrawing(e)
    if (!this.node) return
    const points = this.board.stage.getPointerPosition()!
    const distance = getPointsDistance(points, this.getShapePosition())
    this.node.setAttrs({
      x: points.x - this.node.width(),
      scaleX: distance / 10,
      scaleY: distance / 10,
    })
  }
}
