import { Konva } from 'pikaso'

interface HandlerProps {
  x: number
  y: number
  config?: Konva.CircleConfig
  onDragMove?: (e: Record<string, any>) => void
}

const DEFAULT_CONFIG = {
  radius: 6,
  stroke: '#666',
  fill: '#ddd',
  strokeWidth: 2,
}

export const createHandler = (props: HandlerProps) => {
  const { config = {}, x, y, onDragMove } = props || {}
  if (!x || !y) return console.warn('bad coords for createHandler')

  const anchor = new Konva.Circle({
    x,
    y,
    draggable: true,
    ...DEFAULT_CONFIG,
    ...config,
  })

  onDragMove && anchor.on('dragmove', onDragMove)
  return anchor
}
