import { EditorEnums } from '../../../ts'

import { DEFAULT_X_SHAPE } from './road-gate/x-shape'
import { DEFAULT_T_SHAPE } from './road-gate/t-shape'
import { DEFAULT_Y_SHAPE } from './road-gate/y-shape'

export { generateRoadGate } from './road-gate'
export { roadSegmentGenerator } from './road-segment'

export enum RoadGatePresets {
  XShaped = 'XShaped',
  YShaped = 'YShaped',
  TShaped = 'TShaped',
}

export interface RoadGatePreset {
  type: EditorEnums.EditorObjectsType.RoadGate
  id: RoadGatePresets
}

export interface RoadSegmentPreset {
  type: EditorEnums.EditorObjectsType.RoadSegment
}

export const DEFAULT_CONFIG_ROAD_GATES_PRESETS: Record<RoadGatePresets, any> = {
  [RoadGatePresets.TShaped]: DEFAULT_T_SHAPE,
  [RoadGatePresets.YShaped]: DEFAULT_Y_SHAPE,
  [RoadGatePresets.XShaped]: DEFAULT_X_SHAPE,
}

export type ShapesPresetsType = RoadGatePreset | RoadSegmentPreset
