import { LAYOUT_SETTINGS, ROAD_GATE_WIDTH } from '.'
export const DEFAULT_X_SHAPE = {
  sides: {
    top: {
      valueIn: {
        container: { x: LAYOUT_SETTINGS.startPosition.x, y: LAYOUT_SETTINGS.startPosition.y - 25 },
      },
      valueOut: {
        container: {
          x: LAYOUT_SETTINGS.startPosition.x + LAYOUT_SETTINGS.roadGateSize - 35,
          y: LAYOUT_SETTINGS.startPosition.y - 25,
        },
      },
      label: {
        container: {
          x: (LAYOUT_SETTINGS.startPosition.x + LAYOUT_SETTINGS.roadGateSize) / 1.5,
          y: LAYOUT_SETTINGS.startPosition.y - 45,
        },
      },
      position: {
        x: LAYOUT_SETTINGS.startPosition.x + ROAD_GATE_WIDTH,
        y: LAYOUT_SETTINGS.startPosition.y - LAYOUT_SETTINGS.roadGateGup,
      },
      roadGateWay: {
        BackwardDirection: [
          [60, 1],
          [LAYOUT_SETTINGS.roadGateSize - 60, 1],
          [75, 75],
        ],
        RightDirection: [
          [12, 1],
          [3, 16],
          [16, 14],
        ],
        ForwardDirection: [
          [24, 1],
          [24, LAYOUT_SETTINGS.roadGateSize + LAYOUT_SETTINGS.roadGateGup + ROAD_GATE_WIDTH],
          [24, 50],
        ],
        LeftDirection: [
          [36, 1],
          [LAYOUT_SETTINGS.roadGateSize, LAYOUT_SETTINGS.roadGateSize - 36],
          [24, LAYOUT_SETTINGS.roadGateSize - 36],
        ],
      },
    },
    right: {
      valueIn: {
        container: {
          x: LAYOUT_SETTINGS.startPosition.x + LAYOUT_SETTINGS.roadGateSize,
          y: LAYOUT_SETTINGS.startPosition.y + 20,
        },
        angle: 0,
      },
      valueOut: {
        container: {
          x: LAYOUT_SETTINGS.startPosition.x + LAYOUT_SETTINGS.roadGateSize,
          y: LAYOUT_SETTINGS.startPosition.y + LAYOUT_SETTINGS.roadGateSize - 30,
        },
        angle: 0,
      },
      label: {
        container: {
          x: LAYOUT_SETTINGS.startPosition.x + +LAYOUT_SETTINGS.roadGateSize + 10,
          y: LAYOUT_SETTINGS.startPosition.y + LAYOUT_SETTINGS.roadGateSize / 2,
        },
        angle: 0,
      },
      position: {
        x:
          LAYOUT_SETTINGS.startPosition.x +
          LAYOUT_SETTINGS.roadGateSize +
          LAYOUT_SETTINGS.roadGateGup,
        y: LAYOUT_SETTINGS.startPosition.y,
      },
      roadGateWay: {
        BackwardDirection: [
          [-3, 60],
          [-3, LAYOUT_SETTINGS.roadGateSize - 60],
          [-75, 75],
        ],
        ForwardDirection: [
          [-3, 24],
          [-LAYOUT_SETTINGS.roadGateSize, 24],
          [-50, 24],
        ],
        RightDirection: [
          [-3, 16],
          [-12, 1],
          [-13, 13],
        ],
        LeftDirection: [
          [-3, 36],
          [-(LAYOUT_SETTINGS.roadGateSize - 36), LAYOUT_SETTINGS.roadGateSize],
          [-(LAYOUT_SETTINGS.roadGateSize - 24), 24],
        ],
      },
    },
    bottom: {
      valueIn: {
        container: {
          x: LAYOUT_SETTINGS.startPosition.x,
          y:
            LAYOUT_SETTINGS.startPosition.y +
            LAYOUT_SETTINGS.roadGateSize +
            LAYOUT_SETTINGS.roadGateGup * 2 +
            ROAD_GATE_WIDTH,
        },
      },
      valueOut: {
        container: {
          x: LAYOUT_SETTINGS.startPosition.x + LAYOUT_SETTINGS.roadGateSize - 55,
          y:
            LAYOUT_SETTINGS.startPosition.y +
            LAYOUT_SETTINGS.roadGateSize +
            LAYOUT_SETTINGS.roadGateGup * 2 +
            ROAD_GATE_WIDTH,
        },
      },
      label: {
        container: {
          x: (LAYOUT_SETTINGS.startPosition.x + LAYOUT_SETTINGS.roadGateSize) / 1.5,
          y:
            LAYOUT_SETTINGS.startPosition.y +
            LAYOUT_SETTINGS.roadGateSize +
            LAYOUT_SETTINGS.roadGateGup * 6 +
            ROAD_GATE_WIDTH,
        },
      },
      position: {
        x: LAYOUT_SETTINGS.startPosition.x + ROAD_GATE_WIDTH,
        y:
          LAYOUT_SETTINGS.startPosition.y +
          LAYOUT_SETTINGS.roadGateSize +
          LAYOUT_SETTINGS.roadGateGup,
      },
      roadGateWay: {
        BackwardDirection: [
          [LAYOUT_SETTINGS.roadGateSize - 60, -1],
          [60, -1],
          [75, -75],
        ],
        RightDirection: [
          [LAYOUT_SETTINGS.roadGateSize - 12, -1],
          [LAYOUT_SETTINGS.roadGateSize - 1, -16],
          [LAYOUT_SETTINGS.roadGateSize - 13, -14],
        ],
        ForwardDirection: [
          [LAYOUT_SETTINGS.roadGateSize - 24, -1],
          [
            LAYOUT_SETTINGS.roadGateSize - 24,
            -(LAYOUT_SETTINGS.roadGateSize + LAYOUT_SETTINGS.roadGateGup),
          ],
          [LAYOUT_SETTINGS.roadGateSize - 24, -50],
        ],
        LeftDirection: [
          [LAYOUT_SETTINGS.roadGateSize - 36, -1],
          [1, -(LAYOUT_SETTINGS.roadGateSize - 36)],
          [LAYOUT_SETTINGS.roadGateSize - 26, -(LAYOUT_SETTINGS.roadGateSize - 24)],
        ],
      },
    },
    left: {
      valueIn: {
        container: {
          x: LAYOUT_SETTINGS.startPosition.x - 35,
          y: LAYOUT_SETTINGS.startPosition.y + 20,
        },
        angle: 0,
      },
      valueOut: {
        container: {
          x: LAYOUT_SETTINGS.startPosition.x - 40,
          y: LAYOUT_SETTINGS.startPosition.y + LAYOUT_SETTINGS.roadGateSize - 30,
        },
        angle: 0,
      },
      label: {
        container: {
          x: LAYOUT_SETTINGS.startPosition.x - 85,
          y: LAYOUT_SETTINGS.startPosition.y + LAYOUT_SETTINGS.roadGateSize / 2,
        },
        angle: 0,
      },
      position: {
        x: LAYOUT_SETTINGS.startPosition.x,
        y: LAYOUT_SETTINGS.startPosition.y,
      },
      roadGateWay: {
        BackwardDirection: [
          [1, LAYOUT_SETTINGS.roadGateSize - 60],
          [1, 60],
          [75, 75],
        ],
        RightDirection: [
          [1, LAYOUT_SETTINGS.roadGateSize - 16],
          [11, LAYOUT_SETTINGS.roadGateSize + LAYOUT_SETTINGS.roadGateGup],
          [12.6, LAYOUT_SETTINGS.roadGateSize - 9],
        ],
        ForwardDirection: [
          [1, LAYOUT_SETTINGS.roadGateSize - 24],
          [
            LAYOUT_SETTINGS.roadGateSize + LAYOUT_SETTINGS.roadGateGup,
            LAYOUT_SETTINGS.roadGateSize - 24,
          ],
          [50, LAYOUT_SETTINGS.roadGateSize - 24],
        ],
        LeftDirection: [
          [1, LAYOUT_SETTINGS.roadGateSize - 36],
          [LAYOUT_SETTINGS.roadGateSize - 36, 1],
          [LAYOUT_SETTINGS.roadGateSize - 36, LAYOUT_SETTINGS.roadGateSize - 36],
        ],
      },
    },
  },
}
