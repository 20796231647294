import Pikaso from 'pikaso'
import { shapesTheme } from '../../shapes-config'

export const getLineCenter = ([x1, y1, x2, y2]: number[]) => ({
  x: (x1 + x2) / 2,
  y: (y1 + y2) / 2,
})

export const roadGateDeserializer = (editor: Pikaso, shapesConfig: Record<string, any[]> = {}) => {
  Object.entries(shapesConfig).forEach(([roadGateId, shapes]) => {
    const nodes: any[] = []
    const groupId = roadGateId
    const groupName = `RoadGate-${groupId}`
    const groupConfig = shapes.find((shape) => shape.className === 'Group')
    const g = editor.board.groups.attach([], groupName)
    g.container.node.setAttrs(groupConfig?.attrs)
    g.container.config = {
      ...g.container.config,
      ...groupConfig,
      transformer: {
        ...g.container.config.transformer,
        enabledAnchors: [],
      },
    }
    g.container.node.id(groupId)

    shapes.forEach((shapeConfig) => {
      if (shapeConfig.className === 'Line') {
        const line = editor.shapes.line.insert(shapeConfig)
        line.group = groupName
        line.config = {
          ...line.config,
          transformer: {
            ...line.config.transformer,
            centeredScaling: false,
            enabledAnchors: ['middle-right', 'middle-left', 'top-center', 'bottom-center'],
          },
        }
        nodes.push(line)
        return
      }

      if (shapeConfig.className === 'Label') {
        const label = editor.shapes.label.insert({
          container: {
            ...shapeConfig.attrs,
          },
          text: shapeConfig?.children[1]?.attrs,
          tag: shapeConfig?.children[0]?.attrs,
        })
        label.node.removeEventListener('dblclick')
        label.group = groupName
        nodes.push(label)
        label.node.setAttr('name', shapeConfig.attrs?.name)
        return
      }
    })

    let hasSomeSelect = false
    let isLargeSelect = false

    nodes.forEach((n) => {
      n.node.setAttr('groupName', groupName)
      n.node.setAttr('groupId', groupId)

      n.node.on('dblclick', () => {
        editor.selection.deselectAll()
        editor.selection.add(n)
        const g = editor.board.groups.find(groupName)
        editor.board.groups.ungroup(groupName)
        editor.board.groups.delete(groupName)
        editor.board.groups.destroy(groupName)
        g && editor.board.removeShape(g.container)
        hasSomeSelect = true
      })
    })

    editor.on('selection:change', (e) => {
      const isClearSomeShape = !e.shapes?.length
      const hasRoadGateShape = e.shapes?.find((s) => s.name === groupName)

      if (hasRoadGateShape && e.shapes?.length && e.shapes?.length > 5) {
        isLargeSelect = true
        const g = editor.board.groups.find(groupName)
        editor.board.groups.ungroup(groupName)
        editor.board.groups.delete(groupName)
        editor.board.groups.destroy(groupName)
        g && editor.board.removeShape(g.container)
        return
      }

      if (isClearSomeShape && hasSomeSelect) {
        hasSomeSelect = false
        const g = editor.board.groups.attach([...nodes], groupName)
        g.container.update({ id: groupId })
        g.container.config = {
          ...g.container.config,
          transformer: {
            ...g.container.config.transformer,
            enabledAnchors: [],
          },
        }
        return
      }
    })
  })
}

export const roadGateBuilder = (
  editor: Pikaso,
  o: {
    isHorizontalOrientation?: boolean
    groupAngle?: number
    title?: string
    id?: string
    points?: number[]
    valueIn?: Record<string, any>
    valueOut?: Record<string, any>
    label?: Record<string, any>
  },
) => {
  const groupId = o?.id || Math.random().toString(16)
  const groupName = `RoadGate-${groupId}`
  const points = o.points || [20, 20, 150, 20]

  const roadGate = editor.shapes.line.insert({
    points,
    ...shapesTheme.RoadGate.line,
  })

  roadGate.config = {
    ...roadGate.config,
    transformer: {
      ...roadGate.config.transformer,
      centeredScaling: false,
      enabledAnchors: ['middle-right', 'middle-left', 'top-center', 'bottom-center'],
    },
  }

  const textValueIn = editor.shapes.label.insert({
    container: {
      x: o.isHorizontalOrientation ? points[0] : points[0] - 20,
      y: o.isHorizontalOrientation ? points[1] - 20 : points[1] + 10,
      ...o?.valueIn?.container,
    },
    text: {
      text: 'ValueIn',
      ...shapesTheme.RoadGate.value,
    },
  })

  const textValueOut = editor.shapes.label.insert({
    container: {
      x: o.isHorizontalOrientation ? points[2] : points[2] - 20,
      y: o.isHorizontalOrientation ? points[3] - 20 : points[3] + 10,
      ...o?.valueOut?.container,
    },
    text: {
      text: 'ValueOut',
      ...shapesTheme.RoadGate.value,
    },
  })

  const { x: xLabel, y: yLabel } = getLineCenter(points)
  const roadGateLabel = editor.shapes.label.insert({
    container: { x: xLabel, y: yLabel + 25, ...o?.label?.container },
    text: {
      text: o?.title || 'LabelRoadGate',
      ...shapesTheme.RoadGate.value,
    },
  })

  roadGateLabel.update({ x: roadGateLabel.x() - roadGateLabel.width() / 2 })

  const textNodes = [roadGateLabel, textValueIn, textValueOut]
  const nodes = [...textNodes, roadGate]
  textNodes.forEach((s) => s.node.removeEventListener('dblclick'))

  roadGateLabel.node.setAttr('name', 'label')
  textValueOut.node.setAttr('name', 'valueOut')
  textValueIn.node.setAttr('name', 'valueIn')

  o?.valueIn?.angle && textValueIn.rotate(o?.valueIn?.angle)
  o?.valueOut?.angle && textValueOut.rotate(o?.valueOut?.angle)
  o?.label?.angle && roadGateLabel.rotate(o?.label?.angle)

  const g = editor.board.groups.attach([...nodes], groupName)
  g.container.update({ id: groupId })
  g.container.config = {
    ...g.container.config,
    transformer: {
      ...g.container.config.transformer,
      enabledAnchors: [],
    },
  }
  o.groupAngle && g.container.rotate(o.groupAngle)

  let hasSomeSelect = false
  let isLargeSelect = false

  nodes.forEach((n) => {
    n.node.setAttr('groupName', groupName)
    n.node.setAttr('groupId', groupId)

    n.node.on('dblclick', () => {
      editor.selection.deselectAll()
      editor.selection.add(n)
      const g = editor.board.groups.find(groupName)
      editor.board.groups.ungroup(groupName)
      editor.board.groups.delete(groupName)
      editor.board.groups.destroy(groupName)
      g && editor.board.removeShape(g.container)
      hasSomeSelect = true
    })
  })

  editor.on('selection:change', (e) => {
    const isClearSomeShape = !e.shapes?.length
    const hasRoadGateShape = e.shapes?.find((s) => s.name === groupName)

    if (hasRoadGateShape && e.shapes?.length && e.shapes?.length > 5) {
      isLargeSelect = true
      const g = editor.board.groups.find(groupName)
      editor.board.groups.ungroup(groupName)
      editor.board.groups.delete(groupName)
      editor.board.groups.destroy(groupName)
      g && editor.board.removeShape(g.container)
      return
    }

    if (isLargeSelect && isClearSomeShape) {
      isLargeSelect = false
      const g = editor.board.groups.attach([...nodes], groupName)
      g.container.update({ id: groupId })
      g.container.config = {
        ...g.container.config,
        transformer: {
          ...g.container.config.transformer,
          enabledAnchors: [],
        },
      }
      return
    }

    if (isClearSomeShape && hasSomeSelect) {
      hasSomeSelect = false
      const g = editor.board.groups.attach([...nodes], groupName)
      g.container.update({ id: groupId })
      g.container.config = {
        ...g.container.config,
        transformer: {
          ...g.container.config.transformer,
          enabledAnchors: [],
        },
      }
      return
    }
  })

  return {
    id: groupId,
  }
}
