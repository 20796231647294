export enum EditorModes {
  viewer = 'viewer',
  editor = 'editor',
}

export enum ActionToolBar {
  save = 'save',
  delete = 'delete',
}

export enum EditorObjectsType {
  Camera = 'Camera',
  Bolid = 'Bolid',
  Text = 'Text',
  Common = 'Common',
  Line = 'Line',
  Circle = 'Circle',
  QuadraticBezier = 'QuadraticBezier',
  Arrow = 'Arrow',
  RoadGate = 'RoadGate',
  RoadGateWay = 'RoadGateWay',
  RoadSegment = 'RoadSegment',
  HalfRoadSegment = 'HalfRoadSegment',
  Image = 'Image',
}

export enum EditorActions {
  addObject = 'addObject',
  deleteObject = 'deleteObject',
  addExistObject = 'addExistObject',
  addPresetObjects = 'addPresetObjects',
  objectChanged = 'objectChanged',
  drawElement = 'drawElement',
  createElement = 'createElement',
  clickObject = 'clickObject',
  updateView = 'updateView',
  batchDelete = 'batchDelete',
  load = 'load',
}
