import { EditorEnums } from '../ts'

export class EventEmitterService {
  nodeEmitter: HTMLElement
  constructor(nodeEmitter: HTMLElement | null) {
    if (!nodeEmitter) throw Error('nodeEmitter equal null')
    this.nodeEmitter = nodeEmitter
  }

  dispatchEvent(eventType: EditorEnums.EditorActions, payload: Record<string, any>) {
    const event = new CustomEvent(eventType, { detail: { eventType, ...payload } })
    this.nodeEmitter.dispatchEvent(event)
  }

  static dispatchEvent(
    eventType: EditorEnums.EditorActions,
    payload: Record<string, any>,
    nodeEmitter: HTMLElement,
  ) {
    const event = new CustomEvent(eventType, { detail: { eventType, ...payload } })
    nodeEmitter.dispatchEvent(event)
  }
}
