import { css } from '@emotion/react'

export const editorWrapper = css`
  position: relative;
  width: 100%;
  height: 100%;
`
export const editorStyles = css`
  height: 100%;
  width: 100%;
`
export const editorLoading = css`
  &.p-progress-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

export const messagesStyle = css`
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
`
