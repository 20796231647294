export const iconsMap = {
  abacus: '󱛠',
  'alarm-light-off': '󱜞',
  'alarm-light-off-outline': '󱜟',
  broadcast: '󱜠',
  'broadcast-off': '󱜡',
  'calendar-clock-outline': '󱛡',
  'chat-question': '󱜸',
  'chat-question-outline': '󱜹',
  'check-decagram-outline': '󱝀',
  'clipboard-clock': '󱛢',
  'clipboard-clock-outline': '󱛣',
  'cookie-clock': '󱛤',
  'cookie-clock-outline': '󱛥',
  'cookie-edit': '󱛦',
  'cookie-edit-outline': '󱛧',
  'cookie-lock': '󱛨',
  'cookie-lock-outline': '󱛩',
  'cookie-off': '󱛪',
  'cookie-off-outline': '󱛫',
  'cookie-refresh': '󱛬',
  'cookie-refresh-outline': '󱛭',
  'dog-side-off': '󱛮',
  'fan-auto': '󱜝',
  'fire-off': '󱜢',
  'firework-off': '󱜣',
  'food-turkey': '󱜜',
  'gift-off': '󱛯',
  'gift-off-outline': '󱛰',
  'gift-open': '󱛱',
  'gift-open-outline': '󱛲',
  'kettle-pour-over': '󱜼',
  koala: '󱜿',
  'message-question': '󱜺',
  'message-question-outline': '󱜻',
  'message-reply-outline': '󱜽',
  'message-reply-text-outline': '󱜾',
  'movie-check': '󱛳',
  'movie-check-outline': '󱛴',
  'movie-cog': '󱛵',
  'movie-cog-outline': '󱛶',
  'movie-minus': '󱛷',
  'movie-minus-outline': '󱛸',
  'movie-off': '󱛹',
  'movie-off-outline': '󱛺',
  'movie-open-check': '󱛻',
  'movie-open-check-outline': '󱛼',
  'movie-open-cog': '󱛽',
  'movie-open-cog-outline': '󱛾',
  'movie-open-edit': '󱛿',
  'movie-open-edit-outline': '󱜀',
  'movie-open-minus': '󱜁',
  'movie-open-minus-outline': '󱜂',
  'movie-open-off': '󱜃',
  'movie-open-off-outline': '󱜄',
  'movie-open-play': '󱜅',
  'movie-open-play-outline': '󱜆',
  'movie-open-plus': '󱜇',
  'movie-open-plus-outline': '󱜈',
  'movie-open-remove': '󱜉',
  'movie-open-remove-outline': '󱜊',
  'movie-open-settings': '󱜋',
  'movie-open-settings-outline': '󱜌',
  'movie-open-star': '󱜍',
  'movie-open-star-outline': '󱜎',
  'movie-play': '󱜏',
  'movie-play-outline': '󱜐',
  'movie-plus': '󱜑',
  'movie-plus-outline': '󱜒',
  'movie-remove': '󱜓',
  'movie-remove-outline': '󱜔',
  'movie-settings': '󱜕',
  'movie-settings-outline': '󱜖',
  'movie-star': '󱜗',
  'movie-star-outline': '󱜘',
  'projector-screen-outline': '󱜤',
  'robot-happy': '󱜙',
  'robot-happy-outline': '󱜚',
  'script-text-key': '󱜥',
  'script-text-key-outline': '󱜦',
  'script-text-play': '󱜧',
  'script-text-play-outline': '󱜨',
  'star-shooting': '󱝁',
  'star-shooting-outline': '󱝂',
  'surround-sound-2-1': '󱜩',
  'surround-sound-5-1-2': '󱜪',
  'table-picnic': '󱝃',
  'tag-arrow-down': '󱜫',
  'tag-arrow-down-outline': '󱜬',
  'tag-arrow-left': '󱜭',
  'tag-arrow-left-outline': '󱜮',
  'tag-arrow-right': '󱜯',
  'tag-arrow-right-outline': '󱜰',
  'tag-arrow-up': '󱜱',
  'tag-arrow-up-outline': '󱜲',
  'train-car-passenger': '󱜳',
  'train-car-passenger-door': '󱜴',
  'train-car-passenger-door-open': '󱜵',
  'train-car-passenger-variant': '󱜶',
  turkey: '󱜛',
  'webcam-off': '󱜷',
  'ab-testing': '󰇉',
  'abjad-arabic': '󱌨',
  'abjad-hebrew': '󱌩',
  'abugida-devanagari': '󱌪',
  'abugida-thai': '󱌫',
  'access-point': '󰀃',
  'access-point-check': '󱔸',
  'access-point-minus': '󱔹',
  'access-point-network': '󰀂',
  'access-point-network-off': '󰯡',
  'access-point-off': '󱔑',
  'access-point-plus': '󱔺',
  'access-point-remove': '󱔻',
  account: '󰀄',
  'account-alert': '󰀅',
  'account-alert-outline': '󰭐',
  'account-arrow-left': '󰭑',
  'account-arrow-left-outline': '󰭒',
  'account-arrow-right': '󰭓',
  'account-arrow-right-outline': '󰭔',
  'account-box': '󰀆',
  'account-box-multiple': '󰤴',
  'account-box-multiple-outline': '󱀊',
  'account-box-outline': '󰀇',
  'account-cancel': '󱋟',
  'account-cancel-outline': '󱋠',
  'account-cash': '󱂗',
  'account-cash-outline': '󱂘',
  'account-check': '󰀈',
  'account-check-outline': '󰯢',
  'account-child': '󰪉',
  'account-child-circle': '󰪊',
  'account-child-outline': '󱃈',
  'account-circle': '󰀉',
  'account-circle-outline': '󰭕',
  'account-clock': '󰭖',
  'account-clock-outline': '󰭗',
  'account-cog': '󱍰',
  'account-cog-outline': '󱍱',
  'account-convert': '󰀊',
  'account-convert-outline': '󱌁',
  'account-cowboy-hat': '󰺛',
  'account-details': '󰘱',
  'account-details-outline': '󱍲',
  'account-edit': '󰚼',
  'account-edit-outline': '󰿻',
  'account-group': '󰡉',
  'account-group-outline': '󰭘',
  'account-hard-hat': '󰖵',
  'account-heart': '󰢙',
  'account-heart-outline': '󰯣',
  'account-key': '󰀋',
  'account-key-outline': '󰯤',
  'account-lock': '󱅞',
  'account-lock-outline': '󱅟',
  'account-minus': '󰀍',
  'account-minus-outline': '󰫬',
  'account-multiple': '󰀎',
  'account-multiple-check': '󰣅',
  'account-multiple-check-outline': '󱇾',
  'account-multiple-minus': '󰗓',
  'account-multiple-minus-outline': '󰯥',
  'account-multiple-outline': '󰀏',
  'account-multiple-plus': '󰀐',
  'account-multiple-plus-outline': '󰠀',
  'account-multiple-remove': '󱈊',
  'account-multiple-remove-outline': '󱈋',
  'account-music': '󰠃',
  'account-music-outline': '󰳩',
  'account-network': '󰀑',
  'account-network-outline': '󰯦',
  'account-off': '󰀒',
  'account-off-outline': '󰯧',
  'account-outline': '󰀓',
  'account-plus': '󰀔',
  'account-plus-outline': '󰠁',
  'account-question': '󰭙',
  'account-question-outline': '󰭚',
  'account-reactivate': '󱔫',
  'account-reactivate-outline': '󱔬',
  'account-remove': '󰀕',
  'account-remove-outline': '󰫭',
  'account-search': '󰀖',
  'account-search-outline': '󰤵',
  'account-settings': '󰘰',
  'account-settings-outline': '󱃉',
  'account-star': '󰀗',
  'account-star-outline': '󰯨',
  'account-supervisor': '󰪋',
  'account-supervisor-circle': '󰪌',
  'account-supervisor-circle-outline': '󱓬',
  'account-supervisor-outline': '󱄭',
  'account-switch': '󰀙',
  'account-switch-outline': '󰓋',
  'account-tie': '󰳣',
  'account-tie-outline': '󱃊',
  'account-tie-voice': '󱌈',
  'account-tie-voice-off': '󱌊',
  'account-tie-voice-off-outline': '󱌋',
  'account-tie-voice-outline': '󱌉',
  'account-voice': '󰗋',
  adjust: '󰀚',
  adobe: '󰤶',
  'adobe-acrobat': '󰾝',
  'air-conditioner': '󰀛',
  'air-filter': '󰵃',
  'air-horn': '󰶬',
  'air-humidifier': '󱂙',
  'air-humidifier-off': '󱑦',
  'air-purifier': '󰵄',
  airbag: '󰯩',
  airballoon: '󰀜',
  'airballoon-outline': '󱀋',
  airplane: '󰀝',
  'airplane-landing': '󰗔',
  'airplane-off': '󰀞',
  'airplane-takeoff': '󰗕',
  airport: '󰡋',
  alarm: '󰀠',
  'alarm-bell': '󰞎',
  'alarm-check': '󰀡',
  'alarm-light': '󰞏',
  'alarm-light-outline': '󰯪',
  'alarm-multiple': '󰀢',
  'alarm-note': '󰹱',
  'alarm-note-off': '󰹲',
  'alarm-off': '󰀣',
  'alarm-panel': '󱗄',
  'alarm-panel-outline': '󱗅',
  'alarm-plus': '󰀤',
  'alarm-snooze': '󰚎',
  album: '󰀥',
  alert: '󰀦',
  'alert-box': '󰀧',
  'alert-box-outline': '󰳤',
  'alert-circle': '󰀨',
  'alert-circle-check': '󱇭',
  'alert-circle-check-outline': '󱇮',
  'alert-circle-outline': '󰗖',
  'alert-decagram': '󰚽',
  'alert-decagram-outline': '󰳥',
  'alert-minus': '󱒻',
  'alert-minus-outline': '󱒾',
  'alert-octagon': '󰀩',
  'alert-octagon-outline': '󰳦',
  'alert-octagram': '󰝧',
  'alert-octagram-outline': '󰳧',
  'alert-outline': '󰀪',
  'alert-plus': '󱒺',
  'alert-plus-outline': '󱒽',
  'alert-remove': '󱒼',
  'alert-remove-outline': '󱒿',
  'alert-rhombus': '󱇎',
  'alert-rhombus-outline': '󱇏',
  alien: '󰢚',
  'alien-outline': '󱃋',
  'align-horizontal-center': '󱇃',
  'align-horizontal-left': '󱇂',
  'align-horizontal-right': '󱇄',
  'align-vertical-bottom': '󱇅',
  'align-vertical-center': '󱇆',
  'align-vertical-top': '󱇇',
  'all-inclusive': '󰚾',
  allergy: '󱉘',
  alpha: '󰀫',
  'alpha-a': '󰫮',
  'alpha-a-box': '󰬈',
  'alpha-a-box-outline': '󰯫',
  'alpha-a-circle': '󰯬',
  'alpha-a-circle-outline': '󰯭',
  'alpha-b': '󰫯',
  'alpha-b-box': '󰬉',
  'alpha-b-box-outline': '󰯮',
  'alpha-b-circle': '󰯯',
  'alpha-b-circle-outline': '󰯰',
  'alpha-c': '󰫰',
  'alpha-c-box': '󰬊',
  'alpha-c-box-outline': '󰯱',
  'alpha-c-circle': '󰯲',
  'alpha-c-circle-outline': '󰯳',
  'alpha-d': '󰫱',
  'alpha-d-box': '󰬋',
  'alpha-d-box-outline': '󰯴',
  'alpha-d-circle': '󰯵',
  'alpha-d-circle-outline': '󰯶',
  'alpha-e': '󰫲',
  'alpha-e-box': '󰬌',
  'alpha-e-box-outline': '󰯷',
  'alpha-e-circle': '󰯸',
  'alpha-e-circle-outline': '󰯹',
  'alpha-f': '󰫳',
  'alpha-f-box': '󰬍',
  'alpha-f-box-outline': '󰯺',
  'alpha-f-circle': '󰯻',
  'alpha-f-circle-outline': '󰯼',
  'alpha-g': '󰫴',
  'alpha-g-box': '󰬎',
  'alpha-g-box-outline': '󰯽',
  'alpha-g-circle': '󰯾',
  'alpha-g-circle-outline': '󰯿',
  'alpha-h': '󰫵',
  'alpha-h-box': '󰬏',
  'alpha-h-box-outline': '󰰀',
  'alpha-h-circle': '󰰁',
  'alpha-h-circle-outline': '󰰂',
  'alpha-i': '󰫶',
  'alpha-i-box': '󰬐',
  'alpha-i-box-outline': '󰰃',
  'alpha-i-circle': '󰰄',
  'alpha-i-circle-outline': '󰰅',
  'alpha-j': '󰫷',
  'alpha-j-box': '󰬑',
  'alpha-j-box-outline': '󰰆',
  'alpha-j-circle': '󰰇',
  'alpha-j-circle-outline': '󰰈',
  'alpha-k': '󰫸',
  'alpha-k-box': '󰬒',
  'alpha-k-box-outline': '󰰉',
  'alpha-k-circle': '󰰊',
  'alpha-k-circle-outline': '󰰋',
  'alpha-l': '󰫹',
  'alpha-l-box': '󰬓',
  'alpha-l-box-outline': '󰰌',
  'alpha-l-circle': '󰰍',
  'alpha-l-circle-outline': '󰰎',
  'alpha-m': '󰫺',
  'alpha-m-box': '󰬔',
  'alpha-m-box-outline': '󰰏',
  'alpha-m-circle': '󰰐',
  'alpha-m-circle-outline': '󰰑',
  'alpha-n': '󰫻',
  'alpha-n-box': '󰬕',
  'alpha-n-box-outline': '󰰒',
  'alpha-n-circle': '󰰓',
  'alpha-n-circle-outline': '󰰔',
  'alpha-o': '󰫼',
  'alpha-o-box': '󰬖',
  'alpha-o-box-outline': '󰰕',
  'alpha-o-circle': '󰰖',
  'alpha-o-circle-outline': '󰰗',
  'alpha-p': '󰫽',
  'alpha-p-box': '󰬗',
  'alpha-p-box-outline': '󰰘',
  'alpha-p-circle': '󰰙',
  'alpha-p-circle-outline': '󰰚',
  'alpha-q': '󰫾',
  'alpha-q-box': '󰬘',
  'alpha-q-box-outline': '󰰛',
  'alpha-q-circle': '󰰜',
  'alpha-q-circle-outline': '󰰝',
  'alpha-r': '󰫿',
  'alpha-r-box': '󰬙',
  'alpha-r-box-outline': '󰰞',
  'alpha-r-circle': '󰰟',
  'alpha-r-circle-outline': '󰰠',
  'alpha-s': '󰬀',
  'alpha-s-box': '󰬚',
  'alpha-s-box-outline': '󰰡',
  'alpha-s-circle': '󰰢',
  'alpha-s-circle-outline': '󰰣',
  'alpha-t': '󰬁',
  'alpha-t-box': '󰬛',
  'alpha-t-box-outline': '󰰤',
  'alpha-t-circle': '󰰥',
  'alpha-t-circle-outline': '󰰦',
  'alpha-u': '󰬂',
  'alpha-u-box': '󰬜',
  'alpha-u-box-outline': '󰰧',
  'alpha-u-circle': '󰰨',
  'alpha-u-circle-outline': '󰰩',
  'alpha-v': '󰬃',
  'alpha-v-box': '󰬝',
  'alpha-v-box-outline': '󰰪',
  'alpha-v-circle': '󰰫',
  'alpha-v-circle-outline': '󰰬',
  'alpha-w': '󰬄',
  'alpha-w-box': '󰬞',
  'alpha-w-box-outline': '󰰭',
  'alpha-w-circle': '󰰮',
  'alpha-w-circle-outline': '󰰯',
  'alpha-x': '󰬅',
  'alpha-x-box': '󰬟',
  'alpha-x-box-outline': '󰰰',
  'alpha-x-circle': '󰰱',
  'alpha-x-circle-outline': '󰰲',
  'alpha-y': '󰬆',
  'alpha-y-box': '󰬠',
  'alpha-y-box-outline': '󰰳',
  'alpha-y-circle': '󰰴',
  'alpha-y-circle-outline': '󰰵',
  'alpha-z': '󰬇',
  'alpha-z-box': '󰬡',
  'alpha-z-box-outline': '󰰶',
  'alpha-z-circle': '󰰷',
  'alpha-z-circle-outline': '󰰸',
  'alphabet-aurebesh': '󱌬',
  'alphabet-cyrillic': '󱌭',
  'alphabet-greek': '󱌮',
  'alphabet-latin': '󱌯',
  'alphabet-piqad': '󱌰',
  'alphabet-tengwar': '󱌷',
  alphabetical: '󰀬',
  'alphabetical-off': '󱀌',
  'alphabetical-variant': '󱀍',
  'alphabetical-variant-off': '󱀎',
  altimeter: '󰗗',
  amazon: '󰀭',
  'amazon-alexa': '󰣆',
  ambulance: '󰀯',
  ammunition: '󰳨',
  ampersand: '󰪍',
  amplifier: '󰀰',
  'amplifier-off': '󱆵',
  anchor: '󰀱',
  android: '󰀲',
  'android-auto': '󰪎',
  'android-debug-bridge': '󰀳',
  'android-messages': '󰵅',
  'android-studio': '󰀴',
  'angle-acute': '󰤷',
  'angle-obtuse': '󰤸',
  'angle-right': '󰤹',
  angular: '󰚲',
  angularjs: '󰚿',
  animation: '󰗘',
  'animation-outline': '󰪏',
  'animation-play': '󰤺',
  'animation-play-outline': '󰪐',
  ansible: '󱂚',
  antenna: '󱄙',
  anvil: '󰢛',
  'apache-kafka': '󱀏',
  api: '󱂛',
  'api-off': '󱉗',
  apple: '󰀵',
  'apple-airplay': '󰀟',
  'apple-finder': '󰀶',
  'apple-icloud': '󰀸',
  'apple-ios': '󰀷',
  'apple-keyboard-caps': '󰘲',
  'apple-keyboard-command': '󰘳',
  'apple-keyboard-control': '󰘴',
  'apple-keyboard-option': '󰘵',
  'apple-keyboard-shift': '󰘶',
  'apple-safari': '󰀹',
  application: '󰘔',
  'application-cog': '󱕷',
  'application-export': '󰶭',
  'application-import': '󰶮',
  'application-settings': '󱕕',
  'approximately-equal': '󰾞',
  'approximately-equal-box': '󰾟',
  apps: '󰀻',
  'apps-box': '󰵆',
  arch: '󰣇',
  archive: '󰀼',
  'archive-alert': '󱓽',
  'archive-alert-outline': '󱓾',
  'archive-arrow-down': '󱉙',
  'archive-arrow-down-outline': '󱉚',
  'archive-arrow-up': '󱉛',
  'archive-arrow-up-outline': '󱉜',
  'archive-outline': '󱈎',
  'arm-flex': '󰿗',
  'arm-flex-outline': '󰿖',
  'arrange-bring-forward': '󰀽',
  'arrange-bring-to-front': '󰀾',
  'arrange-send-backward': '󰀿',
  'arrange-send-to-back': '󰁀',
  'arrow-all': '󰁁',
  'arrow-bottom-left': '󰁂',
  'arrow-bottom-left-bold-outline': '󰦷',
  'arrow-bottom-left-thick': '󰦸',
  'arrow-bottom-left-thin-circle-outline': '󱖖',
  'arrow-bottom-right': '󰁃',
  'arrow-bottom-right-bold-outline': '󰦹',
  'arrow-bottom-right-thick': '󰦺',
  'arrow-bottom-right-thin-circle-outline': '󱖕',
  'arrow-collapse': '󰘕',
  'arrow-collapse-all': '󰁄',
  'arrow-collapse-down': '󰞒',
  'arrow-collapse-horizontal': '󰡌',
  'arrow-collapse-left': '󰞓',
  'arrow-collapse-right': '󰞔',
  'arrow-collapse-up': '󰞕',
  'arrow-collapse-vertical': '󰡍',
  'arrow-decision': '󰦻',
  'arrow-decision-auto': '󰦼',
  'arrow-decision-auto-outline': '󰦽',
  'arrow-decision-outline': '󰦾',
  'arrow-down': '󰁅',
  'arrow-down-bold': '󰜮',
  'arrow-down-bold-box': '󰜯',
  'arrow-down-bold-box-outline': '󰜰',
  'arrow-down-bold-circle': '󰁇',
  'arrow-down-bold-circle-outline': '󰁈',
  'arrow-down-bold-hexagon-outline': '󰁉',
  'arrow-down-bold-outline': '󰦿',
  'arrow-down-box': '󰛀',
  'arrow-down-circle': '󰳛',
  'arrow-down-circle-outline': '󰳜',
  'arrow-down-drop-circle': '󰁊',
  'arrow-down-drop-circle-outline': '󰁋',
  'arrow-down-thick': '󰁆',
  'arrow-down-thin-circle-outline': '󱖙',
  'arrow-expand': '󰘖',
  'arrow-expand-all': '󰁌',
  'arrow-expand-down': '󰞖',
  'arrow-expand-horizontal': '󰡎',
  'arrow-expand-left': '󰞗',
  'arrow-expand-right': '󰞘',
  'arrow-expand-up': '󰞙',
  'arrow-expand-vertical': '󰡏',
  'arrow-horizontal-lock': '󱅛',
  'arrow-left': '󰁍',
  'arrow-left-bold': '󰜱',
  'arrow-left-bold-box': '󰜲',
  'arrow-left-bold-box-outline': '󰜳',
  'arrow-left-bold-circle': '󰁏',
  'arrow-left-bold-circle-outline': '󰁐',
  'arrow-left-bold-hexagon-outline': '󰁑',
  'arrow-left-bold-outline': '󰧀',
  'arrow-left-box': '󰛁',
  'arrow-left-circle': '󰳝',
  'arrow-left-circle-outline': '󰳞',
  'arrow-left-drop-circle': '󰁒',
  'arrow-left-drop-circle-outline': '󰁓',
  'arrow-left-right': '󰹳',
  'arrow-left-right-bold': '󰹴',
  'arrow-left-right-bold-outline': '󰧁',
  'arrow-left-thick': '󰁎',
  'arrow-left-thin-circle-outline': '󱖚',
  'arrow-right': '󰁔',
  'arrow-right-bold': '󰜴',
  'arrow-right-bold-box': '󰜵',
  'arrow-right-bold-box-outline': '󰜶',
  'arrow-right-bold-circle': '󰁖',
  'arrow-right-bold-circle-outline': '󰁗',
  'arrow-right-bold-hexagon-outline': '󰁘',
  'arrow-right-bold-outline': '󰧂',
  'arrow-right-box': '󰛂',
  'arrow-right-circle': '󰳟',
  'arrow-right-circle-outline': '󰳠',
  'arrow-right-drop-circle': '󰁙',
  'arrow-right-drop-circle-outline': '󰁚',
  'arrow-right-thick': '󰁕',
  'arrow-right-thin-circle-outline': '󱖘',
  'arrow-split-horizontal': '󰤻',
  'arrow-split-vertical': '󰤼',
  'arrow-top-left': '󰁛',
  'arrow-top-left-bold-outline': '󰧃',
  'arrow-top-left-bottom-right': '󰹵',
  'arrow-top-left-bottom-right-bold': '󰹶',
  'arrow-top-left-thick': '󰧄',
  'arrow-top-left-thin-circle-outline': '󱖓',
  'arrow-top-right': '󰁜',
  'arrow-top-right-bold-outline': '󰧅',
  'arrow-top-right-bottom-left': '󰹷',
  'arrow-top-right-bottom-left-bold': '󰹸',
  'arrow-top-right-thick': '󰧆',
  'arrow-top-right-thin-circle-outline': '󱖔',
  'arrow-up': '󰁝',
  'arrow-up-bold': '󰜷',
  'arrow-up-bold-box': '󰜸',
  'arrow-up-bold-box-outline': '󰜹',
  'arrow-up-bold-circle': '󰁟',
  'arrow-up-bold-circle-outline': '󰁠',
  'arrow-up-bold-hexagon-outline': '󰁡',
  'arrow-up-bold-outline': '󰧇',
  'arrow-up-box': '󰛃',
  'arrow-up-circle': '󰳡',
  'arrow-up-circle-outline': '󰳢',
  'arrow-up-down': '󰹹',
  'arrow-up-down-bold': '󰹺',
  'arrow-up-down-bold-outline': '󰧈',
  'arrow-up-drop-circle': '󰁢',
  'arrow-up-drop-circle-outline': '󰁣',
  'arrow-up-thick': '󰁞',
  'arrow-up-thin-circle-outline': '󱖗',
  'arrow-vertical-lock': '󱅜',
  artstation: '󰭛',
  'aspect-ratio': '󰨤',
  assistant: '󰁤',
  asterisk: '󰛄',
  at: '󰁥',
  atlassian: '󰠄',
  atm: '󰵇',
  atom: '󰝨',
  'atom-variant': '󰹻',
  attachment: '󰁦',
  'audio-video': '󰤽',
  'audio-video-off': '󱆶',
  'augmented-reality': '󰡐',
  'auto-download': '󱍾',
  'auto-fix': '󰁨',
  'auto-upload': '󰁩',
  autorenew: '󰁪',
  'av-timer': '󰁫',
  aws: '󰸏',
  axe: '󰣈',
  axis: '󰵈',
  'axis-arrow': '󰵉',
  'axis-arrow-info': '󱐎',
  'axis-arrow-lock': '󰵊',
  'axis-lock': '󰵋',
  'axis-x-arrow': '󰵌',
  'axis-x-arrow-lock': '󰵍',
  'axis-x-rotate-clockwise': '󰵎',
  'axis-x-rotate-counterclockwise': '󰵏',
  'axis-x-y-arrow-lock': '󰵐',
  'axis-y-arrow': '󰵑',
  'axis-y-arrow-lock': '󰵒',
  'axis-y-rotate-clockwise': '󰵓',
  'axis-y-rotate-counterclockwise': '󰵔',
  'axis-z-arrow': '󰵕',
  'axis-z-arrow-lock': '󰵖',
  'axis-z-rotate-clockwise': '󰵗',
  'axis-z-rotate-counterclockwise': '󰵘',
  babel: '󰨥',
  baby: '󰁬',
  'baby-bottle': '󰼹',
  'baby-bottle-outline': '󰼺',
  'baby-buggy': '󱏠',
  'baby-carriage': '󰚏',
  'baby-carriage-off': '󰾠',
  'baby-face': '󰹼',
  'baby-face-outline': '󰹽',
  backburger: '󰁭',
  backspace: '󰁮',
  'backspace-outline': '󰭜',
  'backspace-reverse': '󰹾',
  'backspace-reverse-outline': '󰹿',
  'backup-restore': '󰁯',
  bacteria: '󰻕',
  'bacteria-outline': '󰻖',
  'badge-account': '󰶧',
  'badge-account-alert': '󰶨',
  'badge-account-alert-outline': '󰶩',
  'badge-account-horizontal': '󰸍',
  'badge-account-horizontal-outline': '󰸎',
  'badge-account-outline': '󰶪',
  badminton: '󰡑',
  'bag-carry-on': '󰼻',
  'bag-carry-on-check': '󰵥',
  'bag-carry-on-off': '󰼼',
  'bag-checked': '󰼽',
  'bag-personal': '󰸐',
  'bag-personal-off': '󰸑',
  'bag-personal-off-outline': '󰸒',
  'bag-personal-outline': '󰸓',
  'bag-suitcase': '󱖋',
  'bag-suitcase-off': '󱖍',
  'bag-suitcase-off-outline': '󱖎',
  'bag-suitcase-outline': '󱖌',
  baguette: '󰼾',
  balloon: '󰨦',
  ballot: '󰧉',
  'ballot-outline': '󰧊',
  'ballot-recount': '󰰹',
  'ballot-recount-outline': '󰰺',
  bandage: '󰶯',
  bandcamp: '󰙵',
  bank: '󰁰',
  'bank-check': '󱙕',
  'bank-minus': '󰶰',
  'bank-off': '󱙖',
  'bank-off-outline': '󱙗',
  'bank-outline': '󰺀',
  'bank-plus': '󰶱',
  'bank-remove': '󰶲',
  'bank-transfer': '󰨧',
  'bank-transfer-in': '󰨨',
  'bank-transfer-out': '󰨩',
  barcode: '󰁱',
  'barcode-off': '󱈶',
  'barcode-scan': '󰁲',
  barley: '󰁳',
  'barley-off': '󰭝',
  barn: '󰭞',
  barrel: '󰁴',
  baseball: '󰡒',
  'baseball-bat': '󰡓',
  'baseball-diamond': '󱗬',
  'baseball-diamond-outline': '󱗭',
  bash: '󱆃',
  basket: '󰁶',
  'basket-fill': '󰁷',
  'basket-minus': '󱔣',
  'basket-minus-outline': '󱔤',
  'basket-off': '󱔥',
  'basket-off-outline': '󱔦',
  'basket-outline': '󱆁',
  'basket-plus': '󱔧',
  'basket-plus-outline': '󱔨',
  'basket-remove': '󱔩',
  'basket-remove-outline': '󱔪',
  'basket-unfill': '󰁸',
  basketball: '󰠆',
  'basketball-hoop': '󰰻',
  'basketball-hoop-outline': '󰰼',
  bat: '󰭟',
  battery: '󰁹',
  'battery-10': '󰁺',
  'battery-10-bluetooth': '󰤾',
  'battery-20': '󰁻',
  'battery-20-bluetooth': '󰤿',
  'battery-30': '󰁼',
  'battery-30-bluetooth': '󰥀',
  'battery-40': '󰁽',
  'battery-40-bluetooth': '󰥁',
  'battery-50': '󰁾',
  'battery-50-bluetooth': '󰥂',
  'battery-60': '󰁿',
  'battery-60-bluetooth': '󰥃',
  'battery-70': '󰂀',
  'battery-70-bluetooth': '󰥄',
  'battery-80': '󰂁',
  'battery-80-bluetooth': '󰥅',
  'battery-90': '󰂂',
  'battery-90-bluetooth': '󰥆',
  'battery-alert': '󰂃',
  'battery-alert-bluetooth': '󰥇',
  'battery-alert-variant': '󱃌',
  'battery-alert-variant-outline': '󱃍',
  'battery-bluetooth': '󰥈',
  'battery-bluetooth-variant': '󰥉',
  'battery-charging': '󰂄',
  'battery-charging-10': '󰢜',
  'battery-charging-100': '󰂅',
  'battery-charging-20': '󰂆',
  'battery-charging-30': '󰂇',
  'battery-charging-40': '󰂈',
  'battery-charging-50': '󰢝',
  'battery-charging-60': '󰂉',
  'battery-charging-70': '󰢞',
  'battery-charging-80': '󰂊',
  'battery-charging-90': '󰂋',
  'battery-charging-high': '󱊦',
  'battery-charging-low': '󱊤',
  'battery-charging-medium': '󱊥',
  'battery-charging-outline': '󰢟',
  'battery-charging-wireless': '󰠇',
  'battery-charging-wireless-10': '󰠈',
  'battery-charging-wireless-20': '󰠉',
  'battery-charging-wireless-30': '󰠊',
  'battery-charging-wireless-40': '󰠋',
  'battery-charging-wireless-50': '󰠌',
  'battery-charging-wireless-60': '󰠍',
  'battery-charging-wireless-70': '󰠎',
  'battery-charging-wireless-80': '󰠏',
  'battery-charging-wireless-90': '󰠐',
  'battery-charging-wireless-alert': '󰠑',
  'battery-charging-wireless-outline': '󰠒',
  'battery-heart': '󱈏',
  'battery-heart-outline': '󱈐',
  'battery-heart-variant': '󱈑',
  'battery-high': '󱊣',
  'battery-low': '󱊡',
  'battery-medium': '󱊢',
  'battery-minus': '󰂌',
  'battery-negative': '󰂍',
  'battery-off': '󱉝',
  'battery-off-outline': '󱉞',
  'battery-outline': '󰂎',
  'battery-plus': '󰂏',
  'battery-positive': '󰂐',
  'battery-unknown': '󰂑',
  'battery-unknown-bluetooth': '󰥊',
  battlenet: '󰭠',
  beach: '󰂒',
  beaker: '󰳪',
  'beaker-alert': '󱈩',
  'beaker-alert-outline': '󱈪',
  'beaker-check': '󱈫',
  'beaker-check-outline': '󱈬',
  'beaker-minus': '󱈭',
  'beaker-minus-outline': '󱈮',
  'beaker-outline': '󰚐',
  'beaker-plus': '󱈯',
  'beaker-plus-outline': '󱈰',
  'beaker-question': '󱈱',
  'beaker-question-outline': '󱈲',
  'beaker-remove': '󱈳',
  'beaker-remove-outline': '󱈴',
  bed: '󰋣',
  'bed-double': '󰿔',
  'bed-double-outline': '󰿓',
  'bed-empty': '󰢠',
  'bed-king': '󰿒',
  'bed-king-outline': '󰿑',
  'bed-outline': '󰂙',
  'bed-queen': '󰿐',
  'bed-queen-outline': '󰿛',
  'bed-single': '󱁭',
  'bed-single-outline': '󱁮',
  bee: '󰾡',
  'bee-flower': '󰾢',
  'beehive-off-outline': '󱏭',
  'beehive-outline': '󱃎',
  beekeeper: '󱓢',
  beer: '󰂘',
  'beer-outline': '󱌌',
  bell: '󰂚',
  'bell-alert': '󰵙',
  'bell-alert-outline': '󰺁',
  'bell-cancel': '󱏧',
  'bell-cancel-outline': '󱏨',
  'bell-check': '󱇥',
  'bell-check-outline': '󱇦',
  'bell-circle': '󰵚',
  'bell-circle-outline': '󰵛',
  'bell-minus': '󱏩',
  'bell-minus-outline': '󱏪',
  'bell-off': '󰂛',
  'bell-off-outline': '󰪑',
  'bell-outline': '󰂜',
  'bell-plus': '󰂝',
  'bell-plus-outline': '󰪒',
  'bell-remove': '󱏫',
  'bell-remove-outline': '󱏬',
  'bell-ring': '󰂞',
  'bell-ring-outline': '󰂟',
  'bell-sleep': '󰂠',
  'bell-sleep-outline': '󰪓',
  beta: '󰂡',
  betamax: '󰧋',
  biathlon: '󰸔',
  bicycle: '󱂜',
  'bicycle-basket': '󱈵',
  'bicycle-electric': '󱖴',
  'bicycle-penny-farthing': '󱗩',
  bike: '󰂣',
  'bike-fast': '󱄟',
  billboard: '󱀐',
  billiards: '󰭡',
  'billiards-rack': '󰭢',
  binoculars: '󰂥',
  bio: '󰂦',
  biohazard: '󰂧',
  bird: '󱗆',
  bitbucket: '󰂨',
  bitcoin: '󰠓',
  'black-mesa': '󰂩',
  blender: '󰳫',
  'blender-software': '󰂫',
  blinds: '󰂬',
  'blinds-open': '󱀑',
  'block-helper': '󰂭',
  blogger: '󰂮',
  'blood-bag': '󰳬',
  bluetooth: '󰂯',
  'bluetooth-audio': '󰂰',
  'bluetooth-connect': '󰂱',
  'bluetooth-off': '󰂲',
  'bluetooth-settings': '󰂳',
  'bluetooth-transfer': '󰂴',
  blur: '󰂵',
  'blur-linear': '󰂶',
  'blur-off': '󰂷',
  'blur-radial': '󰂸',
  'bolnisi-cross': '󰳭',
  bolt: '󰶳',
  bomb: '󰚑',
  'bomb-off': '󰛅',
  bone: '󰂹',
  book: '󰂺',
  'book-account': '󱎭',
  'book-account-outline': '󱎮',
  'book-alert': '󱙼',
  'book-alert-outline': '󱙽',
  'book-alphabet': '󰘝',
  'book-arrow-down': '󱙾',
  'book-arrow-down-outline': '󱙿',
  'book-arrow-left': '󱚀',
  'book-arrow-left-outline': '󱚁',
  'book-arrow-right': '󱚂',
  'book-arrow-right-outline': '󱚃',
  'book-arrow-up': '󱚄',
  'book-arrow-up-outline': '󱚅',
  'book-cancel': '󱚆',
  'book-cancel-outline': '󱚇',
  'book-check': '󱓳',
  'book-check-outline': '󱓴',
  'book-clock': '󱚈',
  'book-clock-outline': '󱚉',
  'book-cog': '󱚊',
  'book-cog-outline': '󱚋',
  'book-cross': '󰂢',
  'book-edit': '󱚌',
  'book-edit-outline': '󱚍',
  'book-education': '󱛉',
  'book-education-outline': '󱛊',
  'book-information-variant': '󱁯',
  'book-lock': '󰞚',
  'book-lock-open': '󰞛',
  'book-lock-open-outline': '󱚎',
  'book-lock-outline': '󱚏',
  'book-marker': '󱚐',
  'book-marker-outline': '󱚑',
  'book-minus': '󰗙',
  'book-minus-multiple': '󰪔',
  'book-minus-multiple-outline': '󰤋',
  'book-minus-outline': '󱚒',
  'book-multiple': '󰂻',
  'book-multiple-outline': '󰐶',
  'book-music': '󰁧',
  'book-music-outline': '󱚓',
  'book-off': '󱚔',
  'book-off-outline': '󱚕',
  'book-open': '󰂽',
  'book-open-blank-variant': '󰂾',
  'book-open-outline': '󰭣',
  'book-open-page-variant': '󰗚',
  'book-open-page-variant-outline': '󱗖',
  'book-open-variant': '󱓷',
  'book-outline': '󰭤',
  'book-play': '󰺂',
  'book-play-outline': '󰺃',
  'book-plus': '󰗛',
  'book-plus-multiple': '󰪕',
  'book-plus-multiple-outline': '󰫞',
  'book-plus-outline': '󱚖',
  'book-refresh': '󱚗',
  'book-refresh-outline': '󱚘',
  'book-remove': '󰪗',
  'book-remove-multiple': '󰪖',
  'book-remove-multiple-outline': '󰓊',
  'book-remove-outline': '󱚙',
  'book-search': '󰺄',
  'book-search-outline': '󰺅',
  'book-settings': '󱚚',
  'book-settings-outline': '󱚛',
  'book-sync': '󱚜',
  'book-sync-outline': '󱛈',
  'book-variant': '󰂿',
  'book-variant-multiple': '󰂼',
  bookmark: '󰃀',
  'bookmark-check': '󰃁',
  'bookmark-check-outline': '󱍻',
  'bookmark-minus': '󰧌',
  'bookmark-minus-outline': '󰧍',
  'bookmark-multiple': '󰸕',
  'bookmark-multiple-outline': '󰸖',
  'bookmark-music': '󰃂',
  'bookmark-music-outline': '󱍹',
  'bookmark-off': '󰧎',
  'bookmark-off-outline': '󰧏',
  'bookmark-outline': '󰃃',
  'bookmark-plus': '󰃅',
  'bookmark-plus-outline': '󰃄',
  'bookmark-remove': '󰃆',
  'bookmark-remove-outline': '󱍺',
  bookshelf: '󱉟',
  'boom-gate': '󰺆',
  'boom-gate-alert': '󰺇',
  'boom-gate-alert-outline': '󰺈',
  'boom-gate-down': '󰺉',
  'boom-gate-down-outline': '󰺊',
  'boom-gate-outline': '󰺋',
  'boom-gate-up': '󰺌',
  'boom-gate-up-outline': '󰺍',
  boombox: '󰗜',
  boomerang: '󱃏',
  bootstrap: '󰛆',
  'border-all': '󰃇',
  'border-all-variant': '󰢡',
  'border-bottom': '󰃈',
  'border-bottom-variant': '󰢢',
  'border-color': '󰃉',
  'border-horizontal': '󰃊',
  'border-inside': '󰃋',
  'border-left': '󰃌',
  'border-left-variant': '󰢣',
  'border-none': '󰃍',
  'border-none-variant': '󰢤',
  'border-outside': '󰃎',
  'border-right': '󰃏',
  'border-right-variant': '󰢥',
  'border-style': '󰃐',
  'border-top': '󰃑',
  'border-top-variant': '󰢦',
  'border-vertical': '󰃒',
  'bottle-soda': '󱁰',
  'bottle-soda-classic': '󱁱',
  'bottle-soda-classic-outline': '󱍣',
  'bottle-soda-outline': '󱁲',
  'bottle-tonic': '󱄮',
  'bottle-tonic-outline': '󱄯',
  'bottle-tonic-plus': '󱄰',
  'bottle-tonic-plus-outline': '󱄱',
  'bottle-tonic-skull': '󱄲',
  'bottle-tonic-skull-outline': '󱄳',
  'bottle-wine': '󰡔',
  'bottle-wine-outline': '󱌐',
  'bow-tie': '󰙸',
  bowl: '󰊎',
  'bowl-mix': '󰘗',
  'bowl-mix-outline': '󰋤',
  'bowl-outline': '󰊩',
  bowling: '󰃓',
  box: '󰃔',
  'box-cutter': '󰃕',
  'box-cutter-off': '󰭊',
  'box-shadow': '󰘷',
  'boxing-glove': '󰭥',
  braille: '󰧐',
  brain: '󰧑',
  'bread-slice': '󰳮',
  'bread-slice-outline': '󰳯',
  bridge: '󰘘',
  briefcase: '󰃖',
  'briefcase-account': '󰳰',
  'briefcase-account-outline': '󰳱',
  'briefcase-check': '󰃗',
  'briefcase-check-outline': '󱌞',
  'briefcase-clock': '󱃐',
  'briefcase-clock-outline': '󱃑',
  'briefcase-download': '󰃘',
  'briefcase-download-outline': '󰰽',
  'briefcase-edit': '󰪘',
  'briefcase-edit-outline': '󰰾',
  'briefcase-minus': '󰨪',
  'briefcase-minus-outline': '󰰿',
  'briefcase-off': '󱙘',
  'briefcase-off-outline': '󱙙',
  'briefcase-outline': '󰠔',
  'briefcase-plus': '󰨫',
  'briefcase-plus-outline': '󰱀',
  'briefcase-remove': '󰨬',
  'briefcase-remove-outline': '󰱁',
  'briefcase-search': '󰨭',
  'briefcase-search-outline': '󰱂',
  'briefcase-upload': '󰃙',
  'briefcase-upload-outline': '󰱃',
  'briefcase-variant': '󱒔',
  'briefcase-variant-off': '󱙚',
  'briefcase-variant-off-outline': '󱙛',
  'briefcase-variant-outline': '󱒕',
  'brightness-1': '󰃚',
  'brightness-2': '󰃛',
  'brightness-3': '󰃜',
  'brightness-4': '󰃝',
  'brightness-5': '󰃞',
  'brightness-6': '󰃟',
  'brightness-7': '󰃠',
  'brightness-auto': '󰃡',
  'brightness-percent': '󰳲',
  broom: '󰃢',
  brush: '󰃣',
  bucket: '󱐕',
  'bucket-outline': '󱐖',
  buddhism: '󰥋',
  buffer: '󰘙',
  buffet: '󰕸',
  bug: '󰃤',
  'bug-check': '󰨮',
  'bug-check-outline': '󰨯',
  'bug-outline': '󰨰',
  bugle: '󰶴',
  bulldozer: '󰬢',
  bullet: '󰳳',
  'bulletin-board': '󰃥',
  bullhorn: '󰃦',
  'bullhorn-outline': '󰬣',
  bullseye: '󰗝',
  'bullseye-arrow': '󰣉',
  bulma: '󱋧',
  'bunk-bed': '󱌂',
  'bunk-bed-outline': '󰂗',
  bus: '󰃧',
  'bus-alert': '󰪙',
  'bus-articulated-end': '󰞜',
  'bus-articulated-front': '󰞝',
  'bus-clock': '󰣊',
  'bus-double-decker': '󰞞',
  'bus-marker': '󱈒',
  'bus-multiple': '󰼿',
  'bus-school': '󰞟',
  'bus-side': '󰞠',
  'bus-stop': '󱀒',
  'bus-stop-covered': '󱀓',
  'bus-stop-uncovered': '󱀔',
  butterfly: '󱖉',
  'butterfly-outline': '󱖊',
  'cable-data': '󱎔',
  cached: '󰃨',
  cactus: '󰶵',
  cake: '󰃩',
  'cake-layered': '󰃪',
  'cake-variant': '󰃫',
  calculator: '󰃬',
  'calculator-variant': '󰪚',
  'calculator-variant-outline': '󱖦',
  calendar: '󰃭',
  'calendar-account': '󰻗',
  'calendar-account-outline': '󰻘',
  'calendar-alert': '󰨱',
  'calendar-arrow-left': '󱄴',
  'calendar-arrow-right': '󱄵',
  'calendar-blank': '󰃮',
  'calendar-blank-multiple': '󱁳',
  'calendar-blank-outline': '󰭦',
  'calendar-check': '󰃯',
  'calendar-check-outline': '󰱄',
  'calendar-clock': '󰃰',
  'calendar-cursor': '󱕻',
  'calendar-edit': '󰢧',
  'calendar-end': '󱙬',
  'calendar-export': '󰬤',
  'calendar-heart': '󰧒',
  'calendar-import': '󰬥',
  'calendar-lock': '󱙁',
  'calendar-lock-outline': '󱙂',
  'calendar-minus': '󰵜',
  'calendar-month': '󰸗',
  'calendar-month-outline': '󰸘',
  'calendar-multiple': '󰃱',
  'calendar-multiple-check': '󰃲',
  'calendar-multiselect': '󰨲',
  'calendar-outline': '󰭧',
  'calendar-plus': '󰃳',
  'calendar-question': '󰚒',
  'calendar-range': '󰙹',
  'calendar-range-outline': '󰭨',
  'calendar-refresh': '󰇡',
  'calendar-refresh-outline': '󰈃',
  'calendar-remove': '󰃴',
  'calendar-remove-outline': '󰱅',
  'calendar-search': '󰥌',
  'calendar-star': '󰧓',
  'calendar-start': '󱙭',
  'calendar-sync': '󰺎',
  'calendar-sync-outline': '󰺏',
  'calendar-text': '󰃵',
  'calendar-text-outline': '󰱆',
  'calendar-today': '󰃶',
  'calendar-week': '󰨳',
  'calendar-week-begin': '󰨴',
  'calendar-weekend': '󰻙',
  'calendar-weekend-outline': '󰻚',
  'call-made': '󰃷',
  'call-merge': '󰃸',
  'call-missed': '󰃹',
  'call-received': '󰃺',
  'call-split': '󰃻',
  camcorder: '󰃼',
  'camcorder-off': '󰃿',
  camera: '󰄀',
  'camera-account': '󰣋',
  'camera-burst': '󰚓',
  'camera-control': '󰭩',
  'camera-enhance': '󰄁',
  'camera-enhance-outline': '󰭪',
  'camera-flip': '󱗙',
  'camera-flip-outline': '󱗚',
  'camera-front': '󰄂',
  'camera-front-variant': '󰄃',
  'camera-gopro': '󰞡',
  'camera-image': '󰣌',
  'camera-iris': '󰄄',
  'camera-metering-center': '󰞢',
  'camera-metering-matrix': '󰞣',
  'camera-metering-partial': '󰞤',
  'camera-metering-spot': '󰞥',
  'camera-off': '󰗟',
  'camera-outline': '󰵝',
  'camera-party-mode': '󰄅',
  'camera-plus': '󰻛',
  'camera-plus-outline': '󰻜',
  'camera-rear': '󰄆',
  'camera-rear-variant': '󰄇',
  'camera-retake': '󰸙',
  'camera-retake-outline': '󰸚',
  'camera-switch': '󰄈',
  'camera-switch-outline': '󰡊',
  'camera-timer': '󰄉',
  'camera-wireless': '󰶶',
  'camera-wireless-outline': '󰶷',
  campfire: '󰻝',
  cancel: '󰜺',
  candle: '󰗢',
  candycane: '󰄊',
  cannabis: '󰞦',
  'cannabis-off': '󱙮',
  'caps-lock': '󰪛',
  car: '󰄋',
  'car-2-plus': '󱀕',
  'car-3-plus': '󱀖',
  'car-arrow-left': '󱎲',
  'car-arrow-right': '󱎳',
  'car-back': '󰸛',
  'car-battery': '󰄌',
  'car-brake-abs': '󰱇',
  'car-brake-alert': '󰱈',
  'car-brake-hold': '󰵞',
  'car-brake-parking': '󰵟',
  'car-brake-retarder': '󱀗',
  'car-child-seat': '󰾣',
  'car-clutch': '󱀘',
  'car-cog': '󱏌',
  'car-connected': '󰄍',
  'car-convertible': '󰞧',
  'car-coolant-level': '󱀙',
  'car-cruise-control': '󰵠',
  'car-defrost-front': '󰵡',
  'car-defrost-rear': '󰵢',
  'car-door': '󰭫',
  'car-door-lock': '󱂝',
  'car-electric': '󰭬',
  'car-electric-outline': '󱖵',
  'car-emergency': '󱘏',
  'car-esp': '󰱉',
  'car-estate': '󰞨',
  'car-hatchback': '󰞩',
  'car-info': '󱆾',
  'car-key': '󰭭',
  'car-lifted-pickup': '󱔭',
  'car-light-dimmed': '󰱊',
  'car-light-fog': '󰱋',
  'car-light-high': '󰱌',
  'car-limousine': '󰣍',
  'car-multiple': '󰭮',
  'car-off': '󰸜',
  'car-outline': '󱓭',
  'car-parking-lights': '󰵣',
  'car-pickup': '󰞪',
  'car-seat': '󰾤',
  'car-seat-cooler': '󰾥',
  'car-seat-heater': '󰾦',
  'car-settings': '󱏍',
  'car-shift-pattern': '󰽀',
  'car-side': '󰞫',
  'car-sports': '󰞬',
  'car-tire-alert': '󰱍',
  'car-traction-control': '󰵤',
  'car-turbocharger': '󱀚',
  'car-wash': '󰄎',
  'car-windshield': '󱀛',
  'car-windshield-outline': '󱀜',
  carabiner: '󱓀',
  caravan: '󰞭',
  card: '󰭯',
  'card-account-details': '󰗒',
  'card-account-details-outline': '󰶫',
  'card-account-details-star': '󰊣',
  'card-account-details-star-outline': '󰛛',
  'card-account-mail': '󰆎',
  'card-account-mail-outline': '󰺘',
  'card-account-phone': '󰺙',
  'card-account-phone-outline': '󰺚',
  'card-bulleted': '󰭰',
  'card-bulleted-off': '󰭱',
  'card-bulleted-off-outline': '󰭲',
  'card-bulleted-outline': '󰭳',
  'card-bulleted-settings': '󰭴',
  'card-bulleted-settings-outline': '󰭵',
  'card-minus': '󱘀',
  'card-minus-outline': '󱘁',
  'card-off': '󱘂',
  'card-off-outline': '󱘃',
  'card-outline': '󰭶',
  'card-plus': '󱇿',
  'card-plus-outline': '󱈀',
  'card-remove': '󱘄',
  'card-remove-outline': '󱘅',
  'card-search': '󱁴',
  'card-search-outline': '󱁵',
  'card-text': '󰭷',
  'card-text-outline': '󰭸',
  cards: '󰘸',
  'cards-club': '󰣎',
  'cards-diamond': '󰣏',
  'cards-diamond-outline': '󱀝',
  'cards-heart': '󰣐',
  'cards-outline': '󰘹',
  'cards-playing-outline': '󰘺',
  'cards-spade': '󰣑',
  'cards-variant': '󰛇',
  carrot: '󰄏',
  cart: '󰄐',
  'cart-arrow-down': '󰵦',
  'cart-arrow-right': '󰱎',
  'cart-arrow-up': '󰵧',
  'cart-check': '󱗪',
  'cart-minus': '󰵨',
  'cart-off': '󰙫',
  'cart-outline': '󰄑',
  'cart-plus': '󰄒',
  'cart-remove': '󰵩',
  'cart-variant': '󱗫',
  'case-sensitive-alt': '󰄓',
  cash: '󰄔',
  'cash-100': '󰄕',
  'cash-check': '󱓮',
  'cash-lock': '󱓪',
  'cash-lock-open': '󱓫',
  'cash-marker': '󰶸',
  'cash-minus': '󱉠',
  'cash-multiple': '󰄖',
  'cash-plus': '󱉡',
  'cash-refund': '󰪜',
  'cash-register': '󰳴',
  'cash-remove': '󱉢',
  'cash-usd': '󱅶',
  'cash-usd-outline': '󰄗',
  cassette: '󰧔',
  cast: '󰄘',
  'cast-audio': '󱀞',
  'cast-connected': '󰄙',
  'cast-education': '󰸝',
  'cast-off': '󰞊',
  castle: '󰄚',
  cat: '󰄛',
  cctv: '󰞮',
  'ceiling-light': '󰝩',
  cellphone: '󰄜',
  'cellphone-android': '󰄝',
  'cellphone-arrow-down': '󰧕',
  'cellphone-basic': '󰄞',
  'cellphone-charging': '󱎗',
  'cellphone-cog': '󰥑',
  'cellphone-dock': '󰄟',
  'cellphone-erase': '󰥍',
  'cellphone-information': '󰽁',
  'cellphone-iphone': '󰄠',
  'cellphone-key': '󰥎',
  'cellphone-link': '󰄡',
  'cellphone-link-off': '󰄢',
  'cellphone-lock': '󰥏',
  'cellphone-message': '󰣓',
  'cellphone-message-off': '󱃒',
  'cellphone-nfc': '󰺐',
  'cellphone-nfc-off': '󱋘',
  'cellphone-off': '󰥐',
  'cellphone-play': '󱀟',
  'cellphone-screenshot': '󰨵',
  'cellphone-settings': '󰄣',
  'cellphone-sound': '󰥒',
  'cellphone-text': '󰣒',
  'cellphone-wireless': '󰠕',
  'celtic-cross': '󰳵',
  centos: '󱄚',
  certificate: '󰄤',
  'certificate-outline': '󱆈',
  'chair-rolling': '󰽈',
  'chair-school': '󰄥',
  charity: '󰱏',
  'chart-arc': '󰄦',
  'chart-areaspline': '󰄧',
  'chart-areaspline-variant': '󰺑',
  'chart-bar': '󰄨',
  'chart-bar-stacked': '󰝪',
  'chart-bell-curve': '󰱐',
  'chart-bell-curve-cumulative': '󰾧',
  'chart-box': '󱕍',
  'chart-box-outline': '󱕎',
  'chart-box-plus-outline': '󱕏',
  'chart-bubble': '󰗣',
  'chart-donut': '󰞯',
  'chart-donut-variant': '󰞰',
  'chart-gantt': '󰙬',
  'chart-histogram': '󰄩',
  'chart-line': '󰄪',
  'chart-line-stacked': '󰝫',
  'chart-line-variant': '󰞱',
  'chart-multiline': '󰣔',
  'chart-multiple': '󱈓',
  'chart-pie': '󰄫',
  'chart-ppf': '󱎀',
  'chart-sankey': '󱇟',
  'chart-sankey-variant': '󱇠',
  'chart-scatter-plot': '󰺒',
  'chart-scatter-plot-hexbin': '󰙭',
  'chart-timeline': '󰙮',
  'chart-timeline-variant': '󰺓',
  'chart-timeline-variant-shimmer': '󱖶',
  'chart-tree': '󰺔',
  chat: '󰭹',
  'chat-alert': '󰭺',
  'chat-alert-outline': '󱋉',
  'chat-minus': '󱐐',
  'chat-minus-outline': '󱐓',
  'chat-outline': '󰻞',
  'chat-plus': '󱐏',
  'chat-plus-outline': '󱐒',
  'chat-processing': '󰭻',
  'chat-processing-outline': '󱋊',
  'chat-remove': '󱐑',
  'chat-remove-outline': '󱐔',
  'chat-sleep': '󱋑',
  'chat-sleep-outline': '󱋒',
  check: '󰄬',
  'check-all': '󰄭',
  'check-bold': '󰸞',
  'check-box-multiple-outline': '󰱑',
  'check-box-outline': '󰱒',
  'check-circle': '󰗠',
  'check-circle-outline': '󰗡',
  'check-decagram': '󰞑',
  'check-network': '󰱓',
  'check-network-outline': '󰱔',
  'check-outline': '󰡕',
  'check-underline': '󰸟',
  'check-underline-circle': '󰸠',
  'check-underline-circle-outline': '󰸡',
  checkbook: '󰪝',
  'checkbox-blank': '󰄮',
  'checkbox-blank-circle': '󰄯',
  'checkbox-blank-circle-outline': '󰄰',
  'checkbox-blank-off': '󱋬',
  'checkbox-blank-off-outline': '󱋭',
  'checkbox-blank-outline': '󰄱',
  'checkbox-intermediate': '󰡖',
  'checkbox-marked': '󰄲',
  'checkbox-marked-circle': '󰄳',
  'checkbox-marked-circle-outline': '󰄴',
  'checkbox-marked-outline': '󰄵',
  'checkbox-multiple-blank': '󰄶',
  'checkbox-multiple-blank-circle': '󰘻',
  'checkbox-multiple-blank-circle-outline': '󰘼',
  'checkbox-multiple-blank-outline': '󰄷',
  'checkbox-multiple-marked': '󰄸',
  'checkbox-multiple-marked-circle': '󰘽',
  'checkbox-multiple-marked-circle-outline': '󰘾',
  'checkbox-multiple-marked-outline': '󰄹',
  checkerboard: '󰄺',
  'checkerboard-minus': '󱈂',
  'checkerboard-plus': '󱈁',
  'checkerboard-remove': '󱈃',
  cheese: '󱊹',
  'cheese-off': '󱏮',
  'chef-hat': '󰭼',
  'chemical-weapon': '󰄻',
  'chess-bishop': '󰡜',
  'chess-king': '󰡗',
  'chess-knight': '󰡘',
  'chess-pawn': '󰡙',
  'chess-queen': '󰡚',
  'chess-rook': '󰡛',
  'chevron-double-down': '󰄼',
  'chevron-double-left': '󰄽',
  'chevron-double-right': '󰄾',
  'chevron-double-up': '󰄿',
  'chevron-down': '󰅀',
  'chevron-down-box': '󰧖',
  'chevron-down-box-outline': '󰧗',
  'chevron-down-circle': '󰬦',
  'chevron-down-circle-outline': '󰬧',
  'chevron-left': '󰅁',
  'chevron-left-box': '󰧘',
  'chevron-left-box-outline': '󰧙',
  'chevron-left-circle': '󰬨',
  'chevron-left-circle-outline': '󰬩',
  'chevron-right': '󰅂',
  'chevron-right-box': '󰧚',
  'chevron-right-box-outline': '󰧛',
  'chevron-right-circle': '󰬪',
  'chevron-right-circle-outline': '󰬫',
  'chevron-triple-down': '󰶹',
  'chevron-triple-left': '󰶺',
  'chevron-triple-right': '󰶻',
  'chevron-triple-up': '󰶼',
  'chevron-up': '󰅃',
  'chevron-up-box': '󰧜',
  'chevron-up-box-outline': '󰧝',
  'chevron-up-circle': '󰬬',
  'chevron-up-circle-outline': '󰬭',
  'chili-hot': '󰞲',
  'chili-medium': '󰞳',
  'chili-mild': '󰞴',
  'chili-off': '󱑧',
  chip: '󰘚',
  christianity: '󰥓',
  'christianity-outline': '󰳶',
  church: '󰅄',
  cigar: '󱆉',
  'cigar-off': '󱐛',
  circle: '󰝥',
  'circle-box': '󱗜',
  'circle-box-outline': '󱗝',
  'circle-double': '󰺕',
  'circle-edit-outline': '󰣕',
  'circle-expand': '󰺖',
  'circle-half': '󱎕',
  'circle-half-full': '󱎖',
  'circle-medium': '󰧞',
  'circle-multiple': '󰬸',
  'circle-multiple-outline': '󰚕',
  'circle-off-outline': '󱃓',
  'circle-outline': '󰝦',
  'circle-slice-1': '󰪞',
  'circle-slice-2': '󰪟',
  'circle-slice-3': '󰪠',
  'circle-slice-4': '󰪡',
  'circle-slice-5': '󰪢',
  'circle-slice-6': '󰪣',
  'circle-slice-7': '󰪤',
  'circle-slice-8': '󰪥',
  'circle-small': '󰧟',
  'circular-saw': '󰸢',
  city: '󰅆',
  'city-variant': '󰨶',
  'city-variant-outline': '󰨷',
  clipboard: '󰅇',
  'clipboard-account': '󰅈',
  'clipboard-account-outline': '󰱕',
  'clipboard-alert': '󰅉',
  'clipboard-alert-outline': '󰳷',
  'clipboard-arrow-down': '󰅊',
  'clipboard-arrow-down-outline': '󰱖',
  'clipboard-arrow-left': '󰅋',
  'clipboard-arrow-left-outline': '󰳸',
  'clipboard-arrow-right': '󰳹',
  'clipboard-arrow-right-outline': '󰳺',
  'clipboard-arrow-up': '󰱗',
  'clipboard-arrow-up-outline': '󰱘',
  'clipboard-check': '󰅎',
  'clipboard-check-multiple': '󱉣',
  'clipboard-check-multiple-outline': '󱉤',
  'clipboard-check-outline': '󰢨',
  'clipboard-edit': '󱓥',
  'clipboard-edit-outline': '󱓦',
  'clipboard-file': '󱉥',
  'clipboard-file-outline': '󱉦',
  'clipboard-flow': '󰛈',
  'clipboard-flow-outline': '󱄗',
  'clipboard-list': '󱃔',
  'clipboard-list-outline': '󱃕',
  'clipboard-minus': '󱘘',
  'clipboard-minus-outline': '󱘙',
  'clipboard-multiple': '󱉧',
  'clipboard-multiple-outline': '󱉨',
  'clipboard-off': '󱘚',
  'clipboard-off-outline': '󱘛',
  'clipboard-outline': '󰅌',
  'clipboard-play': '󰱙',
  'clipboard-play-multiple': '󱉩',
  'clipboard-play-multiple-outline': '󱉪',
  'clipboard-play-outline': '󰱚',
  'clipboard-plus': '󰝑',
  'clipboard-plus-outline': '󱌟',
  'clipboard-pulse': '󰡝',
  'clipboard-pulse-outline': '󰡞',
  'clipboard-remove': '󱘜',
  'clipboard-remove-outline': '󱘝',
  'clipboard-search': '󱘞',
  'clipboard-search-outline': '󱘟',
  'clipboard-text': '󰅍',
  'clipboard-text-multiple': '󱉫',
  'clipboard-text-multiple-outline': '󱉬',
  'clipboard-text-off': '󱘠',
  'clipboard-text-off-outline': '󱘡',
  'clipboard-text-outline': '󰨸',
  'clipboard-text-play': '󰱛',
  'clipboard-text-play-outline': '󰱜',
  'clipboard-text-search': '󱘢',
  'clipboard-text-search-outline': '󱘣',
  clippy: '󰅏',
  clock: '󰥔',
  'clock-alert': '󰥕',
  'clock-alert-outline': '󰗎',
  'clock-check': '󰾨',
  'clock-check-outline': '󰾩',
  'clock-digital': '󰺗',
  'clock-end': '󰅑',
  'clock-fast': '󰅒',
  'clock-in': '󰅓',
  'clock-out': '󰅔',
  'clock-outline': '󰅐',
  'clock-start': '󰅕',
  'clock-time-eight': '󱑆',
  'clock-time-eight-outline': '󱑒',
  'clock-time-eleven': '󱑉',
  'clock-time-eleven-outline': '󱑕',
  'clock-time-five': '󱑃',
  'clock-time-five-outline': '󱑏',
  'clock-time-four': '󱑂',
  'clock-time-four-outline': '󱑎',
  'clock-time-nine': '󱑇',
  'clock-time-nine-outline': '󱑓',
  'clock-time-one': '󱐿',
  'clock-time-one-outline': '󱑋',
  'clock-time-seven': '󱑅',
  'clock-time-seven-outline': '󱑑',
  'clock-time-six': '󱑄',
  'clock-time-six-outline': '󱑐',
  'clock-time-ten': '󱑈',
  'clock-time-ten-outline': '󱑔',
  'clock-time-three': '󱑁',
  'clock-time-three-outline': '󱑍',
  'clock-time-twelve': '󱑊',
  'clock-time-twelve-outline': '󱑖',
  'clock-time-two': '󱑀',
  'clock-time-two-outline': '󱑌',
  close: '󰅖',
  'close-box': '󰅗',
  'close-box-multiple': '󰱝',
  'close-box-multiple-outline': '󰱞',
  'close-box-outline': '󰅘',
  'close-circle': '󰅙',
  'close-circle-multiple': '󰘪',
  'close-circle-multiple-outline': '󰢃',
  'close-circle-outline': '󰅚',
  'close-network': '󰅛',
  'close-network-outline': '󰱟',
  'close-octagon': '󰅜',
  'close-octagon-outline': '󰅝',
  'close-outline': '󰛉',
  'close-thick': '󱎘',
  'closed-caption': '󰅞',
  'closed-caption-outline': '󰶽',
  cloud: '󰅟',
  'cloud-alert': '󰧠',
  'cloud-braces': '󰞵',
  'cloud-check': '󰅠',
  'cloud-check-outline': '󱋌',
  'cloud-circle': '󰅡',
  'cloud-download': '󰅢',
  'cloud-download-outline': '󰭽',
  'cloud-lock': '󱇱',
  'cloud-lock-outline': '󱇲',
  'cloud-off-outline': '󰅤',
  'cloud-outline': '󰅣',
  'cloud-print': '󰅥',
  'cloud-print-outline': '󰅦',
  'cloud-question': '󰨹',
  'cloud-refresh': '󰔪',
  'cloud-search': '󰥖',
  'cloud-search-outline': '󰥗',
  'cloud-sync': '󰘿',
  'cloud-sync-outline': '󱋖',
  'cloud-tags': '󰞶',
  'cloud-upload': '󰅧',
  'cloud-upload-outline': '󰭾',
  clover: '󰠖',
  'coach-lamp': '󱀠',
  'coat-rack': '󱂞',
  'code-array': '󰅨',
  'code-braces': '󰅩',
  'code-braces-box': '󱃖',
  'code-brackets': '󰅪',
  'code-equal': '󰅫',
  'code-greater-than': '󰅬',
  'code-greater-than-or-equal': '󰅭',
  'code-json': '󰘦',
  'code-less-than': '󰅮',
  'code-less-than-or-equal': '󰅯',
  'code-not-equal': '󰅰',
  'code-not-equal-variant': '󰅱',
  'code-parentheses': '󰅲',
  'code-parentheses-box': '󱃗',
  'code-string': '󰅳',
  'code-tags': '󰅴',
  'code-tags-check': '󰚔',
  codepen: '󰅵',
  coffee: '󰅶',
  'coffee-maker': '󱂟',
  'coffee-off': '󰾪',
  'coffee-off-outline': '󰾫',
  'coffee-outline': '󰛊',
  'coffee-to-go': '󰅷',
  'coffee-to-go-outline': '󱌎',
  coffin: '󰭿',
  cog: '󰒓',
  'cog-box': '󰒔',
  'cog-clockwise': '󱇝',
  'cog-counterclockwise': '󱇞',
  'cog-off': '󱏎',
  'cog-off-outline': '󱏏',
  'cog-outline': '󰢻',
  'cog-refresh': '󱑞',
  'cog-refresh-outline': '󱑟',
  'cog-sync': '󱑠',
  'cog-sync-outline': '󱑡',
  'cog-transfer': '󱁛',
  'cog-transfer-outline': '󱁜',
  cogs: '󰣖',
  collage: '󰙀',
  'collapse-all': '󰪦',
  'collapse-all-outline': '󰪧',
  'color-helper': '󰅹',
  comma: '󰸣',
  'comma-box': '󰸫',
  'comma-box-outline': '󰸤',
  'comma-circle': '󰸥',
  'comma-circle-outline': '󰸦',
  comment: '󰅺',
  'comment-account': '󰅻',
  'comment-account-outline': '󰅼',
  'comment-alert': '󰅽',
  'comment-alert-outline': '󰅾',
  'comment-arrow-left': '󰧡',
  'comment-arrow-left-outline': '󰧢',
  'comment-arrow-right': '󰧣',
  'comment-arrow-right-outline': '󰧤',
  'comment-bookmark': '󱖮',
  'comment-bookmark-outline': '󱖯',
  'comment-check': '󰅿',
  'comment-check-outline': '󰆀',
  'comment-edit': '󱆿',
  'comment-edit-outline': '󱋄',
  'comment-eye': '󰨺',
  'comment-eye-outline': '󰨻',
  'comment-flash': '󱖰',
  'comment-flash-outline': '󱖱',
  'comment-minus': '󱗟',
  'comment-minus-outline': '󱗠',
  'comment-multiple': '󰡟',
  'comment-multiple-outline': '󰆁',
  'comment-off': '󱗡',
  'comment-off-outline': '󱗢',
  'comment-outline': '󰆂',
  'comment-plus': '󰧥',
  'comment-plus-outline': '󰆃',
  'comment-processing': '󰆄',
  'comment-processing-outline': '󰆅',
  'comment-question': '󰠗',
  'comment-question-outline': '󰆆',
  'comment-quote': '󱀡',
  'comment-quote-outline': '󱀢',
  'comment-remove': '󰗞',
  'comment-remove-outline': '󰆇',
  'comment-search': '󰨼',
  'comment-search-outline': '󰨽',
  'comment-text': '󰆈',
  'comment-text-multiple': '󰡠',
  'comment-text-multiple-outline': '󰡡',
  'comment-text-outline': '󰆉',
  compare: '󰆊',
  'compare-horizontal': '󱒒',
  'compare-vertical': '󱒓',
  compass: '󰆋',
  'compass-off': '󰮀',
  'compass-off-outline': '󰮁',
  'compass-outline': '󰆌',
  'compass-rose': '󱎂',
  'concourse-ci': '󱂠',
  connection: '󱘖',
  console: '󰆍',
  'console-line': '󰞷',
  'console-network': '󰢩',
  'console-network-outline': '󰱠',
  consolidate: '󱃘',
  'contactless-payment': '󰵪',
  'contactless-payment-circle': '󰌡',
  'contactless-payment-circle-outline': '󰐈',
  contacts: '󰛋',
  'contacts-outline': '󰖸',
  contain: '󰨾',
  'contain-end': '󰨿',
  'contain-start': '󰩀',
  'content-copy': '󰆏',
  'content-cut': '󰆐',
  'content-duplicate': '󰆑',
  'content-paste': '󰆒',
  'content-save': '󰆓',
  'content-save-alert': '󰽂',
  'content-save-alert-outline': '󰽃',
  'content-save-all': '󰆔',
  'content-save-all-outline': '󰽄',
  'content-save-cog': '󱑛',
  'content-save-cog-outline': '󱑜',
  'content-save-edit': '󰳻',
  'content-save-edit-outline': '󰳼',
  'content-save-move': '󰸧',
  'content-save-move-outline': '󰸨',
  'content-save-off': '󱙃',
  'content-save-off-outline': '󱙄',
  'content-save-outline': '󰠘',
  'content-save-settings': '󰘛',
  'content-save-settings-outline': '󰬮',
  contrast: '󰆕',
  'contrast-box': '󰆖',
  'contrast-circle': '󰆗',
  'controller-classic': '󰮂',
  'controller-classic-outline': '󰮃',
  cookie: '󰆘',
  'cookie-alert': '󱛐',
  'cookie-alert-outline': '󱛑',
  'cookie-check': '󱛒',
  'cookie-check-outline': '󱛓',
  'cookie-cog': '󱛔',
  'cookie-cog-outline': '󱛕',
  'cookie-minus': '󱛚',
  'cookie-minus-outline': '󱛛',
  'cookie-outline': '󱛞',
  'cookie-plus': '󱛖',
  'cookie-plus-outline': '󱛗',
  'cookie-remove': '󱛘',
  'cookie-remove-outline': '󱛙',
  'cookie-settings': '󱛜',
  'cookie-settings-outline': '󱛝',
  'coolant-temperature': '󰏈',
  copyright: '󰗦',
  cordova: '󰥘',
  corn: '󰞸',
  'corn-off': '󱏯',
  'cosine-wave': '󱑹',
  counter: '󰆙',
  cow: '󰆚',
  'cpu-32-bit': '󰻟',
  'cpu-64-bit': '󰻠',
  crane: '󰡢',
  creation: '󰙴',
  'creative-commons': '󰵫',
  'credit-card': '󰿯',
  'credit-card-check': '󱏐',
  'credit-card-check-outline': '󱏑',
  'credit-card-clock': '󰻡',
  'credit-card-clock-outline': '󰻢',
  'credit-card-marker': '󰚨',
  'credit-card-marker-outline': '󰶾',
  'credit-card-minus': '󰾬',
  'credit-card-minus-outline': '󰾭',
  'credit-card-multiple': '󰿰',
  'credit-card-multiple-outline': '󰆜',
  'credit-card-off': '󰿱',
  'credit-card-off-outline': '󰗤',
  'credit-card-outline': '󰆛',
  'credit-card-plus': '󰿲',
  'credit-card-plus-outline': '󰙶',
  'credit-card-refresh': '󱙅',
  'credit-card-refresh-outline': '󱙆',
  'credit-card-refund': '󰿳',
  'credit-card-refund-outline': '󰪨',
  'credit-card-remove': '󰾮',
  'credit-card-remove-outline': '󰾯',
  'credit-card-scan': '󰿴',
  'credit-card-scan-outline': '󰆝',
  'credit-card-search': '󱙇',
  'credit-card-search-outline': '󱙈',
  'credit-card-settings': '󰿵',
  'credit-card-settings-outline': '󰣗',
  'credit-card-sync': '󱙉',
  'credit-card-sync-outline': '󱙊',
  'credit-card-wireless': '󰠂',
  'credit-card-wireless-off': '󰕺',
  'credit-card-wireless-off-outline': '󰕻',
  'credit-card-wireless-outline': '󰵬',
  cricket: '󰵭',
  crop: '󰆞',
  'crop-free': '󰆟',
  'crop-landscape': '󰆠',
  'crop-portrait': '󰆡',
  'crop-rotate': '󰚖',
  'crop-square': '󰆢',
  crosshairs: '󰆣',
  'crosshairs-gps': '󰆤',
  'crosshairs-off': '󰽅',
  'crosshairs-question': '󱄶',
  crown: '󰆥',
  'crown-outline': '󱇐',
  cryengine: '󰥙',
  'crystal-ball': '󰬯',
  cube: '󰆦',
  'cube-off': '󱐜',
  'cube-off-outline': '󱐝',
  'cube-outline': '󰆧',
  'cube-scan': '󰮄',
  'cube-send': '󰆨',
  'cube-unfolded': '󰆩',
  cup: '󰆪',
  'cup-off': '󰗥',
  'cup-off-outline': '󱍽',
  'cup-outline': '󱌏',
  'cup-water': '󰆫',
  cupboard: '󰽆',
  'cupboard-outline': '󰽇',
  cupcake: '󰥚',
  curling: '󰡣',
  'currency-bdt': '󰡤',
  'currency-brl': '󰮅',
  'currency-btc': '󰆬',
  'currency-cny': '󰞺',
  'currency-eth': '󰞻',
  'currency-eur': '󰆭',
  'currency-eur-off': '󱌕',
  'currency-gbp': '󰆮',
  'currency-ils': '󰱡',
  'currency-inr': '󰆯',
  'currency-jpy': '󰞼',
  'currency-krw': '󰞽',
  'currency-kzt': '󰡥',
  'currency-mnt': '󱔒',
  'currency-ngn': '󰆰',
  'currency-php': '󰧦',
  'currency-rial': '󰺜',
  'currency-rub': '󰆱',
  'currency-sign': '󰞾',
  'currency-try': '󰆲',
  'currency-twd': '󰞿',
  'currency-usd': '󰇁',
  'currency-usd-circle': '󱅫',
  'currency-usd-circle-outline': '󰅸',
  'currency-usd-off': '󰙺',
  'current-ac': '󱒀',
  'current-dc': '󰥜',
  'cursor-default': '󰇀',
  'cursor-default-click': '󰳽',
  'cursor-default-click-outline': '󰳾',
  'cursor-default-gesture': '󱄧',
  'cursor-default-gesture-outline': '󱄨',
  'cursor-default-outline': '󰆿',
  'cursor-move': '󰆾',
  'cursor-pointer': '󰆽',
  'cursor-text': '󰗧',
  'dance-ballroom': '󱗻',
  'dance-pole': '󱕸',
  'data-matrix': '󱔼',
  'data-matrix-edit': '󱔽',
  'data-matrix-minus': '󱔾',
  'data-matrix-plus': '󱔿',
  'data-matrix-remove': '󱕀',
  'data-matrix-scan': '󱕁',
  database: '󰆼',
  'database-alert': '󱘺',
  'database-alert-outline': '󱘤',
  'database-arrow-down': '󱘻',
  'database-arrow-down-outline': '󱘥',
  'database-arrow-left': '󱘼',
  'database-arrow-left-outline': '󱘦',
  'database-arrow-right': '󱘽',
  'database-arrow-right-outline': '󱘧',
  'database-arrow-up': '󱘾',
  'database-arrow-up-outline': '󱘨',
  'database-check': '󰪩',
  'database-check-outline': '󱘩',
  'database-clock': '󱘿',
  'database-clock-outline': '󱘪',
  'database-cog': '󱙋',
  'database-cog-outline': '󱙌',
  'database-edit': '󰮆',
  'database-edit-outline': '󱘫',
  'database-export': '󰥞',
  'database-export-outline': '󱘬',
  'database-import': '󰥝',
  'database-import-outline': '󱘭',
  'database-lock': '󰪪',
  'database-lock-outline': '󱘮',
  'database-marker': '󱋶',
  'database-marker-outline': '󱘯',
  'database-minus': '󰆻',
  'database-minus-outline': '󱘰',
  'database-off': '󱙀',
  'database-off-outline': '󱘱',
  'database-outline': '󱘲',
  'database-plus': '󰆺',
  'database-plus-outline': '󱘳',
  'database-refresh': '󰗂',
  'database-refresh-outline': '󱘴',
  'database-remove': '󰴀',
  'database-remove-outline': '󱘵',
  'database-search': '󰡦',
  'database-search-outline': '󱘶',
  'database-settings': '󰴁',
  'database-settings-outline': '󱘷',
  'database-sync': '󰳿',
  'database-sync-outline': '󱘸',
  'death-star': '󰣘',
  'death-star-variant': '󰣙',
  'deathly-hallows': '󰮇',
  debian: '󰣚',
  'debug-step-into': '󰆹',
  'debug-step-out': '󰆸',
  'debug-step-over': '󰆷',
  decagram: '󰝬',
  'decagram-outline': '󰝭',
  decimal: '󱂡',
  'decimal-comma': '󱂢',
  'decimal-comma-decrease': '󱂣',
  'decimal-comma-increase': '󱂤',
  'decimal-decrease': '󰆶',
  'decimal-increase': '󰆵',
  delete: '󰆴',
  'delete-alert': '󱂥',
  'delete-alert-outline': '󱂦',
  'delete-circle': '󰚃',
  'delete-circle-outline': '󰮈',
  'delete-clock': '󱕖',
  'delete-clock-outline': '󱕗',
  'delete-empty': '󰛌',
  'delete-empty-outline': '󰺝',
  'delete-forever': '󰗨',
  'delete-forever-outline': '󰮉',
  'delete-off': '󱂧',
  'delete-off-outline': '󱂨',
  'delete-outline': '󰧧',
  'delete-restore': '󰠙',
  'delete-sweep': '󰗩',
  'delete-sweep-outline': '󰱢',
  'delete-variant': '󰆳',
  delta: '󰇂',
  desk: '󱈹',
  'desk-lamp': '󰥟',
  deskphone: '󰇃',
  'desktop-classic': '󰟀',
  'desktop-mac': '󰇄',
  'desktop-mac-dashboard': '󰧨',
  'desktop-tower': '󰇅',
  'desktop-tower-monitor': '󰪫',
  details: '󰇆',
  'dev-to': '󰵮',
  'developer-board': '󰚗',
  deviantart: '󰇇',
  devices: '󰾰',
  diabetes: '󱄦',
  dialpad: '󰘜',
  diameter: '󰱣',
  'diameter-outline': '󰱤',
  'diameter-variant': '󰱥',
  diamond: '󰮊',
  'diamond-outline': '󰮋',
  'diamond-stone': '󰇈',
  'dice-1': '󰇊',
  'dice-1-outline': '󱅊',
  'dice-2': '󰇋',
  'dice-2-outline': '󱅋',
  'dice-3': '󰇌',
  'dice-3-outline': '󱅌',
  'dice-4': '󰇍',
  'dice-4-outline': '󱅍',
  'dice-5': '󰇎',
  'dice-5-outline': '󱅎',
  'dice-6': '󰇏',
  'dice-6-outline': '󱅏',
  'dice-d10': '󱅓',
  'dice-d10-outline': '󰝯',
  'dice-d12': '󱅔',
  'dice-d12-outline': '󰡧',
  'dice-d20': '󱅕',
  'dice-d20-outline': '󰗪',
  'dice-d4': '󱅐',
  'dice-d4-outline': '󰗫',
  'dice-d6': '󱅑',
  'dice-d6-outline': '󰗭',
  'dice-d8': '󱅒',
  'dice-d8-outline': '󰗬',
  'dice-multiple': '󰝮',
  'dice-multiple-outline': '󱅖',
  'digital-ocean': '󱈷',
  'dip-switch': '󰟁',
  directions: '󰇐',
  'directions-fork': '󰙁',
  disc: '󰗮',
  'disc-alert': '󰇑',
  'disc-player': '󰥠',
  discord: '󰙯',
  dishwasher: '󰪬',
  'dishwasher-alert': '󱆸',
  'dishwasher-off': '󱆹',
  disqus: '󰇒',
  'distribute-horizontal-center': '󱇉',
  'distribute-horizontal-left': '󱇈',
  'distribute-horizontal-right': '󱇊',
  'distribute-vertical-bottom': '󱇋',
  'distribute-vertical-center': '󱇌',
  'distribute-vertical-top': '󱇍',
  'diving-flippers': '󰶿',
  'diving-helmet': '󰷀',
  'diving-scuba': '󰷁',
  'diving-scuba-flag': '󰷂',
  'diving-scuba-tank': '󰷃',
  'diving-scuba-tank-multiple': '󰷄',
  'diving-snorkel': '󰷅',
  division: '󰇔',
  'division-box': '󰇕',
  dlna: '󰩁',
  dna: '󰚄',
  dns: '󰇖',
  'dns-outline': '󰮌',
  'do-not-disturb': '󰚘',
  'do-not-disturb-off': '󰚙',
  'dock-bottom': '󱂩',
  'dock-left': '󱂪',
  'dock-right': '󱂫',
  'dock-top': '󱔓',
  'dock-window': '󱂬',
  docker: '󰡨',
  doctor: '󰩂',
  dog: '󰩃',
  'dog-service': '󰪭',
  'dog-side': '󰩄',
  dolby: '󰚳',
  dolly: '󰺞',
  domain: '󰇗',
  'domain-off': '󰵯',
  'domain-plus': '󱂭',
  'domain-remove': '󱂮',
  'dome-light': '󱐞',
  'domino-mask': '󱀣',
  donkey: '󰟂',
  door: '󰠚',
  'door-closed': '󰠛',
  'door-closed-lock': '󱂯',
  'door-open': '󰠜',
  doorbell: '󱋦',
  'doorbell-video': '󰡩',
  'dot-net': '󰪮',
  'dots-grid': '󱗼',
  'dots-hexagon': '󱗿',
  'dots-horizontal': '󰇘',
  'dots-horizontal-circle': '󰟃',
  'dots-horizontal-circle-outline': '󰮍',
  'dots-square': '󱗽',
  'dots-triangle': '󱗾',
  'dots-vertical': '󰇙',
  'dots-vertical-circle': '󰟄',
  'dots-vertical-circle-outline': '󰮎',
  douban: '󰚚',
  download: '󰇚',
  'download-box': '󱑢',
  'download-box-outline': '󱑣',
  'download-circle': '󱑤',
  'download-circle-outline': '󱑥',
  'download-lock': '󱌠',
  'download-lock-outline': '󱌡',
  'download-multiple': '󰧩',
  'download-network': '󰛴',
  'download-network-outline': '󰱦',
  'download-off': '󱂰',
  'download-off-outline': '󱂱',
  'download-outline': '󰮏',
  drag: '󰇛',
  'drag-horizontal': '󰇜',
  'drag-horizontal-variant': '󱋰',
  'drag-variant': '󰮐',
  'drag-vertical': '󰇝',
  'drag-vertical-variant': '󱋱',
  'drama-masks': '󰴂',
  draw: '󰽉',
  drawing: '󰇞',
  'drawing-box': '󰇟',
  dresser: '󰽊',
  'dresser-outline': '󰽋',
  drone: '󰇢',
  dropbox: '󰇣',
  drupal: '󰇤',
  duck: '󰇥',
  dumbbell: '󰇦',
  'dump-truck': '󰱧',
  'ear-hearing': '󰟅',
  'ear-hearing-off': '󰩅',
  earth: '󰇧',
  'earth-arrow-right': '󱌑',
  'earth-box': '󰛍',
  'earth-box-minus': '󱐇',
  'earth-box-off': '󰛎',
  'earth-box-plus': '󱐆',
  'earth-box-remove': '󱐈',
  'earth-minus': '󱐄',
  'earth-off': '󰇨',
  'earth-plus': '󱐃',
  'earth-remove': '󱐅',
  egg: '󰪯',
  'egg-easter': '󰪰',
  'egg-off': '󱏰',
  'egg-off-outline': '󱏱',
  'egg-outline': '󱏲',
  'eiffel-tower': '󱕫',
  'eight-track': '󰧪',
  eject: '󰇪',
  'eject-outline': '󰮑',
  'electric-switch': '󰺟',
  'electric-switch-closed': '󱃙',
  'electron-framework': '󱀤',
  elephant: '󰟆',
  'elevation-decline': '󰇫',
  'elevation-rise': '󰇬',
  elevator: '󰇭',
  'elevator-down': '󱋂',
  'elevator-passenger': '󱎁',
  'elevator-up': '󱋁',
  ellipse: '󰺠',
  'ellipse-outline': '󰺡',
  email: '󰇮',
  'email-alert': '󰛏',
  'email-alert-outline': '󰵂',
  'email-box': '󰴃',
  'email-check': '󰪱',
  'email-check-outline': '󰪲',
  'email-edit': '󰻣',
  'email-edit-outline': '󰻤',
  'email-lock': '󰇱',
  'email-mark-as-unread': '󰮒',
  'email-minus': '󰻥',
  'email-minus-outline': '󰻦',
  'email-multiple': '󰻧',
  'email-multiple-outline': '󰻨',
  'email-newsletter': '󰾱',
  'email-off': '󱏣',
  'email-off-outline': '󱏤',
  'email-open': '󰇯',
  'email-open-multiple': '󰻩',
  'email-open-multiple-outline': '󰻪',
  'email-open-outline': '󰗯',
  'email-outline': '󰇰',
  'email-plus': '󰧫',
  'email-plus-outline': '󰧬',
  'email-receive': '󱃚',
  'email-receive-outline': '󱃛',
  'email-remove': '󱙡',
  'email-remove-outline': '󱙢',
  'email-search': '󰥡',
  'email-search-outline': '󰥢',
  'email-send': '󱃜',
  'email-send-outline': '󱃝',
  'email-sync': '󱋇',
  'email-sync-outline': '󱋈',
  'email-variant': '󰗰',
  ember: '󰬰',
  emby: '󰚴',
  emoticon: '󰱨',
  'emoticon-angry': '󰱩',
  'emoticon-angry-outline': '󰱪',
  'emoticon-confused': '󱃞',
  'emoticon-confused-outline': '󱃟',
  'emoticon-cool': '󰱫',
  'emoticon-cool-outline': '󰇳',
  'emoticon-cry': '󰱬',
  'emoticon-cry-outline': '󰱭',
  'emoticon-dead': '󰱮',
  'emoticon-dead-outline': '󰚛',
  'emoticon-devil': '󰱯',
  'emoticon-devil-outline': '󰇴',
  'emoticon-excited': '󰱰',
  'emoticon-excited-outline': '󰚜',
  'emoticon-frown': '󰽌',
  'emoticon-frown-outline': '󰽍',
  'emoticon-happy': '󰱱',
  'emoticon-happy-outline': '󰇵',
  'emoticon-kiss': '󰱲',
  'emoticon-kiss-outline': '󰱳',
  'emoticon-lol': '󱈔',
  'emoticon-lol-outline': '󱈕',
  'emoticon-neutral': '󰱴',
  'emoticon-neutral-outline': '󰇶',
  'emoticon-outline': '󰇲',
  'emoticon-poop': '󰇷',
  'emoticon-poop-outline': '󰱵',
  'emoticon-sad': '󰱶',
  'emoticon-sad-outline': '󰇸',
  'emoticon-sick': '󱕼',
  'emoticon-sick-outline': '󱕽',
  'emoticon-tongue': '󰇹',
  'emoticon-tongue-outline': '󰱷',
  'emoticon-wink': '󰱸',
  'emoticon-wink-outline': '󰱹',
  engine: '󰇺',
  'engine-off': '󰩆',
  'engine-off-outline': '󰩇',
  'engine-outline': '󰇻',
  epsilon: '󱃠',
  equal: '󰇼',
  'equal-box': '󰇽',
  equalizer: '󰺢',
  'equalizer-outline': '󰺣',
  eraser: '󰇾',
  'eraser-variant': '󰙂',
  escalator: '󰇿',
  'escalator-box': '󱎙',
  'escalator-down': '󱋀',
  'escalator-up': '󱊿',
  eslint: '󰱺',
  et: '󰪳',
  ethereum: '󰡪',
  ethernet: '󰈀',
  'ethernet-cable': '󰈁',
  'ethernet-cable-off': '󰈂',
  'ev-plug-ccs1': '󱔙',
  'ev-plug-ccs2': '󱔚',
  'ev-plug-chademo': '󱔛',
  'ev-plug-tesla': '󱔜',
  'ev-plug-type1': '󱔝',
  'ev-plug-type2': '󱔞',
  'ev-station': '󰗱',
  evernote: '󰈄',
  excavator: '󱀥',
  exclamation: '󰈅',
  'exclamation-thick': '󱈸',
  'exit-run': '󰩈',
  'exit-to-app': '󰈆',
  'expand-all': '󰪴',
  'expand-all-outline': '󰪵',
  'expansion-card': '󰢮',
  'expansion-card-variant': '󰾲',
  exponent: '󰥣',
  'exponent-box': '󰥤',
  export: '󰈇',
  'export-variant': '󰮓',
  eye: '󰈈',
  'eye-check': '󰴄',
  'eye-check-outline': '󰴅',
  'eye-circle': '󰮔',
  'eye-circle-outline': '󰮕',
  'eye-minus': '󱀦',
  'eye-minus-outline': '󱀧',
  'eye-off': '󰈉',
  'eye-off-outline': '󰛑',
  'eye-outline': '󰛐',
  'eye-plus': '󰡫',
  'eye-plus-outline': '󰡬',
  'eye-remove': '󱗣',
  'eye-remove-outline': '󱗤',
  'eye-settings': '󰡭',
  'eye-settings-outline': '󰡮',
  eyedropper: '󰈊',
  'eyedropper-minus': '󱏝',
  'eyedropper-off': '󱏟',
  'eyedropper-plus': '󱏜',
  'eyedropper-remove': '󱏞',
  'eyedropper-variant': '󰈋',
  face: '󰙃',
  'face-agent': '󰵰',
  'face-mask': '󱖆',
  'face-mask-outline': '󱖇',
  'face-outline': '󰮖',
  'face-profile': '󰙄',
  'face-profile-woman': '󱁶',
  'face-recognition': '󰱻',
  'face-shimmer': '󱗌',
  'face-shimmer-outline': '󱗍',
  'face-woman': '󱁷',
  'face-woman-outline': '󱁸',
  'face-woman-shimmer': '󱗎',
  'face-woman-shimmer-outline': '󱗏',
  facebook: '󰈌',
  'facebook-gaming': '󰟝',
  'facebook-messenger': '󰈎',
  'facebook-workplace': '󰬱',
  factory: '󰈏',
  'family-tree': '󱘎',
  fan: '󰈐',
  'fan-alert': '󱑬',
  'fan-chevron-down': '󱑭',
  'fan-chevron-up': '󱑮',
  'fan-minus': '󱑰',
  'fan-off': '󰠝',
  'fan-plus': '󱑯',
  'fan-remove': '󱑱',
  'fan-speed-1': '󱑲',
  'fan-speed-2': '󱑳',
  'fan-speed-3': '󱑴',
  'fast-forward': '󰈑',
  'fast-forward-10': '󰵱',
  'fast-forward-30': '󰴆',
  'fast-forward-5': '󱇸',
  'fast-forward-60': '󱘋',
  'fast-forward-outline': '󰛒',
  fax: '󰈒',
  feather: '󰛓',
  'feature-search': '󰩉',
  'feature-search-outline': '󰩊',
  fedora: '󰣛',
  fencing: '󱓁',
  'ferris-wheel': '󰺤',
  ferry: '󰈓',
  file: '󰈔',
  'file-account': '󰜻',
  'file-account-outline': '󱀨',
  'file-alert': '󰩋',
  'file-alert-outline': '󰩌',
  'file-cabinet': '󰪶',
  'file-cad': '󰻫',
  'file-cad-box': '󰻬',
  'file-cancel': '󰷆',
  'file-cancel-outline': '󰷇',
  'file-certificate': '󱆆',
  'file-certificate-outline': '󱆇',
  'file-chart': '󰈕',
  'file-chart-outline': '󱀩',
  'file-check': '󰈖',
  'file-check-outline': '󰸩',
  'file-clock': '󱋡',
  'file-clock-outline': '󱋢',
  'file-cloud': '󰈗',
  'file-cloud-outline': '󱀪',
  'file-code': '󰈮',
  'file-code-outline': '󱀫',
  'file-cog': '󱁻',
  'file-cog-outline': '󱁼',
  'file-compare': '󰢪',
  'file-delimited': '󰈘',
  'file-delimited-outline': '󰺥',
  'file-document': '󰈙',
  'file-document-edit': '󰷈',
  'file-document-edit-outline': '󰷉',
  'file-document-multiple': '󱔗',
  'file-document-multiple-outline': '󱔘',
  'file-document-outline': '󰧮',
  'file-download': '󰥥',
  'file-download-outline': '󰥦',
  'file-edit': '󱇧',
  'file-edit-outline': '󱇨',
  'file-excel': '󰈛',
  'file-excel-box': '󰈜',
  'file-excel-box-outline': '󱀬',
  'file-excel-outline': '󱀭',
  'file-export': '󰈝',
  'file-export-outline': '󱀮',
  'file-eye': '󰷊',
  'file-eye-outline': '󰷋',
  'file-find': '󰈞',
  'file-find-outline': '󰮗',
  'file-hidden': '󰘓',
  'file-image': '󰈟',
  'file-image-outline': '󰺰',
  'file-import': '󰈠',
  'file-import-outline': '󱀯',
  'file-key': '󱆄',
  'file-key-outline': '󱆅',
  'file-link': '󱅷',
  'file-link-outline': '󱅸',
  'file-lock': '󰈡',
  'file-lock-outline': '󱀰',
  'file-move': '󰪹',
  'file-move-outline': '󱀱',
  'file-multiple': '󰈢',
  'file-multiple-outline': '󱀲',
  'file-music': '󰈣',
  'file-music-outline': '󰸪',
  'file-outline': '󰈤',
  'file-pdf': '󰈥',
  'file-pdf-box': '󰈦',
  'file-pdf-box-outline': '󰾳',
  'file-pdf-outline': '󰸭',
  'file-percent': '󰠞',
  'file-percent-outline': '󱀳',
  'file-phone': '󱅹',
  'file-phone-outline': '󱅺',
  'file-plus': '󰝒',
  'file-plus-outline': '󰻭',
  'file-powerpoint': '󰈧',
  'file-powerpoint-box': '󰈨',
  'file-powerpoint-box-outline': '󱀴',
  'file-powerpoint-outline': '󱀵',
  'file-presentation-box': '󰈩',
  'file-question': '󰡯',
  'file-question-outline': '󱀶',
  'file-refresh': '󰤘',
  'file-refresh-outline': '󰕁',
  'file-remove': '󰮘',
  'file-remove-outline': '󱀷',
  'file-replace': '󰬲',
  'file-replace-outline': '󰬳',
  'file-restore': '󰙰',
  'file-restore-outline': '󱀸',
  'file-search': '󰱼',
  'file-search-outline': '󰱽',
  'file-send': '󰈪',
  'file-send-outline': '󱀹',
  'file-settings': '󱁹',
  'file-settings-outline': '󱁺',
  'file-star': '󱀺',
  'file-star-outline': '󱀻',
  'file-swap': '󰾴',
  'file-swap-outline': '󰾵',
  'file-sync': '󱈖',
  'file-sync-outline': '󱈗',
  'file-table': '󰱾',
  'file-table-box': '󱃡',
  'file-table-box-multiple': '󱃢',
  'file-table-box-multiple-outline': '󱃣',
  'file-table-box-outline': '󱃤',
  'file-table-outline': '󰱿',
  'file-tree': '󰙅',
  'file-tree-outline': '󱏒',
  'file-undo': '󰣜',
  'file-undo-outline': '󱀼',
  'file-upload': '󰩍',
  'file-upload-outline': '󰩎',
  'file-video': '󰈫',
  'file-video-outline': '󰸬',
  'file-word': '󰈬',
  'file-word-box': '󰈭',
  'file-word-box-outline': '󱀽',
  'file-word-outline': '󱀾',
  film: '󰈯',
  filmstrip: '󰈰',
  'filmstrip-box': '󰌲',
  'filmstrip-box-multiple': '󰴘',
  'filmstrip-off': '󰈱',
  filter: '󰈲',
  'filter-menu': '󱃥',
  'filter-menu-outline': '󱃦',
  'filter-minus': '󰻮',
  'filter-minus-outline': '󰻯',
  'filter-off': '󱓯',
  'filter-off-outline': '󱓰',
  'filter-outline': '󰈳',
  'filter-plus': '󰻰',
  'filter-plus-outline': '󰻱',
  'filter-remove': '󰈴',
  'filter-remove-outline': '󰈵',
  'filter-variant': '󰈶',
  'filter-variant-minus': '󱄒',
  'filter-variant-plus': '󱄓',
  'filter-variant-remove': '󱀿',
  finance: '󰠟',
  'find-replace': '󰛔',
  fingerprint: '󰈷',
  'fingerprint-off': '󰺱',
  fire: '󰈸',
  'fire-alert': '󱗗',
  'fire-extinguisher': '󰻲',
  'fire-hydrant': '󱄷',
  'fire-hydrant-alert': '󱄸',
  'fire-hydrant-off': '󱄹',
  'fire-truck': '󰢫',
  firebase: '󰥧',
  firefox: '󰈹',
  fireplace: '󰸮',
  'fireplace-off': '󰸯',
  firework: '󰸰',
  fish: '󰈺',
  'fish-off': '󱏳',
  fishbowl: '󰻳',
  'fishbowl-outline': '󰻴',
  'fit-to-page': '󰻵',
  'fit-to-page-outline': '󰻶',
  flag: '󰈻',
  'flag-checkered': '󰈼',
  'flag-minus': '󰮙',
  'flag-minus-outline': '󱂲',
  'flag-outline': '󰈽',
  'flag-plus': '󰮚',
  'flag-plus-outline': '󱂳',
  'flag-remove': '󰮛',
  'flag-remove-outline': '󱂴',
  'flag-triangle': '󰈿',
  'flag-variant': '󰉀',
  'flag-variant-outline': '󰈾',
  flare: '󰵲',
  flash: '󰉁',
  'flash-alert': '󰻷',
  'flash-alert-outline': '󰻸',
  'flash-auto': '󰉂',
  'flash-circle': '󰠠',
  'flash-off': '󰉃',
  'flash-outline': '󰛕',
  'flash-red-eye': '󰙻',
  flashlight: '󰉄',
  'flashlight-off': '󰉅',
  flask: '󰂓',
  'flask-empty': '󰂔',
  'flask-empty-minus': '󱈺',
  'flask-empty-minus-outline': '󱈻',
  'flask-empty-off': '󱏴',
  'flask-empty-off-outline': '󱏵',
  'flask-empty-outline': '󰂕',
  'flask-empty-plus': '󱈼',
  'flask-empty-plus-outline': '󱈽',
  'flask-empty-remove': '󱈾',
  'flask-empty-remove-outline': '󱈿',
  'flask-minus': '󱉀',
  'flask-minus-outline': '󱉁',
  'flask-off': '󱏶',
  'flask-off-outline': '󱏷',
  'flask-outline': '󰂖',
  'flask-plus': '󱉂',
  'flask-plus-outline': '󱉃',
  'flask-remove': '󱉄',
  'flask-remove-outline': '󱉅',
  'flask-round-bottom': '󱉋',
  'flask-round-bottom-empty': '󱉌',
  'flask-round-bottom-empty-outline': '󱉍',
  'flask-round-bottom-outline': '󱉎',
  'fleur-de-lis': '󱌃',
  'flip-horizontal': '󱃧',
  'flip-to-back': '󰉇',
  'flip-to-front': '󰉈',
  'flip-vertical': '󱃨',
  'floor-lamp': '󰣝',
  'floor-lamp-dual': '󱁀',
  'floor-lamp-variant': '󱁁',
  'floor-plan': '󰠡',
  floppy: '󰉉',
  'floppy-variant': '󰧯',
  flower: '󰉊',
  'flower-outline': '󰧰',
  'flower-poppy': '󰴈',
  'flower-tulip': '󰧱',
  'flower-tulip-outline': '󰧲',
  'focus-auto': '󰽎',
  'focus-field': '󰽏',
  'focus-field-horizontal': '󰽐',
  'focus-field-vertical': '󰽑',
  folder: '󰉋',
  'folder-account': '󰉌',
  'folder-account-outline': '󰮜',
  'folder-alert': '󰷌',
  'folder-alert-outline': '󰷍',
  'folder-clock': '󰪺',
  'folder-clock-outline': '󰪻',
  'folder-cog': '󱁿',
  'folder-cog-outline': '󱂀',
  'folder-download': '󰉍',
  'folder-download-outline': '󱃩',
  'folder-edit': '󰣞',
  'folder-edit-outline': '󰷎',
  'folder-google-drive': '󰉎',
  'folder-heart': '󱃪',
  'folder-heart-outline': '󱃫',
  'folder-home': '󱂵',
  'folder-home-outline': '󱂶',
  'folder-image': '󰉏',
  'folder-information': '󱂷',
  'folder-information-outline': '󱂸',
  'folder-key': '󰢬',
  'folder-key-network': '󰢭',
  'folder-key-network-outline': '󰲀',
  'folder-key-outline': '󱃬',
  'folder-lock': '󰉐',
  'folder-lock-open': '󰉑',
  'folder-marker': '󱉭',
  'folder-marker-outline': '󱉮',
  'folder-move': '󰉒',
  'folder-move-outline': '󱉆',
  'folder-multiple': '󰉓',
  'folder-multiple-image': '󰉔',
  'folder-multiple-outline': '󰉕',
  'folder-multiple-plus': '󱑾',
  'folder-multiple-plus-outline': '󱑿',
  'folder-music': '󱍙',
  'folder-music-outline': '󱍚',
  'folder-network': '󰡰',
  'folder-network-outline': '󰲁',
  'folder-open': '󰝰',
  'folder-open-outline': '󰷏',
  'folder-outline': '󰉖',
  'folder-plus': '󰉗',
  'folder-plus-outline': '󰮝',
  'folder-pound': '󰴉',
  'folder-pound-outline': '󰴊',
  'folder-refresh': '󰝉',
  'folder-refresh-outline': '󰕂',
  'folder-remove': '󰉘',
  'folder-remove-outline': '󰮞',
  'folder-search': '󰥨',
  'folder-search-outline': '󰥩',
  'folder-settings': '󱁽',
  'folder-settings-outline': '󱁾',
  'folder-star': '󰚝',
  'folder-star-multiple': '󱏓',
  'folder-star-multiple-outline': '󱏔',
  'folder-star-outline': '󰮟',
  'folder-swap': '󰾶',
  'folder-swap-outline': '󰾷',
  'folder-sync': '󰴋',
  'folder-sync-outline': '󰴌',
  'folder-table': '󱋣',
  'folder-table-outline': '󱋤',
  'folder-text': '󰲂',
  'folder-text-outline': '󰲃',
  'folder-upload': '󰉙',
  'folder-upload-outline': '󱃭',
  'folder-zip': '󰛫',
  'folder-zip-outline': '󰞹',
  'font-awesome': '󰀺',
  food: '󰉚',
  'food-apple': '󰉛',
  'food-apple-outline': '󰲄',
  'food-croissant': '󰟈',
  'food-drumstick': '󱐟',
  'food-drumstick-off': '󱑨',
  'food-drumstick-off-outline': '󱑩',
  'food-drumstick-outline': '󱐠',
  'food-fork-drink': '󰗲',
  'food-halal': '󱕲',
  'food-kosher': '󱕳',
  'food-off': '󰗳',
  'food-steak': '󱑪',
  'food-steak-off': '󱑫',
  'food-variant': '󰉜',
  'food-variant-off': '󱏥',
  'foot-print': '󰽒',
  football: '󰉝',
  'football-australian': '󰉞',
  'football-helmet': '󰉟',
  forklift: '󰟉',
  'form-dropdown': '󱐀',
  'form-select': '󱐁',
  'form-textarea': '󱂕',
  'form-textbox': '󰘎',
  'form-textbox-lock': '󱍝',
  'form-textbox-password': '󰟵',
  'format-align-bottom': '󰝓',
  'format-align-center': '󰉠',
  'format-align-justify': '󰉡',
  'format-align-left': '󰉢',
  'format-align-middle': '󰝔',
  'format-align-right': '󰉣',
  'format-align-top': '󰝕',
  'format-annotation-minus': '󰪼',
  'format-annotation-plus': '󰙆',
  'format-bold': '󰉤',
  'format-clear': '󰉥',
  'format-color-fill': '󰉦',
  'format-color-highlight': '󰸱',
  'format-color-marker-cancel': '󱌓',
  'format-color-text': '󰚞',
  'format-columns': '󰣟',
  'format-float-center': '󰉧',
  'format-float-left': '󰉨',
  'format-float-none': '󰉩',
  'format-float-right': '󰉪',
  'format-font': '󰛖',
  'format-font-size-decrease': '󰧳',
  'format-font-size-increase': '󰧴',
  'format-header-1': '󰉫',
  'format-header-2': '󰉬',
  'format-header-3': '󰉭',
  'format-header-4': '󰉮',
  'format-header-5': '󰉯',
  'format-header-6': '󰉰',
  'format-header-decrease': '󰉱',
  'format-header-equal': '󰉲',
  'format-header-increase': '󰉳',
  'format-header-pound': '󰉴',
  'format-horizontal-align-center': '󰘞',
  'format-horizontal-align-left': '󰘟',
  'format-horizontal-align-right': '󰘠',
  'format-indent-decrease': '󰉵',
  'format-indent-increase': '󰉶',
  'format-italic': '󰉷',
  'format-letter-case': '󰬴',
  'format-letter-case-lower': '󰬵',
  'format-letter-case-upper': '󰬶',
  'format-letter-ends-with': '󰾸',
  'format-letter-matches': '󰾹',
  'format-letter-starts-with': '󰾺',
  'format-line-spacing': '󰉸',
  'format-line-style': '󰗈',
  'format-line-weight': '󰗉',
  'format-list-bulleted': '󰉹',
  'format-list-bulleted-square': '󰷐',
  'format-list-bulleted-triangle': '󰺲',
  'format-list-bulleted-type': '󰉺',
  'format-list-checkbox': '󰥪',
  'format-list-checks': '󰝖',
  'format-list-numbered': '󰉻',
  'format-list-numbered-rtl': '󰴍',
  'format-list-text': '󱉯',
  'format-overline': '󰺳',
  'format-page-break': '󰛗',
  'format-paint': '󰉼',
  'format-paragraph': '󰉽',
  'format-pilcrow': '󰛘',
  'format-quote-close': '󰉾',
  'format-quote-close-outline': '󱆨',
  'format-quote-open': '󰝗',
  'format-quote-open-outline': '󱆧',
  'format-rotate-90': '󰚪',
  'format-section': '󰚟',
  'format-size': '󰉿',
  'format-strikethrough': '󰊀',
  'format-strikethrough-variant': '󰊁',
  'format-subscript': '󰊂',
  'format-superscript': '󰊃',
  'format-text': '󰊄',
  'format-text-rotation-angle-down': '󰾻',
  'format-text-rotation-angle-up': '󰾼',
  'format-text-rotation-down': '󰵳',
  'format-text-rotation-down-vertical': '󰾽',
  'format-text-rotation-none': '󰵴',
  'format-text-rotation-up': '󰾾',
  'format-text-rotation-vertical': '󰾿',
  'format-text-variant': '󰸲',
  'format-text-variant-outline': '󱔏',
  'format-text-wrapping-clip': '󰴎',
  'format-text-wrapping-overflow': '󰴏',
  'format-text-wrapping-wrap': '󰴐',
  'format-textbox': '󰴑',
  'format-textdirection-l-to-r': '󰊅',
  'format-textdirection-r-to-l': '󰊆',
  'format-title': '󰗴',
  'format-underline': '󰊇',
  'format-vertical-align-bottom': '󰘡',
  'format-vertical-align-center': '󰘢',
  'format-vertical-align-top': '󰘣',
  'format-wrap-inline': '󰊈',
  'format-wrap-square': '󰊉',
  'format-wrap-tight': '󰊊',
  'format-wrap-top-bottom': '󰊋',
  forum: '󰊌',
  'forum-outline': '󰠢',
  forward: '󰊍',
  forwardburger: '󰵵',
  fountain: '󰥫',
  'fountain-pen': '󰴒',
  'fountain-pen-tip': '󰴓',
  freebsd: '󰣠',
  'frequently-asked-questions': '󰺴',
  fridge: '󰊐',
  'fridge-alert': '󱆱',
  'fridge-alert-outline': '󱆲',
  'fridge-bottom': '󰊒',
  'fridge-industrial': '󱗮',
  'fridge-industrial-alert': '󱗯',
  'fridge-industrial-alert-outline': '󱗰',
  'fridge-industrial-off': '󱗱',
  'fridge-industrial-off-outline': '󱗲',
  'fridge-industrial-outline': '󱗳',
  'fridge-off': '󱆯',
  'fridge-off-outline': '󱆰',
  'fridge-outline': '󰊏',
  'fridge-top': '󰊑',
  'fridge-variant': '󱗴',
  'fridge-variant-alert': '󱗵',
  'fridge-variant-alert-outline': '󱗶',
  'fridge-variant-off': '󱗷',
  'fridge-variant-off-outline': '󱗸',
  'fridge-variant-outline': '󱗹',
  'fruit-cherries': '󱁂',
  'fruit-cherries-off': '󱏸',
  'fruit-citrus': '󱁃',
  'fruit-citrus-off': '󱏹',
  'fruit-grapes': '󱁄',
  'fruit-grapes-outline': '󱁅',
  'fruit-pineapple': '󱁆',
  'fruit-watermelon': '󱁇',
  fuel: '󰟊',
  fullscreen: '󰊓',
  'fullscreen-exit': '󰊔',
  function: '󰊕',
  'function-variant': '󰡱',
  'furigana-horizontal': '󱂁',
  'furigana-vertical': '󱂂',
  fuse: '󰲅',
  'fuse-alert': '󱐭',
  'fuse-blade': '󰲆',
  'fuse-off': '󱐬',
  gamepad: '󰊖',
  'gamepad-circle': '󰸳',
  'gamepad-circle-down': '󰸴',
  'gamepad-circle-left': '󰸵',
  'gamepad-circle-outline': '󰸶',
  'gamepad-circle-right': '󰸷',
  'gamepad-circle-up': '󰸸',
  'gamepad-down': '󰸹',
  'gamepad-left': '󰸺',
  'gamepad-right': '󰸻',
  'gamepad-round': '󰸼',
  'gamepad-round-down': '󰸽',
  'gamepad-round-left': '󰸾',
  'gamepad-round-outline': '󰸿',
  'gamepad-round-right': '󰹀',
  'gamepad-round-up': '󰹁',
  'gamepad-square': '󰺵',
  'gamepad-square-outline': '󰺶',
  'gamepad-up': '󰹂',
  'gamepad-variant': '󰊗',
  'gamepad-variant-outline': '󰺷',
  gamma: '󱃮',
  'gantry-crane': '󰷑',
  garage: '󰛙',
  'garage-alert': '󰡲',
  'garage-alert-variant': '󱋕',
  'garage-open': '󰛚',
  'garage-open-variant': '󱋔',
  'garage-variant': '󱋓',
  'gas-cylinder': '󰙇',
  'gas-station': '󰊘',
  'gas-station-off': '󱐉',
  'gas-station-off-outline': '󱐊',
  'gas-station-outline': '󰺸',
  gate: '󰊙',
  'gate-and': '󰣡',
  'gate-arrow-right': '󱅩',
  'gate-nand': '󰣢',
  'gate-nor': '󰣣',
  'gate-not': '󰣤',
  'gate-open': '󱅪',
  'gate-or': '󰣥',
  'gate-xnor': '󰣦',
  'gate-xor': '󰣧',
  gatsby: '󰹃',
  gauge: '󰊚',
  'gauge-empty': '󰡳',
  'gauge-full': '󰡴',
  'gauge-low': '󰡵',
  gavel: '󰊛',
  'gender-female': '󰊜',
  'gender-male': '󰊝',
  'gender-male-female': '󰊞',
  'gender-male-female-variant': '󱄿',
  'gender-non-binary': '󱅀',
  'gender-transgender': '󰊟',
  gentoo: '󰣨',
  gesture: '󰟋',
  'gesture-double-tap': '󰜼',
  'gesture-pinch': '󰪽',
  'gesture-spread': '󰪾',
  'gesture-swipe': '󰵶',
  'gesture-swipe-down': '󰜽',
  'gesture-swipe-horizontal': '󰪿',
  'gesture-swipe-left': '󰜾',
  'gesture-swipe-right': '󰜿',
  'gesture-swipe-up': '󰝀',
  'gesture-swipe-vertical': '󰫀',
  'gesture-tap': '󰝁',
  'gesture-tap-box': '󱊩',
  'gesture-tap-button': '󱊨',
  'gesture-tap-hold': '󰵷',
  'gesture-two-double-tap': '󰝂',
  'gesture-two-tap': '󰝃',
  ghost: '󰊠',
  'ghost-off': '󰧵',
  'ghost-off-outline': '󱙜',
  'ghost-outline': '󱙝',
  gif: '󰵸',
  gift: '󰹄',
  'gift-outline': '󰊡',
  git: '󰊢',
  github: '󰊤',
  gitlab: '󰮠',
  'glass-cocktail': '󰍖',
  'glass-cocktail-off': '󱗦',
  'glass-flute': '󰊥',
  'glass-mug': '󰊦',
  'glass-mug-off': '󱗧',
  'glass-mug-variant': '󱄖',
  'glass-mug-variant-off': '󱗨',
  'glass-pint-outline': '󱌍',
  'glass-stange': '󰊧',
  'glass-tulip': '󰊨',
  'glass-wine': '󰡶',
  glasses: '󰊪',
  'globe-light': '󱋗',
  'globe-model': '󰣩',
  gmail: '󰊫',
  gnome: '󰊬',
  'go-kart': '󰵹',
  'go-kart-track': '󰵺',
  gog: '󰮡',
  gold: '󱉏',
  golf: '󰠣',
  'golf-cart': '󱆤',
  'golf-tee': '󱂃',
  gondola: '󰚆',
  goodreads: '󰵻',
  google: '󰊭',
  'google-ads': '󰲇',
  'google-analytics': '󰟌',
  'google-assistant': '󰟍',
  'google-cardboard': '󰊮',
  'google-chrome': '󰊯',
  'google-circles': '󰊰',
  'google-circles-communities': '󰊱',
  'google-circles-extended': '󰊲',
  'google-circles-group': '󰊳',
  'google-classroom': '󰋀',
  'google-cloud': '󱇶',
  'google-controller': '󰊴',
  'google-controller-off': '󰊵',
  'google-downasaur': '󱍢',
  'google-drive': '󰊶',
  'google-earth': '󰊷',
  'google-fit': '󰥬',
  'google-glass': '󰊸',
  'google-hangouts': '󰋉',
  'google-home': '󰠤',
  'google-keep': '󰛜',
  'google-lens': '󰧶',
  'google-maps': '󰗵',
  'google-my-business': '󱁈',
  'google-nearby': '󰊹',
  'google-photos': '󰛝',
  'google-play': '󰊼',
  'google-plus': '󰊽',
  'google-podcast': '󰺹',
  'google-spreadsheet': '󰧷',
  'google-street-view': '󰲈',
  'google-translate': '󰊿',
  gradient: '󰚠',
  grain: '󰵼',
  graph: '󱁉',
  'graph-outline': '󱁊',
  graphql: '󰡷',
  grass: '󱔐',
  'grave-stone': '󰮢',
  'grease-pencil': '󰙈',
  'greater-than': '󰥭',
  'greater-than-or-equal': '󰥮',
  grid: '󰋁',
  'grid-large': '󰝘',
  'grid-off': '󰋂',
  grill: '󰹅',
  'grill-outline': '󱆊',
  group: '󰋃',
  'guitar-acoustic': '󰝱',
  'guitar-electric': '󰋄',
  'guitar-pick': '󰋅',
  'guitar-pick-outline': '󰋆',
  'guy-fawkes-mask': '󰠥',
  hail: '󰫁',
  'hair-dryer': '󱃯',
  'hair-dryer-outline': '󱃰',
  halloween: '󰮣',
  hamburger: '󰚅',
  hammer: '󰣪',
  'hammer-screwdriver': '󱌢',
  'hammer-wrench': '󱌣',
  hand: '󰩏',
  'hand-heart': '󱃱',
  'hand-heart-outline': '󱕾',
  'hand-left': '󰹆',
  'hand-okay': '󰩐',
  'hand-peace': '󰩑',
  'hand-peace-variant': '󰩒',
  'hand-pointing-down': '󰩓',
  'hand-pointing-left': '󰩔',
  'hand-pointing-right': '󰋇',
  'hand-pointing-up': '󰩕',
  'hand-right': '󰹇',
  'hand-saw': '󰹈',
  'hand-wash': '󱕿',
  'hand-wash-outline': '󱖀',
  'hand-water': '󱎟',
  handball: '󰽓',
  handcuffs: '󱄾',
  handshake: '󱈘',
  'handshake-outline': '󱖡',
  hanger: '󰋈',
  'hard-hat': '󰥯',
  harddisk: '󰋊',
  'harddisk-plus': '󱁋',
  'harddisk-remove': '󱁌',
  'hat-fedora': '󰮤',
  'hazard-lights': '󰲉',
  hdr: '󰵽',
  'hdr-off': '󰵾',
  head: '󱍞',
  'head-alert': '󱌸',
  'head-alert-outline': '󱌹',
  'head-check': '󱌺',
  'head-check-outline': '󱌻',
  'head-cog': '󱌼',
  'head-cog-outline': '󱌽',
  'head-dots-horizontal': '󱌾',
  'head-dots-horizontal-outline': '󱌿',
  'head-flash': '󱍀',
  'head-flash-outline': '󱍁',
  'head-heart': '󱍂',
  'head-heart-outline': '󱍃',
  'head-lightbulb': '󱍄',
  'head-lightbulb-outline': '󱍅',
  'head-minus': '󱍆',
  'head-minus-outline': '󱍇',
  'head-outline': '󱍟',
  'head-plus': '󱍈',
  'head-plus-outline': '󱍉',
  'head-question': '󱍊',
  'head-question-outline': '󱍋',
  'head-remove': '󱍌',
  'head-remove-outline': '󱍍',
  'head-snowflake': '󱍎',
  'head-snowflake-outline': '󱍏',
  'head-sync': '󱍐',
  'head-sync-outline': '󱍑',
  headphones: '󰋋',
  'headphones-bluetooth': '󰥰',
  'headphones-box': '󰋌',
  'headphones-off': '󰟎',
  'headphones-settings': '󰋍',
  headset: '󰋎',
  'headset-dock': '󰋏',
  'headset-off': '󰋐',
  heart: '󰋑',
  'heart-box': '󰋒',
  'heart-box-outline': '󰋓',
  'heart-broken': '󰋔',
  'heart-broken-outline': '󰴔',
  'heart-circle': '󰥱',
  'heart-circle-outline': '󰥲',
  'heart-cog': '󱙣',
  'heart-cog-outline': '󱙤',
  'heart-flash': '󰻹',
  'heart-half': '󰛟',
  'heart-half-full': '󰛞',
  'heart-half-outline': '󰛠',
  'heart-minus': '󱐯',
  'heart-minus-outline': '󱐲',
  'heart-multiple': '󰩖',
  'heart-multiple-outline': '󰩗',
  'heart-off': '󰝙',
  'heart-off-outline': '󱐴',
  'heart-outline': '󰋕',
  'heart-plus': '󱐮',
  'heart-plus-outline': '󱐱',
  'heart-pulse': '󰗶',
  'heart-remove': '󱐰',
  'heart-remove-outline': '󱐳',
  'heart-settings': '󱙥',
  'heart-settings-outline': '󱙦',
  helicopter: '󰫂',
  help: '󰋖',
  'help-box': '󰞋',
  'help-circle': '󰋗',
  'help-circle-outline': '󰘥',
  'help-network': '󰛵',
  'help-network-outline': '󰲊',
  'help-rhombus': '󰮥',
  'help-rhombus-outline': '󰮦',
  hexadecimal: '󱊧',
  hexagon: '󰋘',
  'hexagon-multiple': '󰛡',
  'hexagon-multiple-outline': '󱃲',
  'hexagon-outline': '󰋙',
  'hexagon-slice-1': '󰫃',
  'hexagon-slice-2': '󰫄',
  'hexagon-slice-3': '󰫅',
  'hexagon-slice-4': '󰫆',
  'hexagon-slice-5': '󰫇',
  'hexagon-slice-6': '󰫈',
  hexagram: '󰫉',
  'hexagram-outline': '󰫊',
  'high-definition': '󰟏',
  'high-definition-box': '󰡸',
  highway: '󰗷',
  hiking: '󰵿',
  hinduism: '󰥳',
  history: '󰋚',
  'hockey-puck': '󰡹',
  'hockey-sticks': '󰡺',
  hololens: '󰋛',
  home: '󰋜',
  'home-account': '󰠦',
  'home-alert': '󰡻',
  'home-alert-outline': '󱗐',
  'home-analytics': '󰺺',
  'home-assistant': '󰟐',
  'home-automation': '󰟑',
  'home-circle': '󰟒',
  'home-circle-outline': '󱁍',
  'home-city': '󰴕',
  'home-city-outline': '󰴖',
  'home-currency-usd': '󰢯',
  'home-edit': '󱅙',
  'home-edit-outline': '󱅚',
  'home-export-outline': '󰾛',
  'home-flood': '󰻺',
  'home-floor-0': '󰷒',
  'home-floor-1': '󰶀',
  'home-floor-2': '󰶁',
  'home-floor-3': '󰶂',
  'home-floor-a': '󰶃',
  'home-floor-b': '󰶄',
  'home-floor-g': '󰶅',
  'home-floor-l': '󰶆',
  'home-floor-negative-1': '󰷓',
  'home-group': '󰷔',
  'home-heart': '󰠧',
  'home-import-outline': '󰾜',
  'home-lightbulb': '󱉑',
  'home-lightbulb-outline': '󱉒',
  'home-lock': '󰣫',
  'home-lock-open': '󰣬',
  'home-map-marker': '󰗸',
  'home-minus': '󰥴',
  'home-minus-outline': '󱏕',
  'home-modern': '󰋝',
  'home-outline': '󰚡',
  'home-plus': '󰥵',
  'home-plus-outline': '󱏖',
  'home-remove': '󱉇',
  'home-remove-outline': '󱏗',
  'home-roof': '󱄫',
  'home-search': '󱎰',
  'home-search-outline': '󱎱',
  'home-thermometer': '󰽔',
  'home-thermometer-outline': '󰽕',
  'home-variant': '󰋞',
  'home-variant-outline': '󰮧',
  hook: '󰛢',
  'hook-off': '󰛣',
  hops: '󰋟',
  'horizontal-rotate-clockwise': '󱃳',
  'horizontal-rotate-counterclockwise': '󱃴',
  horse: '󱖿',
  'horse-human': '󱗀',
  'horse-variant': '󱗁',
  horseshoe: '󰩘',
  hospital: '󰿶',
  'hospital-box': '󰋠',
  'hospital-box-outline': '󰿷',
  'hospital-building': '󰋡',
  'hospital-marker': '󰋢',
  'hot-tub': '󰠨',
  'hours-24': '󱑸',
  hubspot: '󰴗',
  hulu: '󰠩',
  human: '󰋦',
  'human-baby-changing-table': '󱎋',
  'human-cane': '󱖁',
  'human-capacity-decrease': '󱖛',
  'human-capacity-increase': '󱖜',
  'human-child': '󰋧',
  'human-edit': '󱓨',
  'human-female': '󰙉',
  'human-female-boy': '󰩙',
  'human-female-dance': '󱗉',
  'human-female-female': '󰩚',
  'human-female-girl': '󰩛',
  'human-greeting': '󰙊',
  'human-greeting-proximity': '󱖝',
  'human-handsdown': '󰙋',
  'human-handsup': '󰙌',
  'human-male': '󰙍',
  'human-male-boy': '󰩜',
  'human-male-child': '󱎌',
  'human-male-female': '󰋨',
  'human-male-girl': '󰩝',
  'human-male-height': '󰻻',
  'human-male-height-variant': '󰻼',
  'human-male-male': '󰩞',
  'human-pregnant': '󰗏',
  'human-queue': '󱕱',
  'human-scooter': '󱇩',
  'human-wheelchair': '󱎍',
  'humble-bundle': '󰝄',
  hvac: '󱍒',
  'hvac-off': '󱖞',
  'hydraulic-oil-level': '󱌤',
  'hydraulic-oil-temperature': '󱌥',
  'hydro-power': '󱋥',
  'ice-cream': '󰠪',
  'ice-cream-off': '󰹒',
  'ice-pop': '󰻽',
  'id-card': '󰿀',
  identifier: '󰻾',
  'ideogram-cjk': '󱌱',
  'ideogram-cjk-variant': '󱌲',
  iframe: '󰲋',
  'iframe-array': '󱃵',
  'iframe-array-outline': '󱃶',
  'iframe-braces': '󱃷',
  'iframe-braces-outline': '󱃸',
  'iframe-outline': '󰲌',
  'iframe-parentheses': '󱃹',
  'iframe-parentheses-outline': '󱃺',
  'iframe-variable': '󱃻',
  'iframe-variable-outline': '󱃼',
  image: '󰋩',
  'image-album': '󰋪',
  'image-area': '󰋫',
  'image-area-close': '󰋬',
  'image-auto-adjust': '󰿁',
  'image-broken': '󰋭',
  'image-broken-variant': '󰋮',
  'image-edit': '󱇣',
  'image-edit-outline': '󱇤',
  'image-filter-black-white': '󰋰',
  'image-filter-center-focus': '󰋱',
  'image-filter-center-focus-strong': '󰻿',
  'image-filter-center-focus-strong-outline': '󰼀',
  'image-filter-center-focus-weak': '󰋲',
  'image-filter-drama': '󰋳',
  'image-filter-frames': '󰋴',
  'image-filter-hdr': '󰋵',
  'image-filter-none': '󰋶',
  'image-filter-tilt-shift': '󰋷',
  'image-filter-vintage': '󰋸',
  'image-frame': '󰹉',
  'image-minus': '󱐙',
  'image-move': '󰧸',
  'image-multiple': '󰋹',
  'image-multiple-outline': '󰋯',
  'image-off': '󰠫',
  'image-off-outline': '󱇑',
  'image-outline': '󰥶',
  'image-plus': '󰡼',
  'image-remove': '󱐘',
  'image-search': '󰥷',
  'image-search-outline': '󰥸',
  'image-size-select-actual': '󰲍',
  'image-size-select-large': '󰲎',
  'image-size-select-small': '󰲏',
  'image-text': '󱘍',
  import: '󰋺',
  inbox: '󰚇',
  'inbox-arrow-down': '󰋻',
  'inbox-arrow-down-outline': '󱉰',
  'inbox-arrow-up': '󰏑',
  'inbox-arrow-up-outline': '󱉱',
  'inbox-full': '󱉲',
  'inbox-full-outline': '󱉳',
  'inbox-multiple': '󰢰',
  'inbox-multiple-outline': '󰮨',
  'inbox-outline': '󱉴',
  'inbox-remove': '󱖟',
  'inbox-remove-outline': '󱖠',
  incognito: '󰗹',
  'incognito-circle': '󱐡',
  'incognito-circle-off': '󱐢',
  'incognito-off': '󰁵',
  infinity: '󰛤',
  information: '󰋼',
  'information-outline': '󰋽',
  'information-variant': '󰙎',
  instagram: '󰋾',
  'instrument-triangle': '󱁎',
  'invert-colors': '󰌁',
  'invert-colors-off': '󰹊',
  iobroker: '󱋨',
  ip: '󰩟',
  'ip-network': '󰩠',
  'ip-network-outline': '󰲐',
  ipod: '󰲑',
  islam: '󰥹',
  island: '󱁏',
  'iv-bag': '󱂹',
  jabber: '󰷕',
  jeepney: '󰌂',
  jellyfish: '󰼁',
  'jellyfish-outline': '󰼂',
  jira: '󰌃',
  jquery: '󰡽',
  jsfiddle: '󰌄',
  judaism: '󰥺',
  'jump-rope': '󱋿',
  kabaddi: '󰶇',
  kangaroo: '󱕘',
  karate: '󰠬',
  keg: '󰌅',
  kettle: '󰗺',
  'kettle-alert': '󱌗',
  'kettle-alert-outline': '󱌘',
  'kettle-off': '󱌛',
  'kettle-off-outline': '󱌜',
  'kettle-outline': '󰽖',
  'kettle-steam': '󱌙',
  'kettle-steam-outline': '󱌚',
  kettlebell: '󱌀',
  key: '󰌆',
  'key-arrow-right': '󱌒',
  'key-chain': '󱕴',
  'key-chain-variant': '󱕵',
  'key-change': '󰌇',
  'key-link': '󱆟',
  'key-minus': '󰌈',
  'key-outline': '󰷖',
  'key-plus': '󰌉',
  'key-remove': '󰌊',
  'key-star': '󱆞',
  'key-variant': '󰌋',
  'key-wireless': '󰿂',
  keyboard: '󰌌',
  'keyboard-backspace': '󰌍',
  'keyboard-caps': '󰌎',
  'keyboard-close': '󰌏',
  'keyboard-esc': '󱊷',
  'keyboard-f1': '󱊫',
  'keyboard-f10': '󱊴',
  'keyboard-f11': '󱊵',
  'keyboard-f12': '󱊶',
  'keyboard-f2': '󱊬',
  'keyboard-f3': '󱊭',
  'keyboard-f4': '󱊮',
  'keyboard-f5': '󱊯',
  'keyboard-f6': '󱊰',
  'keyboard-f7': '󱊱',
  'keyboard-f8': '󱊲',
  'keyboard-f9': '󱊳',
  'keyboard-off': '󰌐',
  'keyboard-off-outline': '󰹋',
  'keyboard-outline': '󰥻',
  'keyboard-return': '󰌑',
  'keyboard-settings': '󰧹',
  'keyboard-settings-outline': '󰧺',
  'keyboard-space': '󱁐',
  'keyboard-tab': '󰌒',
  'keyboard-variant': '󰌓',
  khanda: '󱃽',
  kickstarter: '󰝅',
  klingon: '󱍛',
  knife: '󰧻',
  'knife-military': '󰧼',
  kodi: '󰌔',
  kubernetes: '󱃾',
  label: '󰌕',
  'label-multiple': '󱍵',
  'label-multiple-outline': '󱍶',
  'label-off': '󰫋',
  'label-off-outline': '󰫌',
  'label-outline': '󰌖',
  'label-percent': '󱋪',
  'label-percent-outline': '󱋫',
  'label-variant': '󰫍',
  'label-variant-outline': '󰫎',
  ladder: '󱖢',
  ladybug: '󰠭',
  lambda: '󰘧',
  lamp: '󰚵',
  lamps: '󱕶',
  lan: '󰌗',
  'lan-check': '󱊪',
  'lan-connect': '󰌘',
  'lan-disconnect': '󰌙',
  'lan-pending': '󰌚',
  'language-c': '󰙱',
  'language-cpp': '󰙲',
  'language-csharp': '󰌛',
  'language-css3': '󰌜',
  'language-fortran': '󱈚',
  'language-go': '󰟓',
  'language-haskell': '󰲒',
  'language-html5': '󰌝',
  'language-java': '󰬷',
  'language-javascript': '󰌞',
  'language-kotlin': '󱈙',
  'language-lua': '󰢱',
  'language-markdown': '󰍔',
  'language-markdown-outline': '󰽛',
  'language-php': '󰌟',
  'language-python': '󰌠',
  'language-r': '󰟔',
  'language-ruby': '󰴭',
  'language-ruby-on-rails': '󰫏',
  'language-rust': '󱘗',
  'language-swift': '󰛥',
  'language-typescript': '󰛦',
  'language-xaml': '󰙳',
  laptop: '󰌢',
  'laptop-chromebook': '󰌣',
  'laptop-mac': '󰌤',
  'laptop-off': '󰛧',
  'laptop-windows': '󰌥',
  laravel: '󰫐',
  'laser-pointer': '󱒄',
  lasso: '󰼃',
  lastpass: '󰑆',
  latitude: '󰽗',
  launch: '󰌧',
  'lava-lamp': '󰟕',
  layers: '󰌨',
  'layers-minus': '󰹌',
  'layers-off': '󰌩',
  'layers-off-outline': '󰧽',
  'layers-outline': '󰧾',
  'layers-plus': '󰹍',
  'layers-remove': '󰹎',
  'layers-search': '󱈆',
  'layers-search-outline': '󱈇',
  'layers-triple': '󰽘',
  'layers-triple-outline': '󰽙',
  'lead-pencil': '󰙏',
  leaf: '󰌪',
  'leaf-maple': '󰲓',
  'leaf-maple-off': '󱋚',
  'leaf-off': '󱋙',
  leak: '󰷗',
  'leak-off': '󰷘',
  'led-off': '󰌫',
  'led-on': '󰌬',
  'led-outline': '󰌭',
  'led-strip': '󰟖',
  'led-strip-variant': '󱁑',
  'led-variant-off': '󰌮',
  'led-variant-on': '󰌯',
  'led-variant-outline': '󰌰',
  leek: '󱅽',
  'less-than': '󰥼',
  'less-than-or-equal': '󰥽',
  library: '󰌱',
  'library-shelves': '󰮩',
  license: '󰿃',
  lifebuoy: '󰡾',
  'light-switch': '󰥾',
  lightbulb: '󰌵',
  'lightbulb-cfl': '󱈈',
  'lightbulb-cfl-off': '󱈉',
  'lightbulb-cfl-spiral': '󱉵',
  'lightbulb-cfl-spiral-off': '󱋃',
  'lightbulb-group': '󱉓',
  'lightbulb-group-off': '󱋍',
  'lightbulb-group-off-outline': '󱋎',
  'lightbulb-group-outline': '󱉔',
  'lightbulb-multiple': '󱉕',
  'lightbulb-multiple-off': '󱋏',
  'lightbulb-multiple-off-outline': '󱋐',
  'lightbulb-multiple-outline': '󱉖',
  'lightbulb-off': '󰹏',
  'lightbulb-off-outline': '󰹐',
  'lightbulb-on': '󰛨',
  'lightbulb-on-outline': '󰛩',
  'lightbulb-outline': '󰌶',
  lighthouse: '󰧿',
  'lighthouse-on': '󰨀',
  'lightning-bolt': '󱐋',
  'lightning-bolt-outline': '󱐌',
  lingerie: '󱑶',
  link: '󰌷',
  'link-box': '󰴚',
  'link-box-outline': '󰴛',
  'link-box-variant': '󰴜',
  'link-box-variant-outline': '󰴝',
  'link-lock': '󱂺',
  'link-off': '󰌸',
  'link-plus': '󰲔',
  'link-variant': '󰌹',
  'link-variant-minus': '󱃿',
  'link-variant-off': '󰌺',
  'link-variant-plus': '󱄀',
  'link-variant-remove': '󱄁',
  linkedin: '󰌻',
  linux: '󰌽',
  'linux-mint': '󰣭',
  lipstick: '󱎵',
  'list-status': '󱖫',
  litecoin: '󰩡',
  loading: '󰝲',
  'location-enter': '󰿄',
  'location-exit': '󰿅',
  lock: '󰌾',
  'lock-alert': '󰣮',
  'lock-alert-outline': '󱗑',
  'lock-check': '󱎚',
  'lock-check-outline': '󱚨',
  'lock-clock': '󰥿',
  'lock-minus': '󱚩',
  'lock-minus-outline': '󱚪',
  'lock-off': '󱙱',
  'lock-off-outline': '󱙲',
  'lock-open': '󰌿',
  'lock-open-alert': '󱎛',
  'lock-open-alert-outline': '󱗒',
  'lock-open-check': '󱎜',
  'lock-open-check-outline': '󱚫',
  'lock-open-minus': '󱚬',
  'lock-open-minus-outline': '󱚭',
  'lock-open-outline': '󰍀',
  'lock-open-plus': '󱚮',
  'lock-open-plus-outline': '󱚯',
  'lock-open-remove': '󱚰',
  'lock-open-remove-outline': '󱚱',
  'lock-open-variant': '󰿆',
  'lock-open-variant-outline': '󰿇',
  'lock-outline': '󰍁',
  'lock-pattern': '󰛪',
  'lock-plus': '󰗻',
  'lock-plus-outline': '󱚲',
  'lock-question': '󰣯',
  'lock-remove': '󱚳',
  'lock-remove-outline': '󱚴',
  'lock-reset': '󰝳',
  'lock-smart': '󰢲',
  locker: '󰟗',
  'locker-multiple': '󰟘',
  login: '󰍂',
  'login-variant': '󰗼',
  logout: '󰍃',
  'logout-variant': '󰗽',
  longitude: '󰽚',
  looks: '󰍄',
  lotion: '󱖂',
  'lotion-outline': '󱖃',
  'lotion-plus': '󱖄',
  'lotion-plus-outline': '󱖅',
  loupe: '󰍅',
  lumx: '󰍆',
  lungs: '󱂄',
  magnet: '󰍇',
  'magnet-on': '󰍈',
  magnify: '󰍉',
  'magnify-close': '󰦀',
  'magnify-minus': '󰍊',
  'magnify-minus-cursor': '󰩢',
  'magnify-minus-outline': '󰛬',
  'magnify-plus': '󰍋',
  'magnify-plus-cursor': '󰩣',
  'magnify-plus-outline': '󰛭',
  'magnify-remove-cursor': '󱈌',
  'magnify-remove-outline': '󱈍',
  'magnify-scan': '󱉶',
  mail: '󰺻',
  mailbox: '󰛮',
  'mailbox-open': '󰶈',
  'mailbox-open-outline': '󰶉',
  'mailbox-open-up': '󰶊',
  'mailbox-open-up-outline': '󰶋',
  'mailbox-outline': '󰶌',
  'mailbox-up': '󰶍',
  'mailbox-up-outline': '󰶎',
  manjaro: '󱘊',
  map: '󰍍',
  'map-check': '󰺼',
  'map-check-outline': '󰺽',
  'map-clock': '󰴞',
  'map-clock-outline': '󰴟',
  'map-legend': '󰨁',
  'map-marker': '󰍎',
  'map-marker-alert': '󰼅',
  'map-marker-alert-outline': '󰼆',
  'map-marker-check': '󰲕',
  'map-marker-check-outline': '󱋻',
  'map-marker-circle': '󰍏',
  'map-marker-distance': '󰣰',
  'map-marker-down': '󱄂',
  'map-marker-left': '󱋛',
  'map-marker-left-outline': '󱋝',
  'map-marker-minus': '󰙐',
  'map-marker-minus-outline': '󱋹',
  'map-marker-multiple': '󰍐',
  'map-marker-multiple-outline': '󱉷',
  'map-marker-off': '󰍑',
  'map-marker-off-outline': '󱋽',
  'map-marker-outline': '󰟙',
  'map-marker-path': '󰴠',
  'map-marker-plus': '󰙑',
  'map-marker-plus-outline': '󱋸',
  'map-marker-question': '󰼇',
  'map-marker-question-outline': '󰼈',
  'map-marker-radius': '󰍒',
  'map-marker-radius-outline': '󱋼',
  'map-marker-remove': '󰼉',
  'map-marker-remove-outline': '󱋺',
  'map-marker-remove-variant': '󰼊',
  'map-marker-right': '󱋜',
  'map-marker-right-outline': '󱋞',
  'map-marker-star': '󱘈',
  'map-marker-star-outline': '󱘉',
  'map-marker-up': '󱄃',
  'map-minus': '󰦁',
  'map-outline': '󰦂',
  'map-plus': '󰦃',
  'map-search': '󰦄',
  'map-search-outline': '󰦅',
  mapbox: '󰮪',
  margin: '󰍓',
  marker: '󰙒',
  'marker-cancel': '󰷙',
  'marker-check': '󰍕',
  mastodon: '󰫑',
  'material-design': '󰦆',
  'material-ui': '󰍗',
  'math-compass': '󰍘',
  'math-cos': '󰲖',
  'math-integral': '󰿈',
  'math-integral-box': '󰿉',
  'math-log': '󱂅',
  'math-norm': '󰿊',
  'math-norm-box': '󰿋',
  'math-sin': '󰲗',
  'math-tan': '󰲘',
  matrix: '󰘨',
  medal: '󰦇',
  'medal-outline': '󱌦',
  'medical-bag': '󰛯',
  meditation: '󱅻',
  memory: '󰍛',
  menu: '󰍜',
  'menu-down': '󰍝',
  'menu-down-outline': '󰚶',
  'menu-left': '󰍞',
  'menu-left-outline': '󰨂',
  'menu-open': '󰮫',
  'menu-right': '󰍟',
  'menu-right-outline': '󰨃',
  'menu-swap': '󰩤',
  'menu-swap-outline': '󰩥',
  'menu-up': '󰍠',
  'menu-up-outline': '󰚷',
  merge: '󰽜',
  message: '󰍡',
  'message-alert': '󰍢',
  'message-alert-outline': '󰨄',
  'message-arrow-left': '󱋲',
  'message-arrow-left-outline': '󱋳',
  'message-arrow-right': '󱋴',
  'message-arrow-right-outline': '󱋵',
  'message-bookmark': '󱖬',
  'message-bookmark-outline': '󱖭',
  'message-bulleted': '󰚢',
  'message-bulleted-off': '󰚣',
  'message-cog': '󰛱',
  'message-cog-outline': '󱅲',
  'message-draw': '󰍣',
  'message-flash': '󱖩',
  'message-flash-outline': '󱖪',
  'message-image': '󰍤',
  'message-image-outline': '󱅬',
  'message-lock': '󰿌',
  'message-lock-outline': '󱅭',
  'message-minus': '󱅮',
  'message-minus-outline': '󱅯',
  'message-off': '󱙍',
  'message-off-outline': '󱙎',
  'message-outline': '󰍥',
  'message-plus': '󰙓',
  'message-plus-outline': '󱂻',
  'message-processing': '󰍦',
  'message-processing-outline': '󱅰',
  'message-reply': '󰍧',
  'message-reply-text': '󰍨',
  'message-settings': '󰛰',
  'message-settings-outline': '󱅱',
  'message-text': '󰍩',
  'message-text-clock': '󱅳',
  'message-text-clock-outline': '󱅴',
  'message-text-lock': '󰿍',
  'message-text-lock-outline': '󱅵',
  'message-text-outline': '󰍪',
  'message-video': '󰍫',
  meteor: '󰘩',
  metronome: '󰟚',
  'metronome-tick': '󰟛',
  'micro-sd': '󰟜',
  microphone: '󰍬',
  'microphone-minus': '󰢳',
  'microphone-off': '󰍭',
  'microphone-outline': '󰍮',
  'microphone-plus': '󰢴',
  'microphone-settings': '󰍯',
  'microphone-variant': '󰍰',
  'microphone-variant-off': '󰍱',
  microscope: '󰙔',
  microsoft: '󰍲',
  'microsoft-access': '󱎎',
  'microsoft-azure': '󰠅',
  'microsoft-azure-devops': '󰿕',
  'microsoft-bing': '󰂤',
  'microsoft-dynamics-365': '󰦈',
  'microsoft-edge': '󰇩',
  'microsoft-edge-legacy': '󱉐',
  'microsoft-excel': '󱎏',
  'microsoft-internet-explorer': '󰌀',
  'microsoft-office': '󰏆',
  'microsoft-onedrive': '󰏊',
  'microsoft-onenote': '󰝇',
  'microsoft-outlook': '󰴢',
  'microsoft-powerpoint': '󱎐',
  'microsoft-sharepoint': '󱎑',
  'microsoft-teams': '󰊻',
  'microsoft-visual-studio': '󰘐',
  'microsoft-visual-studio-code': '󰨞',
  'microsoft-windows': '󰖳',
  'microsoft-windows-classic': '󰨡',
  'microsoft-word': '󱎒',
  'microsoft-xbox': '󰖹',
  'microsoft-xbox-controller': '󰖺',
  'microsoft-xbox-controller-battery-alert': '󰝋',
  'microsoft-xbox-controller-battery-charging': '󰨢',
  'microsoft-xbox-controller-battery-empty': '󰝌',
  'microsoft-xbox-controller-battery-full': '󰝍',
  'microsoft-xbox-controller-battery-low': '󰝎',
  'microsoft-xbox-controller-battery-medium': '󰝏',
  'microsoft-xbox-controller-battery-unknown': '󰝐',
  'microsoft-xbox-controller-menu': '󰹯',
  'microsoft-xbox-controller-off': '󰖻',
  'microsoft-xbox-controller-view': '󰹰',
  'microsoft-yammer': '󰞉',
  microwave: '󰲙',
  'microwave-off': '󱐣',
  middleware: '󰽝',
  'middleware-outline': '󰽞',
  midi: '󰣱',
  'midi-port': '󰣲',
  mine: '󰷚',
  minecraft: '󰍳',
  'mini-sd': '󰨅',
  minidisc: '󰨆',
  minus: '󰍴',
  'minus-box': '󰍵',
  'minus-box-multiple': '󱅁',
  'minus-box-multiple-outline': '󱅂',
  'minus-box-outline': '󰛲',
  'minus-circle': '󰍶',
  'minus-circle-multiple': '󰍚',
  'minus-circle-multiple-outline': '󰫓',
  'minus-circle-off': '󱑙',
  'minus-circle-off-outline': '󱑚',
  'minus-circle-outline': '󰍷',
  'minus-network': '󰍸',
  'minus-network-outline': '󰲚',
  'minus-thick': '󱘹',
  mirror: '󱇽',
  'mixed-martial-arts': '󰶏',
  'mixed-reality': '󰡿',
  molecule: '󰮬',
  'molecule-co': '󱋾',
  'molecule-co2': '󰟤',
  monitor: '󰍹',
  'monitor-cellphone': '󰦉',
  'monitor-cellphone-star': '󰦊',
  'monitor-clean': '󱄄',
  'monitor-dashboard': '󰨇',
  'monitor-edit': '󱋆',
  'monitor-eye': '󱎴',
  'monitor-lock': '󰷛',
  'monitor-multiple': '󰍺',
  'monitor-off': '󰶐',
  'monitor-screenshot': '󰹑',
  'monitor-share': '󱒃',
  'monitor-speaker': '󰽟',
  'monitor-speaker-off': '󰽠',
  'monitor-star': '󰷜',
  'moon-first-quarter': '󰽡',
  'moon-full': '󰽢',
  'moon-last-quarter': '󰽣',
  'moon-new': '󰽤',
  'moon-waning-crescent': '󰽥',
  'moon-waning-gibbous': '󰽦',
  'moon-waxing-crescent': '󰽧',
  'moon-waxing-gibbous': '󰽨',
  moped: '󱂆',
  'moped-electric': '󱖷',
  'moped-electric-outline': '󱖸',
  'moped-outline': '󱖹',
  more: '󰍻',
  'mother-heart': '󱌔',
  'mother-nurse': '󰴡',
  motion: '󱖲',
  'motion-outline': '󱖳',
  'motion-pause': '󱖐',
  'motion-pause-outline': '󱖒',
  'motion-play': '󱖏',
  'motion-play-outline': '󱖑',
  'motion-sensor': '󰶑',
  'motion-sensor-off': '󱐵',
  motorbike: '󰍼',
  'motorbike-electric': '󱖺',
  mouse: '󰍽',
  'mouse-bluetooth': '󰦋',
  'mouse-move-down': '󱕐',
  'mouse-move-up': '󱕑',
  'mouse-move-vertical': '󱕒',
  'mouse-off': '󰍾',
  'mouse-variant': '󰍿',
  'mouse-variant-off': '󰎀',
  'move-resize': '󰙕',
  'move-resize-variant': '󰙖',
  movie: '󰎁',
  'movie-edit': '󱄢',
  'movie-edit-outline': '󱄣',
  'movie-filter': '󱄤',
  'movie-filter-outline': '󱄥',
  'movie-open': '󰿎',
  'movie-open-outline': '󰿏',
  'movie-outline': '󰷝',
  'movie-roll': '󰟞',
  'movie-search': '󱇒',
  'movie-search-outline': '󱇓',
  mower: '󱙯',
  'mower-bag': '󱙰',
  muffin: '󰦌',
  multiplication: '󰎂',
  'multiplication-box': '󰎃',
  mushroom: '󰟟',
  'mushroom-off': '󱏺',
  'mushroom-off-outline': '󱏻',
  'mushroom-outline': '󰟠',
  music: '󰝚',
  'music-accidental-double-flat': '󰽩',
  'music-accidental-double-sharp': '󰽪',
  'music-accidental-flat': '󰽫',
  'music-accidental-natural': '󰽬',
  'music-accidental-sharp': '󰽭',
  'music-box': '󰎄',
  'music-box-multiple': '󰌳',
  'music-box-multiple-outline': '󰼄',
  'music-box-outline': '󰎅',
  'music-circle': '󰎆',
  'music-circle-outline': '󰫔',
  'music-clef-alto': '󰽮',
  'music-clef-bass': '󰽯',
  'music-clef-treble': '󰽰',
  'music-note': '󰎇',
  'music-note-bluetooth': '󰗾',
  'music-note-bluetooth-off': '󰗿',
  'music-note-eighth': '󰎈',
  'music-note-eighth-dotted': '󰽱',
  'music-note-half': '󰎉',
  'music-note-half-dotted': '󰽲',
  'music-note-off': '󰎊',
  'music-note-off-outline': '󰽳',
  'music-note-outline': '󰽴',
  'music-note-plus': '󰷞',
  'music-note-quarter': '󰎋',
  'music-note-quarter-dotted': '󰽵',
  'music-note-sixteenth': '󰎌',
  'music-note-sixteenth-dotted': '󰽶',
  'music-note-whole': '󰎍',
  'music-note-whole-dotted': '󰽷',
  'music-off': '󰝛',
  'music-rest-eighth': '󰽸',
  'music-rest-half': '󰽹',
  'music-rest-quarter': '󰽺',
  'music-rest-sixteenth': '󰽻',
  'music-rest-whole': '󰽼',
  mustache: '󱗞',
  nail: '󰷟',
  nas: '󰣳',
  nativescript: '󰢀',
  nature: '󰎎',
  'nature-people': '󰎏',
  navigation: '󰎐',
  'navigation-outline': '󱘇',
  'near-me': '󰗍',
  necklace: '󰼋',
  needle: '󰎑',
  netflix: '󰝆',
  network: '󰛳',
  'network-off': '󰲛',
  'network-off-outline': '󰲜',
  'network-outline': '󰲝',
  'network-strength-1': '󰣴',
  'network-strength-1-alert': '󰣵',
  'network-strength-2': '󰣶',
  'network-strength-2-alert': '󰣷',
  'network-strength-3': '󰣸',
  'network-strength-3-alert': '󰣹',
  'network-strength-4': '󰣺',
  'network-strength-4-alert': '󰣻',
  'network-strength-off': '󰣼',
  'network-strength-off-outline': '󰣽',
  'network-strength-outline': '󰣾',
  'new-box': '󰎔',
  newspaper: '󰎕',
  'newspaper-minus': '󰼌',
  'newspaper-plus': '󰼍',
  'newspaper-variant': '󱀁',
  'newspaper-variant-multiple': '󱀂',
  'newspaper-variant-multiple-outline': '󱀃',
  'newspaper-variant-outline': '󱀄',
  nfc: '󰎖',
  'nfc-search-variant': '󰹓',
  'nfc-tap': '󰎗',
  'nfc-variant': '󰎘',
  'nfc-variant-off': '󰹔',
  ninja: '󰝴',
  'nintendo-game-boy': '󱎓',
  'nintendo-switch': '󰟡',
  'nintendo-wii': '󰖫',
  'nintendo-wiiu': '󰜭',
  nix: '󱄅',
  nodejs: '󰎙',
  noodles: '󱅾',
  'not-equal': '󰦍',
  'not-equal-variant': '󰦎',
  note: '󰎚',
  'note-minus': '󱙏',
  'note-minus-outline': '󱙐',
  'note-multiple': '󰚸',
  'note-multiple-outline': '󰚹',
  'note-outline': '󰎛',
  'note-plus': '󰎜',
  'note-plus-outline': '󰎝',
  'note-remove': '󱙑',
  'note-remove-outline': '󱙒',
  'note-search': '󱙓',
  'note-search-outline': '󱙔',
  'note-text': '󰎞',
  'note-text-outline': '󱇗',
  notebook: '󰠮',
  'notebook-check': '󱓵',
  'notebook-check-outline': '󱓶',
  'notebook-edit': '󱓧',
  'notebook-edit-outline': '󱓩',
  'notebook-minus': '󱘐',
  'notebook-minus-outline': '󱘑',
  'notebook-multiple': '󰹕',
  'notebook-outline': '󰺿',
  'notebook-plus': '󱘒',
  'notebook-plus-outline': '󱘓',
  'notebook-remove': '󱘔',
  'notebook-remove-outline': '󱘕',
  'notification-clear-all': '󰎟',
  npm: '󰛷',
  nuke: '󰚤',
  null: '󰟢',
  numeric: '󰎠',
  'numeric-0': '󰬹',
  'numeric-0-box': '󰎡',
  'numeric-0-box-multiple': '󰼎',
  'numeric-0-box-multiple-outline': '󰎢',
  'numeric-0-box-outline': '󰎣',
  'numeric-0-circle': '󰲞',
  'numeric-0-circle-outline': '󰲟',
  'numeric-1': '󰬺',
  'numeric-1-box': '󰎤',
  'numeric-1-box-multiple': '󰼏',
  'numeric-1-box-multiple-outline': '󰎥',
  'numeric-1-box-outline': '󰎦',
  'numeric-1-circle': '󰲠',
  'numeric-1-circle-outline': '󰲡',
  'numeric-10': '󰿩',
  'numeric-10-box': '󰽽',
  'numeric-10-box-multiple': '󰿪',
  'numeric-10-box-multiple-outline': '󰿫',
  'numeric-10-box-outline': '󰽾',
  'numeric-10-circle': '󰿬',
  'numeric-10-circle-outline': '󰿭',
  'numeric-2': '󰬻',
  'numeric-2-box': '󰎧',
  'numeric-2-box-multiple': '󰼐',
  'numeric-2-box-multiple-outline': '󰎨',
  'numeric-2-box-outline': '󰎩',
  'numeric-2-circle': '󰲢',
  'numeric-2-circle-outline': '󰲣',
  'numeric-3': '󰬼',
  'numeric-3-box': '󰎪',
  'numeric-3-box-multiple': '󰼑',
  'numeric-3-box-multiple-outline': '󰎫',
  'numeric-3-box-outline': '󰎬',
  'numeric-3-circle': '󰲤',
  'numeric-3-circle-outline': '󰲥',
  'numeric-4': '󰬽',
  'numeric-4-box': '󰎭',
  'numeric-4-box-multiple': '󰼒',
  'numeric-4-box-multiple-outline': '󰎲',
  'numeric-4-box-outline': '󰎮',
  'numeric-4-circle': '󰲦',
  'numeric-4-circle-outline': '󰲧',
  'numeric-5': '󰬾',
  'numeric-5-box': '󰎱',
  'numeric-5-box-multiple': '󰼓',
  'numeric-5-box-multiple-outline': '󰎯',
  'numeric-5-box-outline': '󰎰',
  'numeric-5-circle': '󰲨',
  'numeric-5-circle-outline': '󰲩',
  'numeric-6': '󰬿',
  'numeric-6-box': '󰎳',
  'numeric-6-box-multiple': '󰼔',
  'numeric-6-box-multiple-outline': '󰎴',
  'numeric-6-box-outline': '󰎵',
  'numeric-6-circle': '󰲪',
  'numeric-6-circle-outline': '󰲫',
  'numeric-7': '󰭀',
  'numeric-7-box': '󰎶',
  'numeric-7-box-multiple': '󰼕',
  'numeric-7-box-multiple-outline': '󰎷',
  'numeric-7-box-outline': '󰎸',
  'numeric-7-circle': '󰲬',
  'numeric-7-circle-outline': '󰲭',
  'numeric-8': '󰭁',
  'numeric-8-box': '󰎹',
  'numeric-8-box-multiple': '󰼖',
  'numeric-8-box-multiple-outline': '󰎺',
  'numeric-8-box-outline': '󰎻',
  'numeric-8-circle': '󰲮',
  'numeric-8-circle-outline': '󰲯',
  'numeric-9': '󰭂',
  'numeric-9-box': '󰎼',
  'numeric-9-box-multiple': '󰼗',
  'numeric-9-box-multiple-outline': '󰎽',
  'numeric-9-box-outline': '󰎾',
  'numeric-9-circle': '󰲰',
  'numeric-9-circle-outline': '󰲱',
  'numeric-9-plus': '󰿮',
  'numeric-9-plus-box': '󰎿',
  'numeric-9-plus-box-multiple': '󰼘',
  'numeric-9-plus-box-multiple-outline': '󰏀',
  'numeric-9-plus-box-outline': '󰏁',
  'numeric-9-plus-circle': '󰲲',
  'numeric-9-plus-circle-outline': '󰲳',
  'numeric-negative-1': '󱁒',
  'numeric-positive-1': '󱗋',
  nut: '󰛸',
  nutrition: '󰏂',
  nuxt: '󱄆',
  oar: '󰙼',
  ocarina: '󰷠',
  oci: '󱋩',
  ocr: '󱄺',
  octagon: '󰏃',
  'octagon-outline': '󰏄',
  octagram: '󰛹',
  'octagram-outline': '󰝵',
  odnoklassniki: '󰏅',
  offer: '󱈛',
  'office-building': '󰦑',
  'office-building-marker': '󱔠',
  'office-building-marker-outline': '󱔡',
  'office-building-outline': '󱔟',
  oil: '󰏇',
  'oil-lamp': '󰼙',
  'oil-level': '󱁓',
  'oil-temperature': '󰿸',
  omega: '󰏉',
  'one-up': '󰮭',
  onepassword: '󰢁',
  opacity: '󰗌',
  'open-in-app': '󰏋',
  'open-in-new': '󰏌',
  'open-source-initiative': '󰮮',
  openid: '󰏍',
  opera: '󰏎',
  orbit: '󰀘',
  'orbit-variant': '󱗛',
  'order-alphabetical-ascending': '󰈍',
  'order-alphabetical-descending': '󰴇',
  'order-bool-ascending': '󰊾',
  'order-bool-ascending-variant': '󰦏',
  'order-bool-descending': '󱎄',
  'order-bool-descending-variant': '󰦐',
  'order-numeric-ascending': '󰕅',
  'order-numeric-descending': '󰕆',
  origin: '󰭃',
  ornament: '󰏏',
  'ornament-variant': '󰏐',
  'outdoor-lamp': '󱁔',
  overscan: '󱀅',
  owl: '󰏒',
  'pac-man': '󰮯',
  package: '󰏓',
  'package-down': '󰏔',
  'package-up': '󰏕',
  'package-variant': '󰏖',
  'package-variant-closed': '󰏗',
  'page-first': '󰘀',
  'page-last': '󰘁',
  'page-layout-body': '󰛺',
  'page-layout-footer': '󰛻',
  'page-layout-header': '󰛼',
  'page-layout-header-footer': '󰽿',
  'page-layout-sidebar-left': '󰛽',
  'page-layout-sidebar-right': '󰛾',
  'page-next': '󰮰',
  'page-next-outline': '󰮱',
  'page-previous': '󰮲',
  'page-previous-outline': '󰮳',
  pail: '󱐗',
  'pail-minus': '󱐷',
  'pail-minus-outline': '󱐼',
  'pail-off': '󱐹',
  'pail-off-outline': '󱐾',
  'pail-outline': '󱐺',
  'pail-plus': '󱐶',
  'pail-plus-outline': '󱐻',
  'pail-remove': '󱐸',
  'pail-remove-outline': '󱐽',
  palette: '󰏘',
  'palette-advanced': '󰏙',
  'palette-outline': '󰸌',
  'palette-swatch': '󰢵',
  'palette-swatch-outline': '󱍜',
  'palm-tree': '󱁕',
  pan: '󰮴',
  'pan-bottom-left': '󰮵',
  'pan-bottom-right': '󰮶',
  'pan-down': '󰮷',
  'pan-horizontal': '󰮸',
  'pan-left': '󰮹',
  'pan-right': '󰮺',
  'pan-top-left': '󰮻',
  'pan-top-right': '󰮼',
  'pan-up': '󰮽',
  'pan-vertical': '󰮾',
  panda: '󰏚',
  pandora: '󰏛',
  panorama: '󰏜',
  'panorama-fisheye': '󰏝',
  'panorama-horizontal': '󰏞',
  'panorama-vertical': '󰏟',
  'panorama-wide-angle': '󰏠',
  'paper-cut-vertical': '󰏡',
  'paper-roll': '󱅗',
  'paper-roll-outline': '󱅘',
  paperclip: '󰏢',
  parachute: '󰲴',
  'parachute-outline': '󰲵',
  parking: '󰏣',
  'party-popper': '󱁖',
  passport: '󰟣',
  'passport-biometric': '󰷡',
  pasta: '󱅠',
  'patio-heater': '󰾀',
  patreon: '󰢂',
  pause: '󰏤',
  'pause-circle': '󰏥',
  'pause-circle-outline': '󰏦',
  'pause-octagon': '󰏧',
  'pause-octagon-outline': '󰏨',
  paw: '󰏩',
  'paw-off': '󰙗',
  'paw-off-outline': '󱙶',
  'paw-outline': '󱙵',
  'pdf-box': '󰹖',
  peace: '󰢄',
  peanut: '󰿼',
  'peanut-off': '󰿽',
  'peanut-off-outline': '󰿿',
  'peanut-outline': '󰿾',
  pen: '󰏪',
  'pen-lock': '󰷢',
  'pen-minus': '󰷣',
  'pen-off': '󰷤',
  'pen-plus': '󰷥',
  'pen-remove': '󰷦',
  pencil: '󰏫',
  'pencil-box': '󰏬',
  'pencil-box-multiple': '󱅄',
  'pencil-box-multiple-outline': '󱅅',
  'pencil-box-outline': '󰏭',
  'pencil-circle': '󰛿',
  'pencil-circle-outline': '󰝶',
  'pencil-lock': '󰏮',
  'pencil-lock-outline': '󰷧',
  'pencil-minus': '󰷨',
  'pencil-minus-outline': '󰷩',
  'pencil-off': '󰏯',
  'pencil-off-outline': '󰷪',
  'pencil-outline': '󰲶',
  'pencil-plus': '󰷫',
  'pencil-plus-outline': '󰷬',
  'pencil-remove': '󰷭',
  'pencil-remove-outline': '󰷮',
  'pencil-ruler': '󱍓',
  penguin: '󰻀',
  pentagon: '󰜁',
  'pentagon-outline': '󰜀',
  pentagram: '󱙧',
  percent: '󰏰',
  'percent-outline': '󱉸',
  'periodic-table': '󰢶',
  'perspective-less': '󰴣',
  'perspective-more': '󰴤',
  pharmacy: '󰏱',
  phone: '󰏲',
  'phone-alert': '󰼚',
  'phone-alert-outline': '󱆎',
  'phone-bluetooth': '󰏳',
  'phone-bluetooth-outline': '󱆏',
  'phone-cancel': '󱂼',
  'phone-cancel-outline': '󱆐',
  'phone-check': '󱆩',
  'phone-check-outline': '󱆪',
  'phone-classic': '󰘂',
  'phone-classic-off': '󱉹',
  'phone-dial': '󱕙',
  'phone-dial-outline': '󱕚',
  'phone-forward': '󰏴',
  'phone-forward-outline': '󱆑',
  'phone-hangup': '󰏵',
  'phone-hangup-outline': '󱆒',
  'phone-in-talk': '󰏶',
  'phone-in-talk-outline': '󱆂',
  'phone-incoming': '󰏷',
  'phone-incoming-outline': '󱆓',
  'phone-lock': '󰏸',
  'phone-lock-outline': '󱆔',
  'phone-log': '󰏹',
  'phone-log-outline': '󱆕',
  'phone-message': '󱆖',
  'phone-message-outline': '󱆗',
  'phone-minus': '󰙘',
  'phone-minus-outline': '󱆘',
  'phone-missed': '󰏺',
  'phone-missed-outline': '󱆥',
  'phone-off': '󰷯',
  'phone-off-outline': '󱆦',
  'phone-outgoing': '󰏻',
  'phone-outgoing-outline': '󱆙',
  'phone-outline': '󰷰',
  'phone-paused': '󰏼',
  'phone-paused-outline': '󱆚',
  'phone-plus': '󰙙',
  'phone-plus-outline': '󱆛',
  'phone-remove': '󱔯',
  'phone-remove-outline': '󱔰',
  'phone-return': '󰠯',
  'phone-return-outline': '󱆜',
  'phone-ring': '󱆫',
  'phone-ring-outline': '󱆬',
  'phone-rotate-landscape': '󰢅',
  'phone-rotate-portrait': '󰢆',
  'phone-settings': '󰏽',
  'phone-settings-outline': '󱆝',
  'phone-voip': '󰏾',
  pi: '󰏿',
  'pi-box': '󰐀',
  'pi-hole': '󰷱',
  piano: '󰙽',
  pickaxe: '󰢷',
  'picture-in-picture-bottom-right': '󰹗',
  'picture-in-picture-bottom-right-outline': '󰹘',
  'picture-in-picture-top-right': '󰹙',
  'picture-in-picture-top-right-outline': '󰹚',
  pier: '󰢇',
  'pier-crane': '󰢈',
  pig: '󰐁',
  'pig-variant': '󱀆',
  'pig-variant-outline': '󱙸',
  'piggy-bank': '󱀇',
  'piggy-bank-outline': '󱙹',
  pill: '󰐂',
  pillar: '󰜂',
  pin: '󰐃',
  'pin-off': '󰐄',
  'pin-off-outline': '󰤰',
  'pin-outline': '󰤱',
  'pine-tree': '󰐅',
  'pine-tree-box': '󰐆',
  'pine-tree-fire': '󱐚',
  pinterest: '󰐇',
  pinwheel: '󰫕',
  'pinwheel-outline': '󰫖',
  pipe: '󰟥',
  'pipe-disconnected': '󰟦',
  'pipe-leak': '󰢉',
  'pipe-wrench': '󱍔',
  pirate: '󰨈',
  pistol: '󰜃',
  piston: '󰢊',
  pitchfork: '󱕓',
  pizza: '󰐉',
  play: '󰐊',
  'play-box': '󱉺',
  'play-box-multiple': '󰴙',
  'play-box-multiple-outline': '󱏦',
  'play-box-outline': '󰐋',
  'play-circle': '󰐌',
  'play-circle-outline': '󰐍',
  'play-network': '󰢋',
  'play-network-outline': '󰲷',
  'play-outline': '󰼛',
  'play-pause': '󰐎',
  'play-protected-content': '󰐏',
  'play-speed': '󰣿',
  'playlist-check': '󰗇',
  'playlist-edit': '󰤀',
  'playlist-minus': '󰐐',
  'playlist-music': '󰲸',
  'playlist-music-outline': '󰲹',
  'playlist-play': '󰐑',
  'playlist-plus': '󰐒',
  'playlist-remove': '󰐓',
  'playlist-star': '󰷲',
  plex: '󰚺',
  plus: '󰐕',
  'plus-box': '󰐖',
  'plus-box-multiple': '󰌴',
  'plus-box-multiple-outline': '󱅃',
  'plus-box-outline': '󰜄',
  'plus-circle': '󰐗',
  'plus-circle-multiple': '󰍌',
  'plus-circle-multiple-outline': '󰐘',
  'plus-circle-outline': '󰐙',
  'plus-minus': '󰦒',
  'plus-minus-box': '󰦓',
  'plus-minus-variant': '󱓉',
  'plus-network': '󰐚',
  'plus-network-outline': '󰲺',
  'plus-one': '󰐛',
  'plus-outline': '󰜅',
  'plus-thick': '󱇬',
  podcast: '󰦔',
  podium: '󰴥',
  'podium-bronze': '󰴦',
  'podium-gold': '󰴧',
  'podium-silver': '󰴨',
  'point-of-sale': '󰶒',
  pokeball: '󰐝',
  'pokemon-go': '󰨉',
  'poker-chip': '󰠰',
  polaroid: '󰐞',
  'police-badge': '󱅧',
  'police-badge-outline': '󱅨',
  poll: '󰐟',
  'poll-box': '󰐠',
  'poll-box-outline': '󱉻',
  polo: '󱓃',
  polymer: '󰐡',
  pool: '󰘆',
  popcorn: '󰐢',
  post: '󱀈',
  'post-outline': '󱀉',
  'postage-stamp': '󰲻',
  pot: '󰋥',
  'pot-mix': '󰙛',
  'pot-mix-outline': '󰙷',
  'pot-outline': '󰋿',
  'pot-steam': '󰙚',
  'pot-steam-outline': '󰌦',
  pound: '󰐣',
  'pound-box': '󰐤',
  'pound-box-outline': '󱅿',
  power: '󰐥',
  'power-cycle': '󰤁',
  'power-off': '󰤂',
  'power-on': '󰤃',
  'power-plug': '󰚥',
  'power-plug-off': '󰚦',
  'power-plug-off-outline': '󱐤',
  'power-plug-outline': '󱐥',
  'power-settings': '󰐦',
  'power-sleep': '󰤄',
  'power-socket': '󰐧',
  'power-socket-au': '󰤅',
  'power-socket-de': '󱄇',
  'power-socket-eu': '󰟧',
  'power-socket-fr': '󱄈',
  'power-socket-it': '󱓿',
  'power-socket-jp': '󱄉',
  'power-socket-uk': '󰟨',
  'power-socket-us': '󰟩',
  'power-standby': '󰤆',
  powershell: '󰨊',
  prescription: '󰜆',
  presentation: '󰐨',
  'presentation-play': '󰐩',
  pretzel: '󱕢',
  printer: '󰐪',
  'printer-3d': '󰐫',
  'printer-3d-nozzle': '󰹛',
  'printer-3d-nozzle-alert': '󱇀',
  'printer-3d-nozzle-alert-outline': '󱇁',
  'printer-3d-nozzle-outline': '󰹜',
  'printer-alert': '󰐬',
  'printer-check': '󱅆',
  'printer-eye': '󱑘',
  'printer-off': '󰹝',
  'printer-pos': '󱁗',
  'printer-search': '󱑗',
  'printer-settings': '󰜇',
  'printer-wireless': '󰨋',
  'priority-high': '󰘃',
  'priority-low': '󰘄',
  'professional-hexagon': '󰐭',
  'progress-alert': '󰲼',
  'progress-check': '󰦕',
  'progress-clock': '󰦖',
  'progress-close': '󱄊',
  'progress-download': '󰦗',
  'progress-question': '󱔢',
  'progress-upload': '󰦘',
  'progress-wrench': '󰲽',
  projector: '󰐮',
  'projector-screen': '󰐯',
  'propane-tank': '󱍗',
  'propane-tank-outline': '󱍘',
  protocol: '󰿘',
  publish: '󰚧',
  pulse: '󰐰',
  pump: '󱐂',
  pumpkin: '󰮿',
  purse: '󰼜',
  'purse-outline': '󰼝',
  puzzle: '󰐱',
  'puzzle-check': '󱐦',
  'puzzle-check-outline': '󱐧',
  'puzzle-edit': '󱓓',
  'puzzle-edit-outline': '󱓙',
  'puzzle-heart': '󱓔',
  'puzzle-heart-outline': '󱓚',
  'puzzle-minus': '󱓑',
  'puzzle-minus-outline': '󱓗',
  'puzzle-outline': '󰩦',
  'puzzle-plus': '󱓐',
  'puzzle-plus-outline': '󱓖',
  'puzzle-remove': '󱓒',
  'puzzle-remove-outline': '󱓘',
  'puzzle-star': '󱓕',
  'puzzle-star-outline': '󱓛',
  qi: '󰦙',
  qqchat: '󰘅',
  qrcode: '󰐲',
  'qrcode-edit': '󰢸',
  'qrcode-minus': '󱆌',
  'qrcode-plus': '󱆋',
  'qrcode-remove': '󱆍',
  'qrcode-scan': '󰐳',
  quadcopter: '󰐴',
  'quality-high': '󰐵',
  'quality-low': '󰨌',
  'quality-medium': '󰨍',
  quora: '󰴩',
  rabbit: '󰤇',
  'racing-helmet': '󰶓',
  racquetball: '󰶔',
  radar: '󰐷',
  radiator: '󰐸',
  'radiator-disabled': '󰫗',
  'radiator-off': '󰫘',
  radio: '󰐹',
  'radio-am': '󰲾',
  'radio-fm': '󰲿',
  'radio-handheld': '󰐺',
  'radio-off': '󱈜',
  'radio-tower': '󰐻',
  radioactive: '󰐼',
  'radioactive-off': '󰻁',
  'radiobox-blank': '󰐽',
  'radiobox-marked': '󰐾',
  'radiology-box': '󱓅',
  'radiology-box-outline': '󱓆',
  radius: '󰳀',
  'radius-outline': '󰳁',
  'railroad-light': '󰼞',
  rake: '󱕄',
  'raspberry-pi': '󰐿',
  'ray-end': '󰑀',
  'ray-end-arrow': '󰑁',
  'ray-start': '󰑂',
  'ray-start-arrow': '󰑃',
  'ray-start-end': '󰑄',
  'ray-start-vertex-end': '󱗘',
  'ray-vertex': '󰑅',
  react: '󰜈',
  read: '󰑇',
  receipt: '󰑉',
  record: '󰑊',
  'record-circle': '󰻂',
  'record-circle-outline': '󰻃',
  'record-player': '󰦚',
  'record-rec': '󰑋',
  rectangle: '󰹞',
  'rectangle-outline': '󰹟',
  recycle: '󰑌',
  'recycle-variant': '󱎝',
  reddit: '󰑍',
  redhat: '󱄛',
  redo: '󰑎',
  'redo-variant': '󰑏',
  'reflect-horizontal': '󰨎',
  'reflect-vertical': '󰨏',
  refresh: '󰑐',
  'refresh-circle': '󱍷',
  regex: '󰑑',
  'registered-trademark': '󰩧',
  reiterate: '󱖈',
  'relation-many-to-many': '󱒖',
  'relation-many-to-one': '󱒗',
  'relation-many-to-one-or-many': '󱒘',
  'relation-many-to-only-one': '󱒙',
  'relation-many-to-zero-or-many': '󱒚',
  'relation-many-to-zero-or-one': '󱒛',
  'relation-one-or-many-to-many': '󱒜',
  'relation-one-or-many-to-one': '󱒝',
  'relation-one-or-many-to-one-or-many': '󱒞',
  'relation-one-or-many-to-only-one': '󱒟',
  'relation-one-or-many-to-zero-or-many': '󱒠',
  'relation-one-or-many-to-zero-or-one': '󱒡',
  'relation-one-to-many': '󱒢',
  'relation-one-to-one': '󱒣',
  'relation-one-to-one-or-many': '󱒤',
  'relation-one-to-only-one': '󱒥',
  'relation-one-to-zero-or-many': '󱒦',
  'relation-one-to-zero-or-one': '󱒧',
  'relation-only-one-to-many': '󱒨',
  'relation-only-one-to-one': '󱒩',
  'relation-only-one-to-one-or-many': '󱒪',
  'relation-only-one-to-only-one': '󱒫',
  'relation-only-one-to-zero-or-many': '󱒬',
  'relation-only-one-to-zero-or-one': '󱒭',
  'relation-zero-or-many-to-many': '󱒮',
  'relation-zero-or-many-to-one': '󱒯',
  'relation-zero-or-many-to-one-or-many': '󱒰',
  'relation-zero-or-many-to-only-one': '󱒱',
  'relation-zero-or-many-to-zero-or-many': '󱒲',
  'relation-zero-or-many-to-zero-or-one': '󱒳',
  'relation-zero-or-one-to-many': '󱒴',
  'relation-zero-or-one-to-one': '󱒵',
  'relation-zero-or-one-to-one-or-many': '󱒶',
  'relation-zero-or-one-to-only-one': '󱒷',
  'relation-zero-or-one-to-zero-or-many': '󱒸',
  'relation-zero-or-one-to-zero-or-one': '󱒹',
  'relative-scale': '󰑒',
  reload: '󰑓',
  'reload-alert': '󱄋',
  reminder: '󰢌',
  remote: '󰑔',
  'remote-desktop': '󰢹',
  'remote-off': '󰻄',
  'remote-tv': '󰻅',
  'remote-tv-off': '󰻆',
  'rename-box': '󰑕',
  'reorder-horizontal': '󰚈',
  'reorder-vertical': '󰚉',
  repeat: '󰑖',
  'repeat-off': '󰑗',
  'repeat-once': '󰑘',
  replay: '󰑙',
  reply: '󰑚',
  'reply-all': '󰑛',
  'reply-all-outline': '󰼟',
  'reply-circle': '󱆮',
  'reply-outline': '󰼠',
  reproduction: '󰑜',
  resistor: '󰭄',
  'resistor-nodes': '󰭅',
  resize: '󰩨',
  'resize-bottom-right': '󰑝',
  responsive: '󰑞',
  restart: '󰜉',
  'restart-alert': '󱄌',
  'restart-off': '󰶕',
  restore: '󰦛',
  'restore-alert': '󱄍',
  rewind: '󰑟',
  'rewind-10': '󰴪',
  'rewind-30': '󰶖',
  'rewind-5': '󱇹',
  'rewind-60': '󱘌',
  'rewind-outline': '󰜊',
  rhombus: '󰜋',
  'rhombus-medium': '󰨐',
  'rhombus-medium-outline': '󱓜',
  'rhombus-outline': '󰜌',
  'rhombus-split': '󰨑',
  'rhombus-split-outline': '󱓝',
  ribbon: '󰑠',
  rice: '󰟪',
  rickshaw: '󱖻',
  'rickshaw-electric': '󱖼',
  ring: '󰟫',
  rivet: '󰹠',
  road: '󰑡',
  'road-variant': '󰑢',
  robber: '󱁘',
  robot: '󰚩',
  'robot-angry': '󱚝',
  'robot-angry-outline': '󱚞',
  'robot-confused': '󱚟',
  'robot-confused-outline': '󱚠',
  'robot-dead': '󱚡',
  'robot-dead-outline': '󱚢',
  'robot-excited': '󱚣',
  'robot-excited-outline': '󱚤',
  'robot-industrial': '󰭆',
  'robot-love': '󱚥',
  'robot-love-outline': '󱚦',
  'robot-mower': '󱇷',
  'robot-mower-outline': '󱇳',
  'robot-off': '󱚧',
  'robot-off-outline': '󱙻',
  'robot-outline': '󱙺',
  'robot-vacuum': '󰜍',
  'robot-vacuum-variant': '󰤈',
  rocket: '󰑣',
  'rocket-launch': '󱓞',
  'rocket-launch-outline': '󱓟',
  'rocket-outline': '󱎯',
  rodent: '󱌧',
  'roller-skate': '󰴫',
  'roller-skate-off': '󰅅',
  rollerblade: '󰴬',
  'rollerblade-off': '󰀮',
  rollupjs: '󰯀',
  'roman-numeral-1': '󱂈',
  'roman-numeral-10': '󱂑',
  'roman-numeral-2': '󱂉',
  'roman-numeral-3': '󱂊',
  'roman-numeral-4': '󱂋',
  'roman-numeral-5': '󱂌',
  'roman-numeral-6': '󱂍',
  'roman-numeral-7': '󱂎',
  'roman-numeral-8': '󱂏',
  'roman-numeral-9': '󱂐',
  'room-service': '󰢍',
  'room-service-outline': '󰶗',
  'rotate-3d': '󰻇',
  'rotate-3d-variant': '󰑤',
  'rotate-left': '󰑥',
  'rotate-left-variant': '󰑦',
  'rotate-orbit': '󰶘',
  'rotate-right': '󰑧',
  'rotate-right-variant': '󰑨',
  'rounded-corner': '󰘇',
  router: '󱇢',
  'router-network': '󱂇',
  'router-wireless': '󰑩',
  'router-wireless-off': '󱖣',
  'router-wireless-settings': '󰩩',
  routes: '󰑪',
  'routes-clock': '󱁙',
  rowing: '󰘈',
  rss: '󰑫',
  'rss-box': '󰑬',
  'rss-off': '󰼡',
  rug: '󱑵',
  rugby: '󰶙',
  ruler: '󰑭',
  'ruler-square': '󰳂',
  'ruler-square-compass': '󰺾',
  run: '󰜎',
  'run-fast': '󰑮',
  'rv-truck': '󱇔',
  sack: '󰴮',
  'sack-percent': '󰴯',
  safe: '󰩪',
  'safe-square': '󱉼',
  'safe-square-outline': '󱉽',
  'safety-goggles': '󰴰',
  'sail-boat': '󰻈',
  sale: '󰑯',
  salesforce: '󰢎',
  sass: '󰟬',
  satellite: '󰑰',
  'satellite-uplink': '󰤉',
  'satellite-variant': '󰑱',
  sausage: '󰢺',
  'saw-blade': '󰹡',
  'sawtooth-wave': '󱑺',
  saxophone: '󰘉',
  scale: '󰑲',
  'scale-balance': '󰗑',
  'scale-bathroom': '󰑳',
  'scale-off': '󱁚',
  'scan-helper': '󱏘',
  scanner: '󰚫',
  'scanner-off': '󰤊',
  'scatter-plot': '󰻉',
  'scatter-plot-outline': '󰻊',
  school: '󰑴',
  'school-outline': '󱆀',
  'scissors-cutting': '󰩫',
  scooter: '󱖽',
  'scooter-electric': '󱖾',
  scoreboard: '󱉾',
  'scoreboard-outline': '󱉿',
  'screen-rotation': '󰑵',
  'screen-rotation-lock': '󰑸',
  'screw-flat-top': '󰷳',
  'screw-lag': '󰷴',
  'screw-machine-flat-top': '󰷵',
  'screw-machine-round-top': '󰷶',
  'screw-round-top': '󰷷',
  screwdriver: '󰑶',
  script: '󰯁',
  'script-outline': '󰑷',
  'script-text': '󰯂',
  'script-text-outline': '󰯃',
  sd: '󰑹',
  seal: '󰑺',
  'seal-variant': '󰿙',
  'search-web': '󰜏',
  seat: '󰳃',
  'seat-flat': '󰑻',
  'seat-flat-angled': '󰑼',
  'seat-individual-suite': '󰑽',
  'seat-legroom-extra': '󰑾',
  'seat-legroom-normal': '󰑿',
  'seat-legroom-reduced': '󰒀',
  'seat-outline': '󰳄',
  'seat-passenger': '󱉉',
  'seat-recline-extra': '󰒁',
  'seat-recline-normal': '󰒂',
  seatbelt: '󰳅',
  security: '󰒃',
  'security-network': '󰒄',
  seed: '󰹢',
  'seed-off': '󱏽',
  'seed-off-outline': '󱏾',
  'seed-outline': '󰹣',
  seesaw: '󱖤',
  segment: '󰻋',
  select: '󰒅',
  'select-all': '󰒆',
  'select-color': '󰴱',
  'select-compare': '󰫙',
  'select-drag': '󰩬',
  'select-group': '󰾂',
  'select-inverse': '󰒇',
  'select-marker': '󱊀',
  'select-multiple': '󱊁',
  'select-multiple-marker': '󱊂',
  'select-off': '󰒈',
  'select-place': '󰿚',
  'select-search': '󱈄',
  selection: '󰒉',
  'selection-drag': '󰩭',
  'selection-ellipse': '󰴲',
  'selection-ellipse-arrow-inside': '󰼢',
  'selection-marker': '󱊃',
  'selection-multiple': '󱊅',
  'selection-multiple-marker': '󱊄',
  'selection-off': '󰝷',
  'selection-search': '󱈅',
  'semantic-web': '󱌖',
  send: '󰒊',
  'send-check': '󱅡',
  'send-check-outline': '󱅢',
  'send-circle': '󰷸',
  'send-circle-outline': '󰷹',
  'send-clock': '󱅣',
  'send-clock-outline': '󱅤',
  'send-lock': '󰟭',
  'send-lock-outline': '󱅦',
  'send-outline': '󱅥',
  'serial-port': '󰙜',
  server: '󰒋',
  'server-minus': '󰒌',
  'server-network': '󰒍',
  'server-network-off': '󰒎',
  'server-off': '󰒏',
  'server-plus': '󰒐',
  'server-remove': '󰒑',
  'server-security': '󰒒',
  'set-all': '󰝸',
  'set-center': '󰝹',
  'set-center-right': '󰝺',
  'set-left': '󰝻',
  'set-left-center': '󰝼',
  'set-left-right': '󰝽',
  'set-merge': '󱓠',
  'set-none': '󰝾',
  'set-right': '󰝿',
  'set-split': '󱓡',
  'set-square': '󱑝',
  'set-top-box': '󰦟',
  'settings-helper': '󰩮',
  shaker: '󱄎',
  'shaker-outline': '󱄏',
  shape: '󰠱',
  'shape-circle-plus': '󰙝',
  'shape-outline': '󰠲',
  'shape-oval-plus': '󱇺',
  'shape-plus': '󰒕',
  'shape-polygon-plus': '󰙞',
  'shape-rectangle-plus': '󰙟',
  'shape-square-plus': '󰙠',
  'shape-square-rounded-plus': '󱓺',
  share: '󰒖',
  'share-all': '󱇴',
  'share-all-outline': '󱇵',
  'share-circle': '󱆭',
  'share-off': '󰼣',
  'share-off-outline': '󰼤',
  'share-outline': '󰤲',
  'share-variant': '󰒗',
  'share-variant-outline': '󱔔',
  'shark-fin': '󱙳',
  'shark-fin-outline': '󱙴',
  sheep: '󰳆',
  shield: '󰒘',
  'shield-account': '󰢏',
  'shield-account-outline': '󰨒',
  'shield-account-variant': '󱖧',
  'shield-account-variant-outline': '󱖨',
  'shield-airplane': '󰚻',
  'shield-airplane-outline': '󰳇',
  'shield-alert': '󰻌',
  'shield-alert-outline': '󰻍',
  'shield-bug': '󱏚',
  'shield-bug-outline': '󱏛',
  'shield-car': '󰾃',
  'shield-check': '󰕥',
  'shield-check-outline': '󰳈',
  'shield-cross': '󰳉',
  'shield-cross-outline': '󰳊',
  'shield-edit': '󱆠',
  'shield-edit-outline': '󱆡',
  'shield-half': '󱍠',
  'shield-half-full': '󰞀',
  'shield-home': '󰚊',
  'shield-home-outline': '󰳋',
  'shield-key': '󰯄',
  'shield-key-outline': '󰯅',
  'shield-link-variant': '󰴳',
  'shield-link-variant-outline': '󰴴',
  'shield-lock': '󰦝',
  'shield-lock-outline': '󰳌',
  'shield-off': '󰦞',
  'shield-off-outline': '󰦜',
  'shield-outline': '󰒙',
  'shield-plus': '󰫚',
  'shield-plus-outline': '󰫛',
  'shield-refresh': '󰂪',
  'shield-refresh-outline': '󰇠',
  'shield-remove': '󰫜',
  'shield-remove-outline': '󰫝',
  'shield-search': '󰶚',
  'shield-star': '󱄻',
  'shield-star-outline': '󱄼',
  'shield-sun': '󱁝',
  'shield-sun-outline': '󱁞',
  'shield-sync': '󱆢',
  'shield-sync-outline': '󱆣',
  'ship-wheel': '󰠳',
  'shoe-ballet': '󱗊',
  'shoe-cleat': '󱗇',
  'shoe-formal': '󰭇',
  'shoe-heel': '󰭈',
  'shoe-print': '󰷺',
  'shoe-sneaker': '󱗈',
  shopping: '󰒚',
  'shopping-music': '󰒛',
  'shopping-outline': '󱇕',
  'shopping-search': '󰾄',
  shore: '󱓹',
  shovel: '󰜐',
  'shovel-off': '󰜑',
  shower: '󰦠',
  'shower-head': '󰦡',
  shredder: '󰒜',
  shuffle: '󰒝',
  'shuffle-disabled': '󰒞',
  'shuffle-variant': '󰒟',
  shuriken: '󱍿',
  sigma: '󰒠',
  'sigma-lower': '󰘫',
  'sign-caution': '󰒡',
  'sign-direction': '󰞁',
  'sign-direction-minus': '󱀀',
  'sign-direction-plus': '󰿜',
  'sign-direction-remove': '󰿝',
  'sign-pole': '󱓸',
  'sign-real-estate': '󱄘',
  'sign-text': '󰞂',
  signal: '󰒢',
  'signal-2g': '󰜒',
  'signal-3g': '󰜓',
  'signal-4g': '󰜔',
  'signal-5g': '󰩯',
  'signal-cellular-1': '󰢼',
  'signal-cellular-2': '󰢽',
  'signal-cellular-3': '󰢾',
  'signal-cellular-outline': '󰢿',
  'signal-distance-variant': '󰹤',
  'signal-hspa': '󰜕',
  'signal-hspa-plus': '󰜖',
  'signal-off': '󰞃',
  'signal-variant': '󰘊',
  signature: '󰷻',
  'signature-freehand': '󰷼',
  'signature-image': '󰷽',
  'signature-text': '󰷾',
  silo: '󰭉',
  silverware: '󰒣',
  'silverware-clean': '󰿞',
  'silverware-fork': '󰒤',
  'silverware-fork-knife': '󰩰',
  'silverware-spoon': '󰒥',
  'silverware-variant': '󰒦',
  sim: '󰒧',
  'sim-alert': '󰒨',
  'sim-alert-outline': '󱗓',
  'sim-off': '󰒩',
  'sim-off-outline': '󱗔',
  'sim-outline': '󱗕',
  'simple-icons': '󱌝',
  'sina-weibo': '󰫟',
  'sine-wave': '󰥛',
  sitemap: '󰒪',
  'size-l': '󱎦',
  'size-m': '󱎥',
  'size-s': '󱎤',
  'size-xl': '󱎧',
  'size-xs': '󱎣',
  'size-xxl': '󱎨',
  'size-xxs': '󱎢',
  'size-xxxl': '󱎩',
  skate: '󰴵',
  skateboard: '󱓂',
  'skew-less': '󰴶',
  'skew-more': '󰴷',
  ski: '󱌄',
  'ski-cross-country': '󱌅',
  'ski-water': '󱌆',
  'skip-backward': '󰒫',
  'skip-backward-outline': '󰼥',
  'skip-forward': '󰒬',
  'skip-forward-outline': '󰼦',
  'skip-next': '󰒭',
  'skip-next-circle': '󰙡',
  'skip-next-circle-outline': '󰙢',
  'skip-next-outline': '󰼧',
  'skip-previous': '󰒮',
  'skip-previous-circle': '󰙣',
  'skip-previous-circle-outline': '󰙤',
  'skip-previous-outline': '󰼨',
  skull: '󰚌',
  'skull-crossbones': '󰯆',
  'skull-crossbones-outline': '󰯇',
  'skull-outline': '󰯈',
  'skull-scan': '󱓇',
  'skull-scan-outline': '󱓈',
  skype: '󰒯',
  'skype-business': '󰒰',
  slack: '󰒱',
  'slash-forward': '󰿟',
  'slash-forward-box': '󰿠',
  sleep: '󰒲',
  'sleep-off': '󰒳',
  slide: '󱖥',
  'slope-downhill': '󰷿',
  'slope-uphill': '󰸀',
  'slot-machine': '󱄔',
  'slot-machine-outline': '󱄕',
  'smart-card': '󱂽',
  'smart-card-outline': '󱂾',
  'smart-card-reader': '󱂿',
  'smart-card-reader-outline': '󱃀',
  smog: '󰩱',
  'smoke-detector': '󰎒',
  smoking: '󰒴',
  'smoking-off': '󰒵',
  'smoking-pipe': '󱐍',
  'smoking-pipe-off': '󱐨',
  snail: '󱙷',
  snake: '󱔎',
  snapchat: '󰒶',
  snowboard: '󱌇',
  snowflake: '󰜗',
  'snowflake-alert': '󰼩',
  'snowflake-melt': '󱋋',
  'snowflake-off': '󱓣',
  'snowflake-variant': '󰼪',
  snowman: '󰒷',
  soccer: '󰒸',
  'soccer-field': '󰠴',
  'social-distance-2-meters': '󱕹',
  'social-distance-6-feet': '󱕺',
  sofa: '󰒹',
  'sofa-outline': '󱕭',
  'sofa-single': '󱕮',
  'sofa-single-outline': '󱕯',
  'solar-panel': '󰶛',
  'solar-panel-large': '󰶜',
  'solar-power': '󰩲',
  'soldering-iron': '󱂒',
  solid: '󰚍',
  'sony-playstation': '󰐔',
  sort: '󰒺',
  'sort-alphabetical-ascending': '󰖽',
  'sort-alphabetical-ascending-variant': '󱅈',
  'sort-alphabetical-descending': '󰖿',
  'sort-alphabetical-descending-variant': '󱅉',
  'sort-alphabetical-variant': '󰒻',
  'sort-ascending': '󰒼',
  'sort-bool-ascending': '󱎅',
  'sort-bool-ascending-variant': '󱎆',
  'sort-bool-descending': '󱎇',
  'sort-bool-descending-variant': '󱎈',
  'sort-calendar-ascending': '󱕇',
  'sort-calendar-descending': '󱕈',
  'sort-clock-ascending': '󱕉',
  'sort-clock-ascending-outline': '󱕊',
  'sort-clock-descending': '󱕋',
  'sort-clock-descending-outline': '󱕌',
  'sort-descending': '󰒽',
  'sort-numeric-ascending': '󱎉',
  'sort-numeric-ascending-variant': '󰤍',
  'sort-numeric-descending': '󱎊',
  'sort-numeric-descending-variant': '󰫒',
  'sort-numeric-variant': '󰒾',
  'sort-reverse-variant': '󰌼',
  'sort-variant': '󰒿',
  'sort-variant-lock': '󰳍',
  'sort-variant-lock-open': '󰳎',
  'sort-variant-remove': '󱅇',
  soundcloud: '󰓀',
  'source-branch': '󰘬',
  'source-branch-check': '󱓏',
  'source-branch-minus': '󱓋',
  'source-branch-plus': '󱓊',
  'source-branch-refresh': '󱓍',
  'source-branch-remove': '󱓌',
  'source-branch-sync': '󱓎',
  'source-commit': '󰜘',
  'source-commit-end': '󰜙',
  'source-commit-end-local': '󰜚',
  'source-commit-local': '󰜛',
  'source-commit-next-local': '󰜜',
  'source-commit-start': '󰜝',
  'source-commit-start-next-local': '󰜞',
  'source-fork': '󰓁',
  'source-merge': '󰘭',
  'source-pull': '󰓂',
  'source-repository': '󰳏',
  'source-repository-multiple': '󰳐',
  'soy-sauce': '󰟮',
  'soy-sauce-off': '󱏼',
  spa: '󰳑',
  'spa-outline': '󰳒',
  'space-invaders': '󰯉',
  'space-station': '󱎃',
  spade: '󰹥',
  sparkles: '󱕅',
  speaker: '󰓃',
  'speaker-bluetooth': '󰦢',
  'speaker-multiple': '󰴸',
  'speaker-off': '󰓄',
  'speaker-wireless': '󰜟',
  speedometer: '󰓅',
  'speedometer-medium': '󰾅',
  'speedometer-slow': '󰾆',
  spellcheck: '󰓆',
  spider: '󱇪',
  'spider-thread': '󱇫',
  'spider-web': '󰯊',
  'spirit-level': '󱓱',
  'spoon-sugar': '󱐩',
  spotify: '󰓇',
  spotlight: '󰓈',
  'spotlight-beam': '󰓉',
  spray: '󰙥',
  'spray-bottle': '󰫠',
  sprinkler: '󱁟',
  'sprinkler-variant': '󱁠',
  sprout: '󰹦',
  'sprout-outline': '󰹧',
  square: '󰝤',
  'square-circle': '󱔀',
  'square-edit-outline': '󰤌',
  'square-medium': '󰨓',
  'square-medium-outline': '󰨔',
  'square-off': '󱋮',
  'square-off-outline': '󱋯',
  'square-outline': '󰝣',
  'square-root': '󰞄',
  'square-root-box': '󰦣',
  'square-rounded': '󱓻',
  'square-rounded-outline': '󱓼',
  'square-small': '󰨕',
  'square-wave': '󱑻',
  squeegee: '󰫡',
  ssh: '󰣀',
  'stack-exchange': '󰘋',
  'stack-overflow': '󰓌',
  stackpath: '󰍙',
  stadium: '󰿹',
  'stadium-variant': '󰜠',
  stairs: '󰓍',
  'stairs-box': '󱎞',
  'stairs-down': '󱊾',
  'stairs-up': '󱊽',
  stamper: '󰴹',
  'standard-definition': '󰟯',
  star: '󰓎',
  'star-box': '󰩳',
  'star-box-multiple': '󱊆',
  'star-box-multiple-outline': '󱊇',
  'star-box-outline': '󰩴',
  'star-check': '󱕦',
  'star-check-outline': '󱕪',
  'star-circle': '󰓏',
  'star-circle-outline': '󰦤',
  'star-cog': '󱙨',
  'star-cog-outline': '󱙩',
  'star-face': '󰦥',
  'star-four-points': '󰫢',
  'star-four-points-outline': '󰫣',
  'star-half': '󰉆',
  'star-half-full': '󰓐',
  'star-minus': '󱕤',
  'star-minus-outline': '󱕨',
  'star-off': '󰓑',
  'star-off-outline': '󱕛',
  'star-outline': '󰓒',
  'star-plus': '󱕣',
  'star-plus-outline': '󱕧',
  'star-remove': '󱕥',
  'star-remove-outline': '󱕩',
  'star-settings': '󱙪',
  'star-settings-outline': '󱙫',
  'star-three-points': '󰫤',
  'star-three-points-outline': '󰫥',
  'state-machine': '󱇯',
  steam: '󰓓',
  steering: '󰓔',
  'steering-off': '󰤎',
  'step-backward': '󰓕',
  'step-backward-2': '󰓖',
  'step-forward': '󰓗',
  'step-forward-2': '󰓘',
  stethoscope: '󰓙',
  sticker: '󱍤',
  'sticker-alert': '󱍥',
  'sticker-alert-outline': '󱍦',
  'sticker-check': '󱍧',
  'sticker-check-outline': '󱍨',
  'sticker-circle-outline': '󰗐',
  'sticker-emoji': '󰞅',
  'sticker-minus': '󱍩',
  'sticker-minus-outline': '󱍪',
  'sticker-outline': '󱍫',
  'sticker-plus': '󱍬',
  'sticker-plus-outline': '󱍭',
  'sticker-remove': '󱍮',
  'sticker-remove-outline': '󱍯',
  stocking: '󰓚',
  stomach: '󱂓',
  stop: '󰓛',
  'stop-circle': '󰙦',
  'stop-circle-outline': '󰙧',
  store: '󰓜',
  'store-24-hour': '󰓝',
  'store-minus': '󱙞',
  'store-outline': '󱍡',
  'store-plus': '󱙟',
  'store-remove': '󱙠',
  storefront: '󰟇',
  'storefront-outline': '󱃁',
  stove: '󰓞',
  strategy: '󱇖',
  'stretch-to-page': '󰼫',
  'stretch-to-page-outline': '󰼬',
  'string-lights': '󱊺',
  'string-lights-off': '󱊻',
  'subdirectory-arrow-left': '󰘌',
  'subdirectory-arrow-right': '󰘍',
  submarine: '󱕬',
  subtitles: '󰨖',
  'subtitles-outline': '󰨗',
  subway: '󰚬',
  'subway-alert-variant': '󰶝',
  'subway-variant': '󰓟',
  summit: '󰞆',
  sunglasses: '󰓠',
  'surround-sound': '󰗅',
  'surround-sound-2-0': '󰟰',
  'surround-sound-3-1': '󰟱',
  'surround-sound-5-1': '󰟲',
  'surround-sound-7-1': '󰟳',
  svg: '󰜡',
  'swap-horizontal': '󰓡',
  'swap-horizontal-bold': '󰯍',
  'swap-horizontal-circle': '󰿡',
  'swap-horizontal-circle-outline': '󰿢',
  'swap-horizontal-variant': '󰣁',
  'swap-vertical': '󰓢',
  'swap-vertical-bold': '󰯎',
  'swap-vertical-circle': '󰿣',
  'swap-vertical-circle-outline': '󰿤',
  'swap-vertical-variant': '󰣂',
  swim: '󰓣',
  switch: '󰓤',
  sword: '󰓥',
  'sword-cross': '󰞇',
  'syllabary-hangul': '󱌳',
  'syllabary-hiragana': '󱌴',
  'syllabary-katakana': '󱌵',
  'syllabary-katakana-halfwidth': '󱌶',
  symbol: '󱔁',
  symfony: '󰫦',
  sync: '󰓦',
  'sync-alert': '󰓧',
  'sync-circle': '󱍸',
  'sync-off': '󰓨',
  tab: '󰓩',
  'tab-minus': '󰭋',
  'tab-plus': '󰝜',
  'tab-remove': '󰭌',
  'tab-unselected': '󰓪',
  table: '󰓫',
  'table-account': '󱎹',
  'table-alert': '󱎺',
  'table-arrow-down': '󱎻',
  'table-arrow-left': '󱎼',
  'table-arrow-right': '󱎽',
  'table-arrow-up': '󱎾',
  'table-border': '󰨘',
  'table-cancel': '󱎿',
  'table-chair': '󱁡',
  'table-check': '󱏀',
  'table-clock': '󱏁',
  'table-cog': '󱏂',
  'table-column': '󰠵',
  'table-column-plus-after': '󰓬',
  'table-column-plus-before': '󰓭',
  'table-column-remove': '󰓮',
  'table-column-width': '󰓯',
  'table-edit': '󰓰',
  'table-eye': '󱂔',
  'table-eye-off': '󱏃',
  'table-furniture': '󰖼',
  'table-headers-eye': '󱈝',
  'table-headers-eye-off': '󱈞',
  'table-heart': '󱏄',
  'table-key': '󱏅',
  'table-large': '󰓱',
  'table-large-plus': '󰾇',
  'table-large-remove': '󰾈',
  'table-lock': '󱏆',
  'table-merge-cells': '󰦦',
  'table-minus': '󱏇',
  'table-multiple': '󱏈',
  'table-network': '󱏉',
  'table-of-contents': '󰠶',
  'table-off': '󱏊',
  'table-plus': '󰩵',
  'table-refresh': '󱎠',
  'table-remove': '󰩶',
  'table-row': '󰠷',
  'table-row-height': '󰓲',
  'table-row-plus-after': '󰓳',
  'table-row-plus-before': '󰓴',
  'table-row-remove': '󰓵',
  'table-search': '󰤏',
  'table-settings': '󰠸',
  'table-split-cell': '󱐪',
  'table-star': '󱏋',
  'table-sync': '󱎡',
  'table-tennis': '󰹨',
  tablet: '󰓶',
  'tablet-android': '󰓷',
  'tablet-cellphone': '󰦧',
  'tablet-dashboard': '󰻎',
  'tablet-ipad': '󰓸',
  taco: '󰝢',
  tag: '󰓹',
  'tag-faces': '󰓺',
  'tag-heart': '󰚋',
  'tag-heart-outline': '󰯏',
  'tag-minus': '󰤐',
  'tag-minus-outline': '󱈟',
  'tag-multiple': '󰓻',
  'tag-multiple-outline': '󱋷',
  'tag-off': '󱈠',
  'tag-off-outline': '󱈡',
  'tag-outline': '󰓼',
  'tag-plus': '󰜢',
  'tag-plus-outline': '󱈢',
  'tag-remove': '󰜣',
  'tag-remove-outline': '󱈣',
  'tag-text': '󱈤',
  'tag-text-outline': '󰓽',
  tailwind: '󱏿',
  tank: '󰴺',
  'tanker-truck': '󰿥',
  'tape-drive': '󱛟',
  'tape-measure': '󰭍',
  target: '󰓾',
  'target-account': '󰯐',
  'target-variant': '󰩷',
  taxi: '󰓿',
  tea: '󰶞',
  'tea-outline': '󰶟',
  teach: '󰢐',
  teamviewer: '󰔀',
  telegram: '󰔁',
  telescope: '󰭎',
  television: '󰔂',
  'television-ambient-light': '󱍖',
  'television-box': '󰠹',
  'television-classic': '󰟴',
  'television-classic-off': '󰠺',
  'television-clean': '󱄐',
  'television-guide': '󰔃',
  'television-off': '󰠻',
  'television-pause': '󰾉',
  'television-play': '󰻏',
  'television-stop': '󰾊',
  'temperature-celsius': '󰔄',
  'temperature-fahrenheit': '󰔅',
  'temperature-kelvin': '󰔆',
  tennis: '󰶠',
  'tennis-ball': '󰔇',
  tent: '󰔈',
  terraform: '󱁢',
  terrain: '󰔉',
  'test-tube': '󰙨',
  'test-tube-empty': '󰤑',
  'test-tube-off': '󰤒',
  text: '󰦨',
  'text-account': '󱕰',
  'text-box': '󰈚',
  'text-box-check': '󰺦',
  'text-box-check-outline': '󰺧',
  'text-box-minus': '󰺨',
  'text-box-minus-outline': '󰺩',
  'text-box-multiple': '󰪷',
  'text-box-multiple-outline': '󰪸',
  'text-box-outline': '󰧭',
  'text-box-plus': '󰺪',
  'text-box-plus-outline': '󰺫',
  'text-box-remove': '󰺬',
  'text-box-remove-outline': '󰺭',
  'text-box-search': '󰺮',
  'text-box-search-outline': '󰺯',
  'text-recognition': '󱄽',
  'text-search': '󱎸',
  'text-shadow': '󰙩',
  'text-short': '󰦩',
  'text-subject': '󰦪',
  'text-to-speech': '󰔊',
  'text-to-speech-off': '󰔋',
  texture: '󰔌',
  'texture-box': '󰿦',
  theater: '󰔍',
  'theme-light-dark': '󰔎',
  thermometer: '󰔏',
  'thermometer-alert': '󰸁',
  'thermometer-chevron-down': '󰸂',
  'thermometer-chevron-up': '󰸃',
  'thermometer-high': '󱃂',
  'thermometer-lines': '󰔐',
  'thermometer-low': '󱃃',
  'thermometer-minus': '󰸄',
  'thermometer-off': '󱔱',
  'thermometer-plus': '󰸅',
  thermostat: '󰎓',
  'thermostat-box': '󰢑',
  'thought-bubble': '󰟶',
  'thought-bubble-outline': '󰟷',
  'thumb-down': '󰔑',
  'thumb-down-outline': '󰔒',
  'thumb-up': '󰔓',
  'thumb-up-outline': '󰔔',
  'thumbs-up-down': '󰔕',
  ticket: '󰔖',
  'ticket-account': '󰔗',
  'ticket-confirmation': '󰔘',
  'ticket-confirmation-outline': '󱎪',
  'ticket-outline': '󰤓',
  'ticket-percent': '󰜤',
  'ticket-percent-outline': '󱐫',
  tie: '󰔙',
  tilde: '󰜥',
  timelapse: '󰔚',
  timeline: '󰯑',
  'timeline-alert': '󰾕',
  'timeline-alert-outline': '󰾘',
  'timeline-check': '󱔲',
  'timeline-check-outline': '󱔳',
  'timeline-clock': '󱇻',
  'timeline-clock-outline': '󱇼',
  'timeline-help': '󰾙',
  'timeline-help-outline': '󰾚',
  'timeline-minus': '󱔴',
  'timeline-minus-outline': '󱔵',
  'timeline-outline': '󰯒',
  'timeline-plus': '󰾖',
  'timeline-plus-outline': '󰾗',
  'timeline-remove': '󱔶',
  'timeline-remove-outline': '󱔷',
  'timeline-text': '󰯓',
  'timeline-text-outline': '󰯔',
  timer: '󱎫',
  'timer-10': '󰔜',
  'timer-3': '󰔝',
  'timer-off': '󱎬',
  'timer-off-outline': '󰔞',
  'timer-outline': '󰔛',
  'timer-sand': '󰔟',
  'timer-sand-empty': '󰚭',
  'timer-sand-full': '󰞌',
  timetable: '󰔠',
  toaster: '󱁣',
  'toaster-off': '󱆷',
  'toaster-oven': '󰳓',
  'toggle-switch': '󰔡',
  'toggle-switch-off': '󰔢',
  'toggle-switch-off-outline': '󰨙',
  'toggle-switch-outline': '󰨚',
  toilet: '󰦫',
  toolbox: '󰦬',
  'toolbox-outline': '󰦭',
  tools: '󱁤',
  tooltip: '󰔣',
  'tooltip-account': '󰀌',
  'tooltip-check': '󱕜',
  'tooltip-check-outline': '󱕝',
  'tooltip-edit': '󰔤',
  'tooltip-edit-outline': '󱋅',
  'tooltip-image': '󰔥',
  'tooltip-image-outline': '󰯕',
  'tooltip-minus': '󱕞',
  'tooltip-minus-outline': '󱕟',
  'tooltip-outline': '󰔦',
  'tooltip-plus': '󰯖',
  'tooltip-plus-outline': '󰔧',
  'tooltip-remove': '󱕠',
  'tooltip-remove-outline': '󱕡',
  'tooltip-text': '󰔨',
  'tooltip-text-outline': '󰯗',
  tooth: '󰣃',
  'tooth-outline': '󰔩',
  toothbrush: '󱄩',
  'toothbrush-electric': '󱄬',
  'toothbrush-paste': '󱄪',
  torch: '󱘆',
  tortoise: '󰴻',
  toslink: '󱊸',
  tournament: '󰦮',
  'tow-truck': '󰠼',
  'tower-beach': '󰚁',
  'tower-fire': '󰚂',
  'toy-brick': '󱊈',
  'toy-brick-marker': '󱊉',
  'toy-brick-marker-outline': '󱊊',
  'toy-brick-minus': '󱊋',
  'toy-brick-minus-outline': '󱊌',
  'toy-brick-outline': '󱊍',
  'toy-brick-plus': '󱊎',
  'toy-brick-plus-outline': '󱊏',
  'toy-brick-remove': '󱊐',
  'toy-brick-remove-outline': '󱊑',
  'toy-brick-search': '󱊒',
  'toy-brick-search-outline': '󱊓',
  'track-light': '󰤔',
  trackpad: '󰟸',
  'trackpad-lock': '󰤳',
  tractor: '󰢒',
  'tractor-variant': '󱓄',
  trademark: '󰩸',
  'traffic-cone': '󱍼',
  'traffic-light': '󰔫',
  train: '󰔬',
  'train-car': '󰯘',
  'train-variant': '󰣄',
  tram: '󰔭',
  'tram-side': '󰿧',
  transcribe: '󰔮',
  'transcribe-close': '󰔯',
  transfer: '󱁥',
  'transfer-down': '󰶡',
  'transfer-left': '󰶢',
  'transfer-right': '󰔰',
  'transfer-up': '󰶣',
  'transit-connection': '󰴼',
  'transit-connection-horizontal': '󱕆',
  'transit-connection-variant': '󰴽',
  'transit-detour': '󰾋',
  'transit-skip': '󱔕',
  'transit-transfer': '󰚮',
  transition: '󰤕',
  'transition-masked': '󰤖',
  translate: '󰗊',
  'translate-off': '󰸆',
  'transmission-tower': '󰴾',
  'trash-can': '󰩹',
  'trash-can-outline': '󰩺',
  tray: '󱊔',
  'tray-alert': '󱊕',
  'tray-full': '󱊖',
  'tray-minus': '󱊗',
  'tray-plus': '󱊘',
  'tray-remove': '󱊙',
  'treasure-chest': '󰜦',
  tree: '󰔱',
  'tree-outline': '󰹩',
  trello: '󰔲',
  'trending-down': '󰔳',
  'trending-neutral': '󰔴',
  'trending-up': '󰔵',
  triangle: '󰔶',
  'triangle-outline': '󰔷',
  'triangle-wave': '󱑼',
  triforce: '󰯙',
  trophy: '󰔸',
  'trophy-award': '󰔹',
  'trophy-broken': '󰶤',
  'trophy-outline': '󰔺',
  'trophy-variant': '󰔻',
  'trophy-variant-outline': '󰔼',
  truck: '󰔽',
  'truck-check': '󰳔',
  'truck-check-outline': '󱊚',
  'truck-delivery': '󰔾',
  'truck-delivery-outline': '󱊛',
  'truck-fast': '󰞈',
  'truck-fast-outline': '󱊜',
  'truck-outline': '󱊝',
  'truck-trailer': '󰜧',
  trumpet: '󱂖',
  'tshirt-crew': '󰩻',
  'tshirt-crew-outline': '󰔿',
  'tshirt-v': '󰩼',
  'tshirt-v-outline': '󰕀',
  'tumble-dryer': '󰤗',
  'tumble-dryer-alert': '󱆺',
  'tumble-dryer-off': '󱆻',
  tune: '󰘮',
  'tune-variant': '󱕂',
  'tune-vertical': '󰙪',
  'tune-vertical-variant': '󱕃',
  turnstile: '󰳕',
  'turnstile-outline': '󰳖',
  turtle: '󰳗',
  twitch: '󰕃',
  twitter: '󰕄',
  'twitter-retweet': '󰕇',
  'two-factor-authentication': '󰦯',
  typewriter: '󰼭',
  ubisoft: '󰯚',
  ubuntu: '󰕈',
  ufo: '󱃄',
  'ufo-outline': '󱃅',
  'ultra-high-definition': '󰟹',
  umbraco: '󰕉',
  umbrella: '󰕊',
  'umbrella-closed': '󰦰',
  'umbrella-closed-outline': '󱏢',
  'umbrella-closed-variant': '󱏡',
  'umbrella-outline': '󰕋',
  undo: '󰕌',
  'undo-variant': '󰕍',
  'unfold-less-horizontal': '󰕎',
  'unfold-less-vertical': '󰝠',
  'unfold-more-horizontal': '󰕏',
  'unfold-more-vertical': '󰝡',
  ungroup: '󰕐',
  unicode: '󰻐',
  unicorn: '󱗂',
  'unicorn-variant': '󱗃',
  unicycle: '󱗥',
  unity: '󰚯',
  unreal: '󰦱',
  untappd: '󰕑',
  update: '󰚰',
  upload: '󰕒',
  'upload-lock': '󱍳',
  'upload-lock-outline': '󱍴',
  'upload-multiple': '󰠽',
  'upload-network': '󰛶',
  'upload-network-outline': '󰳘',
  'upload-off': '󱃆',
  'upload-off-outline': '󱃇',
  'upload-outline': '󰸇',
  usb: '󰕓',
  'usb-flash-drive': '󱊞',
  'usb-flash-drive-outline': '󱊟',
  'usb-port': '󱇰',
  valve: '󱁦',
  'valve-closed': '󱁧',
  'valve-open': '󱁨',
  'van-passenger': '󰟺',
  'van-utility': '󰟻',
  vanish: '󰟼',
  'vanish-quarter': '󱕔',
  'vanity-light': '󱇡',
  variable: '󰫧',
  'variable-box': '󱄑',
  'vector-arrange-above': '󰕔',
  'vector-arrange-below': '󰕕',
  'vector-bezier': '󰫨',
  'vector-circle': '󰕖',
  'vector-circle-variant': '󰕗',
  'vector-combine': '󰕘',
  'vector-curve': '󰕙',
  'vector-difference': '󰕚',
  'vector-difference-ab': '󰕛',
  'vector-difference-ba': '󰕜',
  'vector-ellipse': '󰢓',
  'vector-intersection': '󰕝',
  'vector-line': '󰕞',
  'vector-link': '󰿨',
  'vector-point': '󰕟',
  'vector-polygon': '󰕠',
  'vector-polyline': '󰕡',
  'vector-polyline-edit': '󱈥',
  'vector-polyline-minus': '󱈦',
  'vector-polyline-plus': '󱈧',
  'vector-polyline-remove': '󱈨',
  'vector-radius': '󰝊',
  'vector-rectangle': '󰗆',
  'vector-selection': '󰕢',
  'vector-square': '󰀁',
  'vector-triangle': '󰕣',
  'vector-union': '󰕤',
  vhs: '󰨛',
  vibrate: '󰕦',
  'vibrate-off': '󰳙',
  video: '󰕧',
  'video-3d': '󰟽',
  'video-3d-off': '󱏙',
  'video-3d-variant': '󰻑',
  'video-4k-box': '󰠾',
  'video-account': '󰤙',
  'video-box': '󰃽',
  'video-box-off': '󰃾',
  'video-check': '󱁩',
  'video-check-outline': '󱁪',
  'video-high-definition': '󱔮',
  'video-image': '󰤚',
  'video-input-antenna': '󰠿',
  'video-input-component': '󰡀',
  'video-input-hdmi': '󰡁',
  'video-input-scart': '󰾌',
  'video-input-svideo': '󰡂',
  'video-minus': '󰦲',
  'video-minus-outline': '󰊺',
  'video-off': '󰕨',
  'video-off-outline': '󰯛',
  'video-outline': '󰯜',
  'video-plus': '󰦳',
  'video-plus-outline': '󰇓',
  'video-stabilization': '󰤛',
  'video-switch': '󰕩',
  'video-switch-outline': '󰞐',
  'video-vintage': '󰨜',
  'video-wireless': '󰻒',
  'video-wireless-outline': '󰻓',
  'view-agenda': '󰕪',
  'view-agenda-outline': '󱇘',
  'view-array': '󰕫',
  'view-array-outline': '󱒅',
  'view-carousel': '󰕬',
  'view-carousel-outline': '󱒆',
  'view-column': '󰕭',
  'view-column-outline': '󱒇',
  'view-comfy': '󰹪',
  'view-comfy-outline': '󱒈',
  'view-compact': '󰹫',
  'view-compact-outline': '󰹬',
  'view-dashboard': '󰕮',
  'view-dashboard-outline': '󰨝',
  'view-dashboard-variant': '󰡃',
  'view-dashboard-variant-outline': '󱒉',
  'view-day': '󰕯',
  'view-day-outline': '󱒊',
  'view-grid': '󰕰',
  'view-grid-outline': '󱇙',
  'view-grid-plus': '󰾍',
  'view-grid-plus-outline': '󱇚',
  'view-headline': '󰕱',
  'view-list': '󰕲',
  'view-list-outline': '󱒋',
  'view-module': '󰕳',
  'view-module-outline': '󱒌',
  'view-parallel': '󰜨',
  'view-parallel-outline': '󱒍',
  'view-quilt': '󰕴',
  'view-quilt-outline': '󱒎',
  'view-sequential': '󰜩',
  'view-sequential-outline': '󱒏',
  'view-split-horizontal': '󰯋',
  'view-split-vertical': '󰯌',
  'view-stream': '󰕵',
  'view-stream-outline': '󱒐',
  'view-week': '󰕶',
  'view-week-outline': '󱒑',
  vimeo: '󰕷',
  violin: '󰘏',
  'virtual-reality': '󰢔',
  virus: '󱎶',
  'virus-outline': '󱎷',
  vk: '󰕹',
  vlc: '󰕼',
  'voice-off': '󰻔',
  voicemail: '󰕽',
  volleyball: '󰦴',
  'volume-high': '󰕾',
  'volume-low': '󰕿',
  'volume-medium': '󰖀',
  'volume-minus': '󰝞',
  'volume-mute': '󰝟',
  'volume-off': '󰖁',
  'volume-plus': '󰝝',
  'volume-source': '󱄠',
  'volume-variant-off': '󰸈',
  'volume-vibrate': '󱄡',
  vote: '󰨟',
  'vote-outline': '󰨠',
  vpn: '󰖂',
  vuejs: '󰡄',
  vuetify: '󰹭',
  walk: '󰖃',
  wall: '󰟾',
  'wall-sconce': '󰤜',
  'wall-sconce-flat': '󰤝',
  'wall-sconce-flat-variant': '󰐜',
  'wall-sconce-round': '󰝈',
  'wall-sconce-round-variant': '󰤞',
  wallet: '󰖄',
  'wallet-giftcard': '󰖅',
  'wallet-membership': '󰖆',
  'wallet-outline': '󰯝',
  'wallet-plus': '󰾎',
  'wallet-plus-outline': '󰾏',
  'wallet-travel': '󰖇',
  wallpaper: '󰸉',
  wan: '󰖈',
  wardrobe: '󰾐',
  'wardrobe-outline': '󰾑',
  warehouse: '󰾁',
  'washing-machine': '󰜪',
  'washing-machine-alert': '󱆼',
  'washing-machine-off': '󱆽',
  watch: '󰖉',
  'watch-export': '󰖊',
  'watch-export-variant': '󰢕',
  'watch-import': '󰖋',
  'watch-import-variant': '󰢖',
  'watch-variant': '󰢗',
  'watch-vibrate': '󰚱',
  'watch-vibrate-off': '󰳚',
  water: '󰖌',
  'water-alert': '󱔂',
  'water-alert-outline': '󱔃',
  'water-boiler': '󰾒',
  'water-boiler-alert': '󱆳',
  'water-boiler-off': '󱆴',
  'water-check': '󱔄',
  'water-check-outline': '󱔅',
  'water-minus': '󱔆',
  'water-minus-outline': '󱔇',
  'water-off': '󰖍',
  'water-off-outline': '󱔈',
  'water-outline': '󰸊',
  'water-percent': '󰖎',
  'water-percent-alert': '󱔉',
  'water-plus': '󱔊',
  'water-plus-outline': '󱔋',
  'water-polo': '󱊠',
  'water-pump': '󰖏',
  'water-pump-off': '󰾓',
  'water-remove': '󱔌',
  'water-remove-outline': '󱔍',
  'water-well': '󱁫',
  'water-well-outline': '󱁬',
  'watering-can': '󱒁',
  'watering-can-outline': '󱒂',
  watermark: '󰘒',
  wave: '󰼮',
  waveform: '󱑽',
  waves: '󰞍',
  waze: '󰯞',
  'weather-cloudy': '󰖐',
  'weather-cloudy-alert': '󰼯',
  'weather-cloudy-arrow-right': '󰹮',
  'weather-fog': '󰖑',
  'weather-hail': '󰖒',
  'weather-hazy': '󰼰',
  'weather-hurricane': '󰢘',
  'weather-lightning': '󰖓',
  'weather-lightning-rainy': '󰙾',
  'weather-night': '󰖔',
  'weather-night-partly-cloudy': '󰼱',
  'weather-partly-cloudy': '󰖕',
  'weather-partly-lightning': '󰼲',
  'weather-partly-rainy': '󰼳',
  'weather-partly-snowy': '󰼴',
  'weather-partly-snowy-rainy': '󰼵',
  'weather-pouring': '󰖖',
  'weather-rainy': '󰖗',
  'weather-snowy': '󰖘',
  'weather-snowy-heavy': '󰼶',
  'weather-snowy-rainy': '󰙿',
  'weather-sunny': '󰖙',
  'weather-sunny-alert': '󰼷',
  'weather-sunny-off': '󱓤',
  'weather-sunset': '󰖚',
  'weather-sunset-down': '󰖛',
  'weather-sunset-up': '󰖜',
  'weather-tornado': '󰼸',
  'weather-windy': '󰖝',
  'weather-windy-variant': '󰖞',
  web: '󰖟',
  'web-box': '󰾔',
  'web-clock': '󱉊',
  webcam: '󰖠',
  webhook: '󰘯',
  webpack: '󰜫',
  webrtc: '󱉈',
  wechat: '󰘑',
  weight: '󰖡',
  'weight-gram': '󰴿',
  'weight-kilogram': '󰖢',
  'weight-lifter': '󱅝',
  'weight-pound': '󰦵',
  whatsapp: '󰖣',
  'wheel-barrow': '󱓲',
  'wheelchair-accessibility': '󰖤',
  whistle: '󰦶',
  'whistle-outline': '󱊼',
  'white-balance-auto': '󰖥',
  'white-balance-incandescent': '󰖦',
  'white-balance-iridescent': '󰖧',
  'white-balance-sunny': '󰖨',
  widgets: '󰜬',
  'widgets-outline': '󱍕',
  wifi: '󰖩',
  'wifi-alert': '󱚵',
  'wifi-arrow-down': '󱚶',
  'wifi-arrow-left': '󱚷',
  'wifi-arrow-left-right': '󱚸',
  'wifi-arrow-right': '󱚹',
  'wifi-arrow-up': '󱚺',
  'wifi-arrow-up-down': '󱚻',
  'wifi-cancel': '󱚼',
  'wifi-check': '󱚽',
  'wifi-cog': '󱚾',
  'wifi-lock': '󱚿',
  'wifi-lock-open': '󱛀',
  'wifi-marker': '󱛁',
  'wifi-minus': '󱛂',
  'wifi-off': '󰖪',
  'wifi-plus': '󱛃',
  'wifi-refresh': '󱛄',
  'wifi-remove': '󱛅',
  'wifi-settings': '󱛆',
  'wifi-star': '󰸋',
  'wifi-strength-1': '󰤟',
  'wifi-strength-1-alert': '󰤠',
  'wifi-strength-1-lock': '󰤡',
  'wifi-strength-1-lock-open': '󱛋',
  'wifi-strength-2': '󰤢',
  'wifi-strength-2-alert': '󰤣',
  'wifi-strength-2-lock': '󰤤',
  'wifi-strength-2-lock-open': '󱛌',
  'wifi-strength-3': '󰤥',
  'wifi-strength-3-alert': '󰤦',
  'wifi-strength-3-lock': '󰤧',
  'wifi-strength-3-lock-open': '󱛍',
  'wifi-strength-4': '󰤨',
  'wifi-strength-4-alert': '󰤩',
  'wifi-strength-4-lock': '󰤪',
  'wifi-strength-4-lock-open': '󱛎',
  'wifi-strength-alert-outline': '󰤫',
  'wifi-strength-lock-open-outline': '󱛏',
  'wifi-strength-lock-outline': '󰤬',
  'wifi-strength-off': '󰤭',
  'wifi-strength-off-outline': '󰤮',
  'wifi-strength-outline': '󰤯',
  'wifi-sync': '󱛇',
  wikipedia: '󰖬',
  'wind-turbine': '󰶥',
  'window-close': '󰖭',
  'window-closed': '󰖮',
  'window-closed-variant': '󱇛',
  'window-maximize': '󰖯',
  'window-minimize': '󰖰',
  'window-open': '󰖱',
  'window-open-variant': '󱇜',
  'window-restore': '󰖲',
  'window-shutter': '󱄜',
  'window-shutter-alert': '󱄝',
  'window-shutter-open': '󱄞',
  windsock: '󱗺',
  wiper: '󰫩',
  'wiper-wash': '󰶦',
  'wizard-hat': '󱑷',
  wordpress: '󰖴',
  wrap: '󰖶',
  'wrap-disabled': '󰯟',
  wrench: '󰖷',
  'wrench-outline': '󰯠',
  xamarin: '󰡅',
  'xamarin-outline': '󰡆',
  xing: '󰖾',
  xml: '󰗀',
  xmpp: '󰟿',
  'y-combinator': '󰘤',
  yahoo: '󰭏',
  yeast: '󰗁',
  'yin-yang': '󰚀',
  yoga: '󱅼',
  youtube: '󰗃',
  'youtube-gaming': '󰡈',
  'youtube-studio': '󰡇',
  'youtube-subscription': '󰵀',
  'youtube-tv': '󰑈',
  yurt: '󱔖',
  'z-wave': '󰫪',
  zend: '󰫫',
  zigbee: '󰵁',
  'zip-box': '󰗄',
  'zip-box-outline': '󰿺',
  'zip-disk': '󰨣',
  'zodiac-aquarius': '󰩽',
  'zodiac-aries': '󰩾',
  'zodiac-cancer': '󰩿',
  'zodiac-capricorn': '󰪀',
  'zodiac-gemini': '󰪁',
  'zodiac-leo': '󰪂',
  'zodiac-libra': '󰪃',
  'zodiac-pisces': '󰪄',
  'zodiac-sagittarius': '󰪅',
  'zodiac-scorpio': '󰪆',
  'zodiac-taurus': '󰪇',
  'zodiac-virgo': '󰪈',
  blank: '',
} as Record<string, string>
