import { Konva, ShapeModel, Board, ShapeDrawer, getPointsDistance } from 'pikaso'
import { EditorObjectsType } from '../../../../ts/enum/editor'

export class HalfRoadSegment extends ShapeModel<Konva.Group, Konva.GroupConfig> {
  constructor(board: Board, node: Konva.Group) {
    super(board, node)
    this.config = {
      ...this.config,
      transformer: {
        enabledAnchors: [],
      },
    }
  }

  public get type() {
    return EditorObjectsType.HalfRoadSegment as string
  }
}

export class HalfRoadSegmentDrawer extends ShapeDrawer<
  // @ts-ignore
  Konva.Group,
  Konva.GroupConfig
> {
  public node: Konva.Group | null = null

  constructor(board: Board) {
    super(board, EditorObjectsType.HalfRoadSegment)
  }

  public insert(config: Konva.ShapeConfig): HalfRoadSegment {
    return super.insert(config)
  }

  public draw(config: Konva.ShapeConfig = {}) {
    super.draw(config)
  }

  generateRoadLane(lanes: number, isLeftSide?: boolean) {
    const widthOneLane = 45

    const laneGroup = new Konva.Group({
      draggable: false,
      name: 'roadLanes',
      id: Math.random().toString(16).slice(2),
    })

    const [laneStart, laneEnd] = [
      new Konva.Line({
        strokeWidth: 3,
        stroke: '#3C72FF',
        points: [0, 0, 0, 200],
        id: Math.random().toString(16).slice(2),
      }),
      new Konva.Line({
        strokeWidth: 3,
        stroke: '#3C72FF',
        points: [widthOneLane * lanes, 0, widthOneLane * lanes, 200],
        id: Math.random().toString(16).slice(2),
      }),
    ]

    laneGroup.add(laneStart)
    const createMiddleLine = (position: number) => {
      const x = position * widthOneLane
      return new Konva.Line({
        strokeWidth: 3,
        lineJoin: 'round',
        dash: [10, 5],
        stroke: '#3C72FF',
        points: [x, 0, x + 1, 200],
        id: Math.random().toString(16).slice(2),
      })
    }

    for (let i = 0; i < lanes; i++) {
      const currentLane = i + 1
      const needGenerateMiddle = currentLane !== lanes
      const roadLane = new Konva.Group({ draggable: false })
      const currentLanePosition = isLeftSide ? lanes - i : currentLane
      roadLane.setAttr('position', currentLanePosition)
      const line = new Konva.Line({
        strokeWidth: 3,
        stroke: '#3C72FF',
        points: [widthOneLane * i, 100, widthOneLane * currentLane, 100],
        id: Math.random().toString(16).slice(2),
      })

      const roadPosition = new Konva.Text({
        x: 14 + widthOneLane * i,
        y: 60,
        fontSize: 32,
        fill: 'white',
        fontFamily: 'Geometria',
        text: String(currentLanePosition),
        id: Math.random().toString(16).slice(2),
      })

      const amount = new Konva.Text({
        x: 35 + widthOneLane * i,
        y: 120,
        fontSize: 32,
        fill: 'white',
        fontFamily: 'Geometria',
        text: 'XXX',
        rotation: 90,
        id: Math.random().toString(16).slice(2),
      })

      roadLane.add(line, amount, roadPosition)
      laneGroup.add(roadLane)
      if (needGenerateMiddle) laneGroup.add(createMiddleLine(currentLane))
    }
    laneGroup.add(laneEnd)
    return laneGroup
  }

  protected createShape(config: Partial<Konva.ShapeConfig | Record<string, any>>): HalfRoadSegment {
    const widthLine = 3
    const widthOneLane = 45
    const leftCount = config.leftLanes || 1
    const rightCount = config.rightLanes || 1
    const title = config?.title

    const leftSide = this.generateRoadLane(leftCount, true)
    const rightSide = this.generateRoadLane(rightCount)

    const segmentName = new Konva.Text({
      x: 0,
      y: 0,
      fontSize: 16,
      fill: 'white',
      fontFamily: 'Geometria',
      id: Math.random().toString(16).slice(2),
      text: String(title),
    })

    const segmentValue = new Konva.Text({
      x: 0,
      y: 0,
      fontSize: 32,
      fill: 'white',
      fontFamily: 'Geometria',
      id: Math.random().toString(16).slice(2),
      text: 'XXX',
    })

    this.node = new Konva.Group({
      ...config,
      x: config?.x || 100,
      y: config?.y || 100,
      id: config?.id || Math.random().toString(16).slice(2),
      draggable: true,
      name: EditorObjectsType.HalfRoadSegment,
    })

    leftSide.height(200)
    rightSide.height(200)

    leftSide.width(widthLine * 2 + leftCount * widthOneLane)
    rightSide.width(widthLine * 2 + rightCount * widthOneLane)
    rightSide.x(leftSide.width() + 2)

    segmentValue.x((rightSide.width() + leftSide.width()) / 2 - segmentValue.width() / 2)
    segmentValue.y(-50)

    segmentName.x(leftSide.width() + rightSide.width() + segmentName.width())
    segmentName.y(leftSide.height() / 2 - 5)

    this.node.add(leftSide, rightSide, segmentName, segmentValue)
    return new HalfRoadSegment(this.board, this.node)
  }
}
