import { css } from '@emotion/react'

export const toolBarStyles = css`
  position: absolute;
  display: flex;
  padding: 10px;
  top: 5%;
  left: 30px;
  border-radius: 10px;
  background: var(--bg-gradient-dark);
`

export const toolBarItemStyles = css`
  font-size: 18px;
  margin-right: 12px;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  &:last-child {
    margin-right: 0;
  }
`
